import React from 'react'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'

import { formatGradient } from '../../../../utils/formatters'
import { FeatureCard } from './FeatureCard'
import * as styles from './styles'

const FeatureWithCardsSection = ({
  section: {
    fields: {
      title,
      subtitle,
      link,
      image,
      items = [],
      backgroundGradient,
      desktopDirection = 'left',
      offsetDirection,
      config,
    },
  },
}) => {
  const backgroundConfig = config ? config.fields : {}
  const gradient = backgroundConfig.gradient || backgroundGradient
  const formattedGradient = formatGradient(gradient)
  const offsets = {
    leftOffset: offsetDirection === 'left' || offsetDirection === 'both',
    rightOffset: offsetDirection === 'right' || offsetDirection === 'both',
  }

  return (
    <OffsetSection
      top={{ xs: 2.5, md: 4 }}
      bottom={{ xs: 2.5, md: 4 }}
      config={config}
      offsetDirection={offsetDirection}
      fullWidthMobile
    >
      <styles.Background
        background={formattedGradient}
        fullWidthMobile={false}
        {...offsets}
      >
        <Appear observer>
          <styles.Content desktopDirection={desktopDirection}>
            <styles.ContentFrame>
              {title && (
                <Type
                  as="h3"
                  preset="headlineLarge"
                  weight="bold"
                  top={{ xs: 2, md: 0 }}
                  bottom={{ xs: 1, md: 1 }}
                >
                  {title}
                </Type>
              )}
              {subtitle && (
                <Type preset="textLarge" multiline>
                  {subtitle}
                </Type>
              )}
              {link && (
                <styles.ButtonLink
                  to={link.fields.url}
                  transparent
                  noHover
                  weight="bold"
                  textColor="deepBlue"
                  top={{ xs: 1, md: 1.5 }}
                >
                  {link.fields.label}
                  <styles.ArrowWrap>→</styles.ArrowWrap>
                </styles.ButtonLink>
              )}
            </styles.ContentFrame>
            <styles.Media>
              <styles.SideImage image={image} useAspectRatio />
            </styles.Media>
          </styles.Content>
          {items.length > 0 ? (
            <styles.ContentContainer>
              {items.map(item => (
                <Appear delay={100} key={item.sys.id}>
                  <FeatureCard
                    heading={item.fields.heading}
                    subheading={item.fields.subheading}
                    subheadingColor={item.fields.subheadingColor}
                    text={item.fields.text}
                    link={item.fields.link}
                  />
                </Appear>
              ))}
            </styles.ContentContainer>
          ) : null}
        </Appear>
      </styles.Background>
    </OffsetSection>
  )
}

export default FeatureWithCardsSection
