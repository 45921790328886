import React from 'react'
import get from 'lodash/get'

import { Section } from 'bl-common/src/units/Section/Section'

import { CallToAction } from '../../CallToAction'

export const CallToActionSection = ({ section: { fields } }) => (
  <Section
    config={fields.config}
    top={{ xs: 4, md: 6 }}
    bottom={{ xs: 4, md: 6 }}
    gradient={{ fields: { colorName: 'softGradient6' } }}
  >
    <CallToAction
      title={get(fields, 'title')}
      description={get(fields, 'description')}
      buttonUrl={get(fields, 'buttonUrl')}
      buttonText={get(fields, 'buttonText')}
      image={get(fields, 'image')}
      gradient={get(fields, 'gradient')}
    />
  </Section>
)

export default CallToActionSection
