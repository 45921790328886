import React from 'react'
import get from 'lodash/get'

import { ScrollArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { getSectionProps } from '../../../utils/getSectionProps'
import { BreadcrumbLink } from '../../BreadcrumbLink'

export const HeaderSection = props => {
  const hasOffset = get(
    props.pageConfig,
    'fields.hasAbsolutelyPositionedNavigation'
  )
  const {
    tagline,
    heading,
    multilineSubheading,
    disclaimer,
    multilineDisclaimer,
    showScrollArrow = true,
    showBreadcrumbLink = false,
    config,
  } = getSectionProps(props)
  return (
    <Section
      top={{ xs: hasOffset ? 6 : 3.5, md: hasOffset ? 10 : 6 }}
      bottom={{ xs: showScrollArrow ? 3 : 1, md: showScrollArrow ? 6 : 3 }}
      config={config}
      as="header"
    >
      {showBreadcrumbLink && <BreadcrumbLink top={2} arrowScale={0.7} />}
      {tagline && (
        <Appear>
          <Type
            preset="label"
            as="h1"
            case="uppercase"
            bottom={{ xs: 2, md: 2 }}
          >
            {tagline}
          </Type>
        </Appear>
      )}
      <Appear>
        <Type
          preset="headlineLarge"
          as={tagline ? 'h2' : 'h1'}
          bottom={{ xs: 1, md: 2 }}
        >
          {heading}
        </Type>
      </Appear>
      {multilineSubheading && (
        <Appear>
          <Type
            preset="subtitle"
            bottom={{
              xs: showScrollArrow ? 6 : 3,
            }}
            maxWidth={800}
            multiline
          >
            {multilineSubheading}
          </Type>
        </Appear>
      )}
      {disclaimer && (
        <Appear>
          <Type
            preset="label"
            case="uppercase"
            bottom={{ xs: 2, md: 2 }}
            maxWidth={{ xs: 390, md: 900 }}
          >
            {disclaimer}
          </Type>
        </Appear>
      )}
      {multilineDisclaimer && (
        <Appear>
          <Type
            preset="label"
            case="uppercase"
            bottom={{ xs: 2, md: 2 }}
            maxWidth={{ xs: 390, md: 900 }}
            multiline
            dangerouslySetInnerHTML={{
              __html: formatHtmlText(multilineDisclaimer),
            }}
          />
        </Appear>
      )}
      {showScrollArrow && (
        <Appear>
          <ScrollArrow />
        </Appear>
      )}
    </Section>
  )
}
export default HeaderSection
