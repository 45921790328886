import React from 'react'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import get from 'lodash/get'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'
import { Slider } from 'bl-common/src/units/Slider'

import { RenderComponent } from '../../RenderComponent'

export const SliderSection = ({
  section: {
    fields: { heading, multilineSubheading, slider, config },
  },
}) => {
  // TODO: Switch to useBreakpoints
  const isLarge = useObserver(
    observe(viewportWidth(), vw => vw >= breakpoints.lg)
  )

  return (
    <Section
      bottom={{ xs: 2, md: 6 }}
      top={{ xs: 4, md: 6 }}
      config={config}
      hideOverflowX
    >
      <Appear observer>
        <Appear>
          <Type as="h2" preset="headlineLarge" bottom={{ xs: 0.5, md: 1 }}>
            {heading}
          </Type>
        </Appear>
        <Appear>
          <Type
            preset="subtitle"
            bottom={{ xs: 2, md: 6 }}
            maxWidth={650}
            multiline
          >
            {multilineSubheading}
          </Type>
        </Appear>
        <RenderComponent
          componentData={slider}
          contentType="slider"
          many
          limit={2}
        >
          {component => (
            <Slider
              key={get(component, 'sys.id')}
              scrollKey={get(component, 'sys.id')}
              items={get(component, 'fields.sliderItems')}
              pageMargin={isLarge ? 2 : 1}
            />
          )}
        </RenderComponent>
      </Appear>
    </Section>
  )
}

export default SliderSection
