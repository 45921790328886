import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { ResponsiveParallax } from 'bl-common/src/units/ResponsiveParallax'
import { mediaObj } from 'bl-common/src/utils/media'

export const MenuIntro = styled.div(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing[4],
}))

export const MenuWrapper = styled.div(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing[6],
}))

export const MenuItem = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gap: '16px',

  [mediaObj.md]: {
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: '0',
  },
})

export const MenuDetails = styled.div(({ theme }) => ({
  gridColumn: 'span 6',
  textAlign: 'center',
  background: colors.white,
  filter: 'drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.10));',
  padding: theme.spacing[3],

  '& > p:empty': {
    marginTop: theme.spacing[1],
  },
}))

export const MenuImg = styled(ResponsiveParallax)(({ theme }) => ({
  position: 'relative',
  gridColumn: 'span 3',
  order: 1,
  paddingBottom: '100%',
  marginTop: theme.spacing[3],

  [mediaObj.md]: {
    order: 'initial',
    margin: `${theme.spacing[4]} 0`,
  },
}))
