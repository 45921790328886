import React from 'react'
import styled, { css } from 'styled-components'

import { modularScale } from '../../constants/sizes'
import { between } from '../../utils/between'
import { media } from '../../utils/media'
import { Type } from '../Typography/Typography'

const Key = styled.dt`
  flex: 0 1 auto;
  margin-right: 1rem;
`
const Value = styled.dd`
  text-align: right;
  margin-left: auto;
  flex: 1 0 auto;
`

const Item = styled.dl`
  width: 100%;
  display: flex;
  margin: 0;
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[1.5]}`};
  &:nth-of-type(even) {
    background: linear-gradient(
      to right,
      rgba(245, 246, 245, 0.45),
      rgba(216, 231, 230, 0.45)
    );
  }
  &:nth-of-type(odd) {
    background: rgba(240, 240, 240, 0.15);
  }
  ${media.lg(css`
    max-width: ${between(480 / modularScale, 480)};
  `)};
`

export const KeyValueList = ({ list = [], title = undefined }) => (
  <div>
    {title && (
      <Type preset="labelSmall" case="uppercase" bottom={{ xs: 1, md: 1.5 }}>
        {title}
      </Type>
    )}
    {list &&
      list.map(item => (
        <Item key={item.key}>
          <Key>
            <Type preset="text">{item.key}</Type>
          </Key>
          <Value>
            <Type preset="text">{item.value}</Type>
          </Value>
        </Item>
      ))}
  </div>
)
