import React from 'react'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import { BLOCKS } from '@contentful/rich-text-types'
import styled, { css } from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { gridOffsetFrom, gridOffsetTo } from 'bl-common/src/constants/sizes'
import { LinkArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { IGradient } from 'bl-common/src/generated/contentful'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Section } from 'bl-common/src/units/Section/Section'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'
import { between } from 'bl-common/src/utils/between'
import { media, mediaMax } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'

import { HighlightsPlayer } from './HighlightsPlayer'

const CarouselContainer = styled.div`
  position: relative;

  ${media.md(css`
    ${mixins.siteGutter()}
    padding-bottom: ${({ theme }) => theme.spacing[5]};
  `)}
`

const CarouselContainerInner = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  z-index: 1;
  ${media.md(css`
    flex-direction: row;
  `)}
`

const CarouselContent = styled.div`
  opacity: 0;
  transform: translateY(50px);
  animation: fade-highlight-content 1900ms 500ms ease forwards,
    move-highlight-content 2950ms 550ms ease forwards;
  ${mediaMax.md(css`
    padding: 0 40px 72px;
  `)}
  ${media.md(css`
    width: ${mixins.span({ columns: 6, gutters: 5 })};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `)}
  ${media.lg(css`
    width: ${mixins.span({ columns: 3, gutters: 4 })};
  `)}
  @keyframes fade-highlight-content {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes move-highlight-content {
    0% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0);
    }
  }
`

const Arrow = styled.div`
  opacity: 0;
  margin-top: 32px;
  animation: fade-highlight-arrow 1000ms 2500ms forwards,
    jump-highlight-arrow ${durations.long * 2}ms ease-in-out infinite;
  animation-play-state: running;

  path {
    fill: ${colors.midGrey};
  }
  .t-dark & path,
  .gradient-dark & path {
    fill: ${colors.white};
  }

  @keyframes fade-highlight-arrow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes jump-highlight-arrow {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0);
    }
  }
`

const CarouselMedia = styled.div`
  ${media.md(css`
    width: ${mixins.span({ columns: 6, gutters: 6 })};
    padding-left: ${mixins.span({ columns: 0, gutters: 1 })};
    margin-left: auto;
    display: flex;
    align-items: baseline;
  `)}
  ${media.lg(css`
    width: ${mixins.span({ columns: 8, gutters: 7 })};
    padding-left: ${mixins.span({ columns: 1, gutters: 0 })};
    margin-left: auto;
    display: flex;
    align-items: baseline;
  `)}
`

const Carousel = styled.div`
  width: 100%;
  animation: 3000ms ease-in-out 0s 1 highlight-imageSlideInFromTop;

  @keyframes highlight-imageSlideInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-60px);
    }
    100% {
      opacity: 100%;
      transform: translateY(0);
    }
  }

  ${media.mlg(css`
    margin-right: 48px;
  `)};
`

const CarouselPlayer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${(520 / 375) * 100}%;
  margin-bottom: 48px;

  ${media.md(css`
    padding-bottom: ${(650 / 433) * 100}%;
    margin-bottom: 0;
  `)};
`

const ImageWrap = styled.div`
  display: none;
  ${media.mlg(css`
    display: block;
    width: ${mixins.span({ columns: 3, gutters: 2 })};
    flex-shrink: 0;
    animation: 3000ms ease-in-out 0s 1 highlight-imageSlideInFromVeryBottom;

    @keyframes highlight-imageSlideInFromVeryBottom {
      0% {
        opacity: 0;
        transform: translateY(150px);
      }
      100% {
        opacity: 100%;
        transform: translateY(0);
      }
    }
  `)};
`
const Image = styled.div<{ image: string }>`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: ${(357 / 238) * 100}%;
  transform: translateY(-48px);
`

const SectionDecorator = styled.div<{ gradient: IGradient }>`
  position: absolute;
  top: 75px;
  bottom: 0;
  left: ${gridOffsetFrom}px;
  right: 0;
  opacity: 0;
  transform: translateX(50px);
  animation: higlight-decorator-fade 1900ms 500ms ease forwards,
    move-highlight-decorator 2950ms 550ms ease forwards;

  ${media.md(`
    left: ${between(gridOffsetFrom, gridOffsetTo)};
  `)}

  .t-dark & {
    animation: higlight-decorator-dark-fade 1900ms 500ms ease forwards,
      move-highlight-decorator 2950ms 550ms ease forwards;
  }

  ${({
    gradient = {
      fields: {},
    },
  }) => {
    const { color1, color2 } = gradient.fields
    return (
      color1 &&
      color2 &&
      css`
        background: linear-gradient(${color1}, ${color2});
      `
    )
  }};

  @keyframes higlight-decorator-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes higlight-decorator-dark-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.1;
    }
  }
  @keyframes move-highlight-decorator {
    0% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(50px);
    }
  }
`

const documentOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type weight="normal" size={{ xs: 16, md: 24 }}>
        {children}
      </Type>
    ),
  },
}

const HighlightsHeader = ({
  section: {
    fields: {
      highlights,
      title,
      description,
      gradient,
      image,
      showArrow,
      config,
    },
  },
}) => {
  // TODO: Switch to useBreakpoints
  const isMobile = useObserver(
    observe(viewportWidth(), width => width < breakpoints.md)
  )

  return (
    <Section
      config={config}
      top={{ xs: 0, md: 2 }}
      bottom={{ xs: 4, md: 5 }}
      noHorizontalPadding
      hideOverflowX
    >
      <CarouselContainer>
        <CarouselContainerInner>
          <CarouselContent>
            {title ? (
              <Type
                as="h2"
                size={{ xs: 32, md: 64 }}
                weight="bold"
                bottom={{ xs: 1 }}
              >
                {title}
              </Type>
            ) : null}

            {!!description && (
              <RichTextRenderer
                document={description}
                customOptions={documentOptions}
              />
            )}
            {showArrow && (
              <Arrow>
                <LinkArrow
                  rotate={90}
                  height={isMobile ? 18 : 32}
                  width={isMobile ? 24 : 32}
                />
              </Arrow>
            )}
          </CarouselContent>
          <CarouselMedia>
            <Carousel>
              <CarouselPlayer>
                <HighlightsPlayer
                  highlights={highlights?.fields.highlights}
                  name={highlights?.fields.collectionName}
                />
              </CarouselPlayer>
            </Carousel>
            {image && (
              <ImageWrap>
                <SmartImage image={image}>
                  {imageUrl => <Image image={imageUrl} />}
                </SmartImage>
              </ImageWrap>
            )}
          </CarouselMedia>
        </CarouselContainerInner>
        {gradient ? <SectionDecorator gradient={gradient} /> : null}
      </CarouselContainer>
    </Section>
  )
}

export default HighlightsHeader
