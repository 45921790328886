import React from 'react'

import { Carousel } from '../../units/Carousel/Carousel'
import { SmartImage } from '../../units/SmartImage/SmartImage'
import { LightBoxItem } from '../LightBox/LightBoxItem'
import { LightBoxProvider } from '../LightBox/LightBoxProvider'
import { BackgroundImage, ImageWrap } from './styles'

type ImageCarouselProps = {
  id: React.Key
  images: { id?: React.Key; sys?: { id?: React.Key } }[]
  minItemWidthFrom?: unknown
  minItemWidthTo?: unknown
  endWidth?: unknown
  initialCard?: unknown
  renderControls: unknown
  width: unknown
}

export const ImageCarousel = ({
  id,
  images,
  minItemWidthFrom,
  minItemWidthTo,
  endWidth,
  initialCard,
  renderControls,
  width,
}: ImageCarouselProps) => {
  return (
    images && (
      <LightBoxProvider>
        <Carousel
          scrollKey={id}
          minItemWidthFrom={minItemWidthFrom}
          minItemWidthTo={minItemWidthTo}
          key={id}
          endWidth={endWidth}
          initialItem={initialCard}
          width={width}
          renderControls={renderControls}
        >
          {images.map(image => {
            return (
              <LightBoxItem key={image.id || image.sys.id} image={image}>
                <ImageWrap>
                  <SmartImage image={image}>
                    {image => (
                      <BackgroundImage
                        style={{
                          backgroundImage: `url(${image})`,
                          backgroundPosition: 'center',
                        }}
                      />
                    )}
                  </SmartImage>
                </ImageWrap>
              </LightBoxItem>
            )
          })}
        </Carousel>
      </LightBoxProvider>
    )
  )
}
