import React from 'react'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { ImageGrid } from 'bl-common/src/units/ImageGrid/ImageGrid'
import { Section } from 'bl-common/src/units/Section/Section'

import { formatGradient } from '../../../utils/formatters/elements'

const ImageGridSection = ({
  section: {
    fields: {
      heading,
      multilineSubheading,
      images,
      gradient,
      centerText,
      config,
    },
  },
}) => {
  return (
    <Section bottom={{ xs: 4, md: 6 }} top={{ xs: 2, md: 6 }} config={config}>
      <Appear observer>
        <Appear>
          <Type
            as="h2"
            preset="headlineLarge"
            textAlign={centerText ? 'center' : 'left'}
            bottom={{
              xs: multilineSubheading ? 1 : 2,
              md: multilineSubheading ? 2 : 3,
            }}
          >
            {heading}
          </Type>
        </Appear>
        {multilineSubheading && (
          <Appear>
            <Type
              as="p"
              preset="subtitle"
              bottom={{ xs: 4, md: 5 }}
              maxWidth={1000}
              textAlign={centerText ? 'center' : 'left'}
              style={centerText ? { margin: '0 auto' } : {}}
              multiline
            >
              {multilineSubheading}
            </Type>
          </Appear>
        )}
      </Appear>
      <ImageGrid images={images} gradient={formatGradient(gradient)} />
    </Section>
  )
}

export default ImageGridSection
