import React from 'react'
import chunk from 'lodash/chunk'
import head from 'lodash/head'
import tail from 'lodash/tail'
import take from 'lodash/take'
import takeRight from 'lodash/takeRight'
import styled, { css } from 'styled-components'

import { CoverCard } from 'bl-common/src/cards/CoverCard/CoverCard'
import { modularScale } from 'bl-common/src/constants/sizes'
import { between } from 'bl-common/src/utils/between'
import { media, mediaMax } from 'bl-common/src/utils/media'

type AmenityItem = {
  id: any
  type: any
  title: any
  subtitle: any
  height: any
  media: any
  fallbackMedia: any
  gradientOverlay: any
  grayscale: any
  link: any
}

type SpaAmenitiesProps = {
  items?: AmenityItem[]
  offset?: boolean
}

type GroupProps = {
  offsetY?: boolean
}

// 2 two items get the following layout:

/*+------------------+ +--------------------+
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  +-----------------------------------------+/*

  // 3 two items get the following layout:

/*+------------------+ +--------------------+
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||--------------------+
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  |                   ||                    |
  +-----------------------------------------+/*

// 4 items gets the following layout:

/*+-------------------------------+ +----------+
  |                   ||          | |          |
  |                   ||          | |          |
  |                   ||          | |          |
  |                   ||          | |          |
  |                   ||          | |          |
  |                   ||          | |          |
  |                   |-----------+ +----------+
  |                   ||                       |
  |                   ||                       |
  |                   ||                       |
  |                   ||                       |
  +--------------------------------------------+/*


  // 5 items gets the following layout:

/*+-------------------------------+ +----------+
  |                   ||          | |          |
  |                   ||          | |          |
  |                   ||          | |          |
  |                   ||          | |          |
  |                   ||          | |          |
  |                    -----------+ +----------+
  |                   ||          | |          |
  |                   ||          | |          |
  |                   ||          | |          |
  |                   ||          | |          |
  |                   ||          | |          |
  +--------------------------------------------+/*

   // 6 items gets the following layout:

/*+--------------------------------------+
  |                   |                  |
  |                   |                  |
  |                   |                  |
  |                   |                  |
  |                   +------------------+
  |                   |                  |
  |                   |                  |
  |                   |                  |
  |                   |                  |
  +-------------------+                  |
  |         |         |                  |
  |         |         |                  |
  |         |         +------------------+
  |         |         |                  |
  |         |         |                  |
  |         |         |                  |
  |         |         |                  |
  +---------+---------+-----------------+*/

// 7 items gets the following layout:

/*+--------------------------------------+
  |                   |         |        |
  |                   |         |        |
  |                   |         |        |
  |                   |         |        |
  |                   +------------------+
  |                   |                  |
  |                   |                  |
  |                   |                  |
  |                   |                  |
  +-------------------+                  |
  |         |         |                  |
  |         |         |                  |
  |         |         +------------------+
  |         |         |                  |
  |         |         |                  |
  |         |         |                  |
  |         |         |                  |
  +---------+---------+-----------------+*/

// and 8 items:

/*+-------------------+------------------+
  |                   |                  |
  |                   |                  |
  |                   |                  |
  |                   |                  |
  |                   +---------+--------+
  |                   |         |        |
  |                   |         |        |
  |                   |         |        |
  +-------------------+         |        |
  |                   |         |        |
  |                   +---------+--------+
  |                   |                  |
  |                   |                  |
  +---------+---------+                  |
  |         |         |                  |
  |         |         |                  |
  |         |         |                  |
  |         |         |                  |
  |         |         |                  |
  +---------+---------+------------------+*/

const Container = styled.div`
  display: flex;
  ${mediaMax.md(css`
    flex-wrap: wrap;
  `)};
`

const Group = styled.div<GroupProps>`
  display: flex;
  flex: 0 1 100%;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;

  ${media.md(css`
    flex: 0 1 50%;

    &:first-child {
      margin-right: 21px;
    }
    &:last-child {
      margin-left: 21px;
    }

    ${(props: GroupProps) =>
      props.offsetY &&
      css`
        transform: translateY(86px);
      `};
  `)};
`

const getGroups = items => {
  const count = items.length

  if (count === 2 || count === 3 || count === 4 || count === 5) {
    return [[head(items)], tail(items)]
  } else if (count === 6) {
    return chunk(items, 3)
  } else if (count === 7) {
    return [take(items, 3), takeRight(items, 4)]
  } else if (count === 8) {
    return chunk(items, 4)
  }

  return []
}

export const SpaAmenities = ({ items, offset }: SpaAmenitiesProps) => {
  const totalItems = items.length
  const groups = getGroups(items)

  if (totalItems < 2 || totalItems > 8) {
    console.warn(
      `Spa Amenities should be between 2 and 8. Instead got ${totalItems}.`
    )
    return null
  }

  const heights = {
    xlarge: between(712 / modularScale, 712),
    large: between(570 / modularScale, 570),
    medium: between(396 / modularScale, 396),
    small: between(329 / modularScale, 329),
  }

  // Sizings grouped by number of items by index
  const sizing = {
    2: {
      0: {
        0: { width: 'full', height: heights.xlarge },
      },
      1: {
        0: { width: 'full', height: heights.xlarge },
      },
    },
    3: {
      0: {
        0: { width: 'full', height: heights.xlarge },
      },
      1: {
        0: { width: 'full', height: heights.medium },
        1: { width: 'full', height: heights.medium },
      },
    },
    4: {
      0: {
        0: { width: 'full', height: heights.xlarge },
      },
      1: {
        0: { width: 'half', height: heights.medium },
        1: { width: 'half', height: heights.medium },
        2: { width: 'full', height: heights.small },
      },
    },
    5: {
      0: {
        0: { width: 'full', height: heights.xlarge },
      },
      1: {
        0: { width: 'half', height: heights.small },
        1: { width: 'half', height: heights.small },
        2: { width: 'half', height: heights.small },
        3: { width: 'half', height: heights.small },
      },
    },
    6: {
      0: {
        0: { width: 'full', height: heights.xlarge },
        1: { width: 'half', height: heights.medium },
        2: { width: 'half', height: heights.medium },
      },
      1: {
        0: { width: 'full', height: heights.small },
        1: { width: 'full', height: heights.large },
        2: { width: 'full', height: heights.small },
      },
    },
    7: {
      0: {
        0: { width: 'full', height: heights.xlarge },
        1: { width: 'half', height: heights.medium },
        2: { width: 'half', height: heights.medium },
      },
      1: {
        0: { width: 'half', height: heights.small },
        1: { width: 'half', height: heights.small },
        2: { width: 'full', height: heights.large },
        3: { width: 'full', height: heights.small },
      },
    },
    8: {
      0: {
        0: { width: 'full', height: heights.large },
        1: { width: 'full', height: heights.small },
        2: { width: 'half', height: heights.medium },
        3: { width: 'half', height: heights.medium },
      },
      1: {
        0: { width: 'full', height: heights.small },
        1: { width: 'half', height: heights.medium },
        2: { width: 'half', height: heights.medium },
        3: { width: 'full', height: heights.large },
      },
    },
  }

  return (
    <Container>
      {groups.map((group, groupIndex) => (
        <Group
          offsetY={offset && groupIndex === 1}
          key={`Group__${groupIndex}`}
        >
          {group.map((item: AmenityItem, index) => {
            return (
              <CoverCard
                key={item.id}
                sizing={sizing[totalItems][groupIndex][index]}
                title={item.title}
                subtitle={item.subtitle}
                media={item.media}
                fallbackMedia={item.fallbackMedia}
                gradientOverlay={item.gradientOverlay}
                link={item.link}
                bottomSpacing={3}
              />
            )
          })}
        </Group>
      ))}
    </Container>
  )
}
