import React from 'react'
import styled from 'styled-components'

import { zIndex } from '../constants/zIndex'
import { VisuallyHidden } from '../units/VisuallyHidden'
import { Link } from './Link'

export const BackgroundLink = styled(
  ({ linkComponent, children, zIndex, to, ...rest }) => {
    const isNextLink = !linkComponent
    const Component = linkComponent || Link
    return (
      <Component
        {...rest}
        to={isNextLink ? to : null}
        href={isNextLink ? null : to}
      >
        <VisuallyHidden>{children}</VisuallyHidden>
      </Component>
    )
  }
)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  cursor: pointer;
  z-index: ${zIndex.above};
`
