import styled, { css } from 'styled-components'

type ShadowProps = {
  zIndex?: number
}

export const Shadow = styled.div<ShadowProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 20px 37px -22px rgba(0, 0, 0, 0.3),
    0 22px 80px -20px rgba(0, 0, 0, 0.07);
  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `};
`
