import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'

import { Type } from '../../elements/Typography/Typography'
import { media } from '../../utils/media'
import { LightBoxItem } from '../LightBox/LightBoxItem'
import GalleryImage from './GalleryImage'

type HalfProps = {
  side?: string
}

type ItemContainerProps = {
  noPush?: boolean
}

const Container = styled.div`
  display: flex;
`

const Half = styled.div<HalfProps>`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  ${({ side }) =>
    side === 'left' &&
    css`
      padding-right: 16px;
      ${media.md(css`
        padding-right: ${({ theme }) => theme.spacing[2.5]};
      `)};
    `}
`

const Full = styled.div`
  flex: none;
`

const ItemContainer = styled.div<ItemContainerProps>`
  flex: 0 1 auto;
  padding-bottom: 16px;

  ${media.md(css`
    padding-bottom: ${({ theme }) => theme.spacing[4.5]};
  `)};
`

export const CommonAreasGallery = ({
  heading,
  multilineSubheading,
  tallImage1,
  tallImage2,
  squareImage1,
  squareImage2,
  fullWidthImage,
}) => (
  <Fragment>
    {heading && (
      <Type
        as="h2"
        preset="headline"
        bottom={{
          xs: multilineSubheading ? 0.5 : 2,
          md: multilineSubheading ? 0.5 : 4,
        }}
      >
        {heading}
      </Type>
    )}
    {multilineSubheading && (
      <Type preset="subtitle" bottom={{ xs: 2, md: 4 }} multiline>
        {multilineSubheading}
      </Type>
    )}
    <Container>
      <Half side="left">
        <ItemContainer>
          <LightBoxItem image={tallImage1}>
            <GalleryImage image={tallImage1} size="Tall" />
          </LightBoxItem>
        </ItemContainer>
        <ItemContainer>
          <LightBoxItem image={squareImage2}>
            <GalleryImage image={squareImage2} size="Short" />
          </LightBoxItem>
        </ItemContainer>
      </Half>
      <Half>
        <ItemContainer>
          <LightBoxItem image={squareImage1}>
            <GalleryImage image={squareImage1} size="Short" />
          </LightBoxItem>
        </ItemContainer>
        <ItemContainer>
          <LightBoxItem image={tallImage2}>
            <GalleryImage image={tallImage2} size="Tall" />
          </LightBoxItem>
        </ItemContainer>
      </Half>
    </Container>
    <Full>
      <ItemContainer noPush>
        <LightBoxItem image={fullWidthImage}>
          <GalleryImage image={fullWidthImage} size="Full Width" />
        </LightBoxItem>
      </ItemContainer>
    </Full>
  </Fragment>
)
