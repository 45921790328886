import React from 'react'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'

import { formatCoverCard } from '../../../utils/formatters'
import { SpaAmenities } from '../../SpaAmenities'

export const SpaAmenitiesSection = ({
  section: {
    fields: {
      heading,
      multilineSubheading,
      spaAmenities,
      offsetSecondColumn,
      config,
    },
  },
}) => (
  <Section
    top={{ xs: 2, md: 6 }}
    bottom={{ xs: 2, md: 8 }}
    config={config}
    textColor="light"
  >
    <Appear observer>
      <Appear>
        <Type
          as="h2"
          preset="headlineLarge"
          bottom={{
            xs: 1,
            md: multilineSubheading ? 1 : 3,
          }}
        >
          {heading}
        </Type>
      </Appear>
      {multilineSubheading && (
        <Appear>
          <Type preset="subtitle" bottom={6} multiline maxWidth={780}>
            {multilineSubheading}
          </Type>
        </Appear>
      )}
      <SpaAmenities
        items={spaAmenities.map(card => formatCoverCard(card))}
        offset={offsetSecondColumn}
      />
    </Appear>
  </Section>
)

export default SpaAmenitiesSection
