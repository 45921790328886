import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>

export const Photos = (props: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 10V20H16V10H4ZM6 8V4C6 3.46957 6.21071 2.96086 6.58579 2.58579C6.96086 2.21071 7.46957 2 8 2H20C20.5304 2 21.0391 2.21071 21.4142 2.58579C21.7893 2.96086 22 3.46957 22 4V14C22 14.5304 21.7893 15.0391 21.4142 15.4142C21.0391 15.7893 20.5304 16 20 16H18V20C18 20.5304 17.7893 21.0391 17.4142 21.4142C17.0391 21.7893 16.5304 22 16 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V10C2 9.46957 2.21071 8.96086 2.58579 8.58579C2.96086 8.21071 3.46957 8 4 8H6ZM8 8H16C16.5304 8 17.0391 8.21071 17.4142 8.58579C17.7893 8.96086 18 9.46957 18 10V14H20V4H8V8ZM8 17C7.20435 17 6.44129 16.6839 5.87868 16.1213C5.31607 15.5587 5 14.7956 5 14C5 13.2044 5.31607 12.4413 5.87868 11.8787C6.44129 11.3161 7.20435 11 8 11C8.79565 11 9.55871 11.3161 10.1213 11.8787C10.6839 12.4413 11 13.2044 11 14C11 14.7956 10.6839 15.5587 10.1213 16.1213C9.55871 16.6839 8.79565 17 8 17ZM8 15C8.26522 15 8.51957 14.8946 8.70711 14.7071C8.89464 14.5196 9 14.2652 9 14C9 13.7348 8.89464 13.4804 8.70711 13.2929C8.51957 13.1054 8.26522 13 8 13C7.73478 13 7.48043 13.1054 7.29289 13.2929C7.10536 13.4804 7 13.7348 7 14C7 14.2652 7.10536 14.5196 7.29289 14.7071C7.48043 14.8946 7.73478 15 8 15Z" />
    <path d="M9 8C9 7.20435 9.31607 6.44129 9.87868 5.87868C10.4413 5.31607 11.2044 5 12 5C12.7956 5 13.5587 5.31607 14.1213 5.87868C14.6839 6.44129 15 7.20435 15 8H13C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8H9ZM10.864 21.518L13.589 16.846C13.6678 16.7107 13.7772 16.5958 13.9086 16.5105C14.0399 16.4252 14.1894 16.372 14.3451 16.355C14.5007 16.3381 14.6582 16.358 14.8048 16.413C14.9514 16.4681 15.083 16.5568 15.189 16.672L16.276 17.856L17.749 16.502L16.661 15.319C16.343 14.9733 15.9483 14.707 15.5087 14.5417C15.069 14.3764 14.5967 14.3166 14.1297 14.3672C13.6627 14.4178 13.2142 14.5773 12.8201 14.833C12.4261 15.0886 12.0975 15.4332 11.861 15.839L9.136 20.51L10.864 21.517V21.518ZM17.376 8.549C17.9375 8.33229 18.5513 8.28984 19.1373 8.4272C19.7233 8.56455 20.2543 8.87534 20.661 9.319L21.749 10.502L20.276 11.856L19.189 10.672C19.0414 10.5114 18.8453 10.4034 18.6306 10.3646C18.416 10.3258 18.1945 10.3582 18 10.457V10C18 9.429 17.76 8.913 17.376 8.549Z" />
  </svg>
)
