import React from 'react'
import get from 'lodash/get'

import { Section } from 'bl-common/src/units/Section/Section'

import { TopicGradientSection } from '../../TopicGradientSection'

const GradientSection = ({
  section: {
    fields: { title, textContent, image, textPosition, pricing, config },
  },
}) => {
  return (
    <Section
      bottom={{ xs: 4, md: 5, lg: 8 }}
      top={{ xs: 4, md: 5, lg: 8 }}
      config={config}
      hideOverflowX
    >
      <TopicGradientSection
        title={title}
        textContent={textContent}
        image={image}
        textPosition={textPosition}
        pricing={pricing}
        gradient={get(config, 'fields.gradient')}
      />
    </Section>
  )
}

export default GradientSection
