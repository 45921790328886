import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { Field, FieldProps, Formik, FormikErrors, getIn } from 'formik'
import { useMutation } from 'urql'

import { colors } from 'bl-common/src/constants/colors'
import { ProgressButton } from 'bl-common/src/elements/ProgressButton/ProgressButton'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Input } from 'bl-common/src/form/Input/Input'
import { ISectionGiftCardBalanceFields } from 'bl-common/src/generated/contentful'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'

import { graphql } from '../../../../gql'
import { getSectionProps } from '../../../../utils/getSectionProps'
import { BalanceResult } from './BalanceResult'
import * as styles from './styles'

interface FormValues {
  giftCardBalance: string
}

const documentOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Type preset="headlineMedium" bottom={{ xs: 1, md: 1.5 }}>
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Type preset="subtitle" bottom={{ xs: 1, md: 2 }}>
        {children}
      </Type>
    ),
  },
}

const FindGiftCardMutation = graphql(`
  mutation findGiftcardQuery(
    $input: BookingFlowFindGiftCardRequestModelInput!
  ) {
    Bluelagoon {
      Booking {
        postBookingflowServerGiftcardFind(input: $input) {
          ...GiftCardFragment
        }
      }
    }
  }
`)

export const GiftCardBalanceSection = (props: any) => {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<object | undefined>(undefined)

  const [{ data }, findGiftcard] = useMutation(FindGiftCardMutation)

  const submit = async (values: FormValues) => {
    setErrors({})

    const { error } = await findGiftcard({
      input: { giftCardNo: values.giftCardBalance },
    })

    if (error && error !== null) {
      setErrors({
        giftCardBalance: t('giftCardNotFound'),
      })
      return
    }

    return true
  }

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {}

    if (!values.giftCardBalance) {
      errors.giftCardBalance = t('giftCardNumberReqired')
    }

    return errors
  }

  const initialValues: FormValues = {
    giftCardBalance: '',
  }

  const {
    image,
    backgroundGradient,
    config,
    textColor,
    content,
    textContentSide,
    getBalanceButtonText,
  } = getSectionProps(props) as ISectionGiftCardBalanceFields

  return (
    <OffsetSection
      spacingFromEdge={{ xs: 0, md: 4 }}
      config={config}
      hideOverflowX
    >
      <styles.Container
        mobileGradient={backgroundGradient}
        style={{ color: textColor === 'light' ? colors.light : colors.dark }}
      >
        <styles.ContentContainer
          style={{ order: textContentSide === 'right' ? 1 : 0 }}
        >
          {!!content && (
            <RichTextRenderer
              document={content}
              customOptions={documentOptions}
            />
          )}
          <Formik
            onSubmit={submit}
            validate={validate}
            initialValues={initialValues}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors: _errors, touched, submitCount }) => {
              const err = { ...errors, ..._errors }
              const subtleError = !!(
                (submitCount > 0 || touched.giftCardBalance) &&
                getIn(err, 'giftCardBalance')
              )
              return (
                <styles.Form onSubmit={handleSubmit}>
                  <styles.TopWrapper>
                    <styles.Label htmlFor="giftCardBalance" isRequired>
                      {t('giftCardNumber')}
                    </styles.Label>
                    {subtleError && (
                      <styles.ErrorMessage>
                        {getIn(err, 'giftCardBalance')}
                      </styles.ErrorMessage>
                    )}
                  </styles.TopWrapper>
                  <styles.BottomWrapper>
                    <styles.InputContainer>
                      <Field name="giftCardBalance">
                        {({ field }: FieldProps) => {
                          return (
                            <Input
                              id={field.name}
                              {...field}
                              hasError={subtleError}
                              label={field.name}
                              placeholder=""
                            />
                          )
                        }}
                      </Field>
                    </styles.InputContainer>
                    <ProgressButton type="submit" top={{ xs: 1, lg: 0 }}>
                      {getBalanceButtonText}
                    </ProgressButton>
                  </styles.BottomWrapper>
                </styles.Form>
              )
            }}
          </Formik>
          {data && (
            <BalanceResult
              giftcard={
                data.Bluelagoon.Booking.postBookingflowServerGiftcardFind
              }
            />
          )}
        </styles.ContentContainer>
        <styles.ImageContainer
          gradient={backgroundGradient}
          textContentSide={textContentSide}
        >
          <styles.Image image={image} />
        </styles.ImageContainer>
      </styles.Container>
    </OffsetSection>
  )
}

export default GiftCardBalanceSection
