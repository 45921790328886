import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { gradients } from 'bl-common/src/constants/gradients'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Amount } from 'bl-common/src/elements/Amount'
import { BackgroundLink } from 'bl-common/src/elements/BackgroundLink'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { StyledImage } from 'bl-common/src/units/SmartImage/StyledImage'
import { between } from 'bl-common/src/utils/between'
import { media, mediaMax } from 'bl-common/src/utils/media'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

type CardWrapProps = {
  isLarge?: boolean
}

type ButtonPositionerProps = {
  noTopMargin?: boolean
  canWrap?: any
}

type ContainerProps = {
  inverted?: boolean
}

type CardProps = {
  isLarge?: boolean
  isRetreat?: boolean
  isRight?: boolean
  gradientKey?: any
}

type CardBackgroundProps = {
  gradientKey?: any
}

const StyledButton = styled(({ isOfferButton, ...rest }) => (
  <Button {...rest} />
))`
  ${({ isOfferButton }) =>
    isOfferButton &&
    css`
      margin-top: 0.333rem;
      ${media.md(css`
        opacity: 0.95;
        margin-top: 0;
        margin-left: auto;
        margin-right: -1rem;
        transition: opacity ${durations.short}ms;
        &:hover {
          opacity: 1;
        }
      `)};
    `};
`

const CardWrap = styled.div<CardWrapProps>`
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  transition: all ${durations.short}ms ease,
    transform ${durations.micro}ms ease-out;

  ${media.md(css`
    position: absolute;
  `)};

  ${mediaMax.md(css`
    &:nth-child(2) {
      ${props =>
        css`
          margin-top: ${props.theme.spacing[1.5]};
        `};
    }
  `)};
`

const Disclaimer = styled(({ ...rest }) => (
  <Type preset="text" bottom={{ xs: 1, md: 2 }} {...rest} />
))`
  max-width: 80%;
  margin-top: auto;
`

const ButtonPositioner = styled.div<ButtonPositionerProps>`
  display: flex;
  margin-top: ${props => (props.noTopMargin ? 0 : 'auto')};
  transform: scale(1);
  transition: transform ${durations.micro}ms ease;
  pointer-events: all;
  z-index: ${zIndex.above};

  ${mediaMax.md(css`
    ${({ canWrap }: ButtonPositionerProps) =>
      canWrap &&
      css`
        flex-wrap: wrap;
      `};
    > * {
      flex: 1 0 auto;
    }
  `)};
`

const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: ${zIndex.above};

  ${media.md(css`
    &::before {
      content: '';
      display: block;
      padding-top: 52.82%;
    }

    &::after {
      background: ${(props: ContainerProps) =>
        props.inverted
          ? 'linear-gradient(-138deg, #50555B 6%, #908C8B 100%)'
          : 'linear-gradient(-138deg, #C4D4DF 6%, #EFEEEA 100%)'};
      bottom: -10%;
      content: '';
      display: block;
      left: 20%;
      margin: auto;
      opacity: 0.36;
      position: absolute;
      right: 20%;
      top: 20%;
      z-index: -1;
    }
  `)};
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  pointer-events: none;
  z-index: ${zIndex.above};
`

const Card = styled.div<CardProps>`
  color: ${colors.white};
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  transition: transform ${durations.short}ms;
  padding: ${props => (props.isLarge ? between(34, 104) : between(21, 64))};
  padding-top: ${between(40, 120)};
  pointer-events: all;

  &::before {
    content: '';
    background: linear-gradient(-30deg, transparent 0%, rgba(0, 0, 0, 0.5) 87%);
    position: absolute;
    top: -1px;
    z-index: ${zIndex.behind};
    bottom: -1px;
    left: -1px;
    right: -1px;
  }

  ${mediaMax.md(css`
    padding: 80px 24px 24px;
    height: 456px;
    width: calc(100% + 24px);
    z-index: ${zIndex.above};

    &:first-child {
      z-index: ${zIndex.above2};
    }
  `)};

  /* On desktop the larger item parallax more */
  ${media.md(css`
    max-height: 80vh;
    padding: ${(props: CardProps) =>
      props.isLarge ? between(34, 104) : between(21, 64)};
    padding-top: ${between(40, 120)};
    position: absolute;
    top: 0;
    will-change: transform;
    width: ${(props: CardProps) =>
      props.isLarge ? (props.isRetreat ? '65.56%' : '74.32%') : '45%'};

    ${(props: CardProps & { theme: any }) =>
      !props.isLarge &&
      css`
        top: ${props.theme.spacing[12]};
      `};
  `)};

  ${(props: CardProps) =>
    props.isRight &&
    css`
      right: 0;
      ${mediaMax.md(css`
        top: 16px;
      `)};
    `};

  ${props =>
    props.gradientKey &&
    gradients.branding[props.gradientKey] &&
    css`
      background: linear-gradient(
        ${gradients.branding[props.gradientKey].backgroundDegrees},
        ${gradients.branding[props.gradientKey].backgroundColors[0]} 0%,
        ${gradients.branding[props.gradientKey].backgroundColors[1]} 100%
      );
    `};

  ${props =>
    props.isLarge &&
    css`
      ${mediaMax.md(css`
        left: -24px;
      `)};
      &::after {
        background: ${colors.white};
        bottom: -100px;
        content: '';
        display: none;
        left: -100px;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 100px;
        width: 75%;
        z-index: ${zIndex.behind};
      }
    `};
`

const CardBackground = styled(StyledImage)<CardBackgroundProps>`
    position: absolute;
    transform: scale(1);
    top: -16px;
    right: -16px;
    bottom: -16px;
    left: -16px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3;
    pointer-events: none;
    transition: opacity ${durations.short}ms, transform ${durations.long}ms;
  }

  ${Card}:hover & {
    transform: scale(1.035);
  }
`

export const ParallaxPanels = ({
  experiences: [normal, retreat],
  inverted,
}) => {
  const normalHasDisclaimer = !!normal.disclaimer
  const retreatHasDisclaimer = !!retreat.disclaimer
  const retreatHasOfferButton = !!retreat.showOfferButton

  return (
    <Container inverted={inverted} data-cy="parallax-panel">
      <CardWrap isLarge={!inverted}>
        <Card isLarge={!inverted} gradientKey={normal.gradientKey}>
          <CardBackground
            gradientKey={normal.gradientKey}
            image={normal.image}
          />
          <BackgroundLink
            aria-hidden="true"
            tabIndex={-1}
            to={normal.primaryCtaUrl}
          />
          <Content>
            <Type as="h3" preset="headline" bottom={0.5}>
              {normal.title}
            </Type>
            <Type
              size={{ xs: 16, md: 20, xl: 24 }}
              weight="light"
              bottom={{ xs: 1, md: 2 }}
              maxWidth={!inverted ? 'auto' : { xs: 'none', md: 310 }}
            >
              {normal.description}
            </Type>
            {normal.priceRef && (
              <Type preset="text" bottom={{ xs: 1, md: 2 }}>
                <Amount
                  value={normal.priceRef}
                  format={normal.priceFormat}
                  fallback={normal.price}
                />
              </Type>
            )}
            {normal.body && (
              <Type
                preset="text"
                weight="bold"
                bottom={{ xs: 1, md: 2 }}
                maxWidth={!inverted ? 'auto' : { xs: 'none', md: 310 }}
                dangerouslySetInnerHTML={{
                  __html: formatHtmlText(normal.body),
                }}
              />
            )}
            {normalHasDisclaimer && (
              <Disclaimer>{normal.disclaimer}</Disclaimer>
            )}
            <ButtonPositioner noTopMargin={normalHasDisclaimer}>
              <StyledButton
                paddingSize="large"
                to={normal.primaryCtaUrl}
                minWidth={{ lg: 175 }}
                style={inverted ? { flex: 1 } : {}}
                preset={`${normal.gradientKey}Light`}
              >
                {normal.primaryCtaTitle}
              </StyledButton>
              <StyledButton
                paddingSize="large"
                to={normal.secondaryCtaUrl}
                preset={`${normal.gradientKey}Dark`}
                minWidth={{ xs: 100, lg: 175 }}
                style={inverted ? { flex: 1 } : {}}
              >
                {normal.secondaryCtaTitle}
              </StyledButton>
            </ButtonPositioner>
          </Content>
        </Card>
      </CardWrap>
      <CardWrap isLarge={inverted}>
        <Card
          isRight
          isRetreat
          isLarge={inverted}
          gradientKey={retreat.gradientKey}
        >
          <CardBackground
            gradientKey={normal.gradientKey}
            image={retreat.image}
          />
          <BackgroundLink
            aria-hidden="true"
            tabIndex={-1}
            to={retreat.primaryCtaUrl}
          />
          <Content>
            <Type preset="headline" bottom={0.5}>
              {retreat.title}
            </Type>
            <Type
              size={{ xs: 16, md: 20, xl: 24 }}
              weight="light"
              bottom={{ xs: 1, md: 2 }}
            >
              {retreat.description}
            </Type>
            {retreat.priceRef && (
              <Type preset="text">
                <Amount
                  value={retreat.priceRef}
                  format={retreat.priceFormat}
                  fallback={retreat.price}
                />
              </Type>
            )}
            {retreat.body && (
              <Type
                preset="text"
                weight="bold"
                bottom={{ xs: 1, md: 2 }}
                dangerouslySetInnerHTML={{
                  __html: formatHtmlText(retreat.body),
                }}
              />
            )}
            {retreatHasDisclaimer && (
              <Disclaimer>{retreat.disclaimer}</Disclaimer>
            )}
            <ButtonPositioner
              noTopMargin={retreatHasDisclaimer}
              canWrap={retreatHasOfferButton}
            >
              <StyledButton
                paddingSize="large"
                to={retreat.primaryCtaUrl}
                minWidth={{ xs: 100, lg: 175 }}
                style={!inverted ? { flex: 1 } : {}}
                preset={`${retreat.gradientKey}Light`}
              >
                {retreat.primaryCtaTitle}
              </StyledButton>
              <StyledButton
                paddingSize="large"
                to={retreat.secondaryCtaUrl}
                preset={`${retreat.gradientKey}Dark`}
                minWidth={{ xs: 100, lg: 175 }}
                style={!inverted ? { flex: 1 } : {}}
              >
                {retreat.secondaryCtaTitle}
              </StyledButton>
            </ButtonPositioner>
          </Content>
        </Card>
      </CardWrap>
    </Container>
  )
}

ParallaxPanels.propTypes = {
  experiences: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      disclaimer: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      gradientKey: PropTypes.string,
      primaryCtaTitle: PropTypes.string,
      primaryCtaUrl: PropTypes.string,
      secondaryCtaTitle: PropTypes.string,
      secondaryCtaUrl: PropTypes.string,
      priceRef: PropTypes.number,
      priceFormat: PropTypes.string,
      price: PropTypes.string,
    })
  ),
}

export default ParallaxPanels
