import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { colors } from '../../constants/colors'
import { BackgroundImage } from '../../elements/BackgroundImage'
import { Type } from '../../elements/Typography/Typography'

type ContentProps = {
  withImage?: boolean
}

const Container = styled.div`
  color: ${props =>
    String(props.color).toLowerCase() === 'light' ? colors.white : colors.dark};
  display: flex;
  height: 100%;
  position: relative;
`
const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ withImage }) => !withImage && 'flex-end'};
  padding: ${({ theme: { spacing }, withImage }) =>
    withImage
      ? `${spacing[20]} ${spacing[2.5]} ${spacing[2.5]} ${spacing[2.5]}`
      : `${spacing[5]} ${spacing[2.5]} ${spacing[2.5]}`};
`

const StyledImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: ${({ theme: { spacing } }) => `${spacing[18]}`};
  position: absolute;
  top: 0;
  width: 100%;
`

export const CopyCard = ({
  heading,
  textContent,
  subtext,
  backgroundColor,
  color,
  image,
  alignText,
}) => {
  return (
    <Container color={color} style={{ background: colors[backgroundColor] }}>
      {image && <StyledImage image={image} />}
      <Content
        withImage={image}
        style={{
          justifyContent: alignText === 'top' ? 'flex-start' : 'flex-end',
        }}
      >
        {heading && (
          <Type
            preset="headlineMedium"
            bottom={{
              xs: 1,
              md: 2,
            }}
            dangerouslySetInnerHTML={{ __html: formatHtmlText(heading) }}
          />
        )}
        {textContent && (
          <Type
            preset="textLarge"
            bottom={
              subtext && {
                xs: 1,
                md: 2,
              }
            }
            multiline
            dangerouslySetInnerHTML={{ __html: formatHtmlText(textContent) }}
          />
        )}
        {subtext && (
          <Type
            preset="textSmall"
            bottom={{
              xs: 1,
              md: 2,
            }}
            multiline
            dangerouslySetInnerHTML={{ __html: formatHtmlText(subtext) }}
          />
        )}
      </Content>
    </Container>
  )
}

CopyCard.propTypes = {
  heading: PropTypes.string,
  textContent: PropTypes.string,
  subtext: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.object,
  alignText: PropTypes.string,
}

CopyCard.defaultProps = {
  backgroundColor: 'white',
  color: 'dark',
  alignText: 'bottom',
}
