import React from 'react'

import { Appear } from 'bl-common/src/units/Appear'
import { Hero } from 'bl-common/src/units/Hero'
import { Section } from 'bl-common/src/units/Section/Section'

import { formatGradient } from '../../../utils/formatters/elements'
import { getSectionProps } from '../../../utils/getSectionProps'

const FRAME_EXPANSION = 6

const HeroSection = props => {
  const {
    fullSizeImage,
    mobileCroppedImage,
    video,
    offsetDirection,
    backgroundContrast,
    backgroundPosition,
    frameGradient,
    config,
    expandFrame,
  } = getSectionProps(props)
  return fullSizeImage || video ? (
    <Section
      noHorizontalPadding
      top={{ md: 6 }}
      bottom={{ md: expandFrame ? 5 + FRAME_EXPANSION : 5 }}
      config={config}
    >
      <Appear delay={300}>
        <Hero
          offsetDirection={offsetDirection}
          fullSizeImage={fullSizeImage}
          mobileCroppedImage={mobileCroppedImage}
          backgroundContrast={backgroundContrast}
          backgroundPosition={backgroundPosition}
          frameGradient={formatGradient(frameGradient)}
          expandFrame={expandFrame}
          video={video}
        />
      </Appear>
    </Section>
  ) : null
}

export default HeroSection
