import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import styled, { css } from 'styled-components'

import { ContentfulImage } from '../elements/ContentfulImage'
import { Type } from '../elements/Typography/Typography'
import { RichTextRenderer } from '../richText/RichTextRenderer'
import { Appear } from '../units/Appear'
import { media } from '../utils/media'

type TextContentProps = {
  textContentSide?: string
}

type MediaContentProps = {
  textContentSide?: string
}

type TwoImagesWrapperProps = {
  blowUpImage?: boolean
}

type ImageWrapProps = {
  blowUpImage?: boolean
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const TextContent = styled.div<TextContentProps>`
  flex: 1 0 100%;

  ${media.md(css`
    flex: 1 0 50%;
    padding-top: ${({ theme }) => theme.spacing[6]};
    ${({ textContentSide, theme }: TextContentProps & { theme: any }) => css`
      padding-left: ${textContentSide === 'right' ? theme.spacing[7] : 0};
      padding-right: ${textContentSide === 'right' ? 0 : theme.spacing[7]};
    `}
  `)}
`

const MediaContent = styled.div<MediaContentProps>`
  flex: 1 0 50%;
  ${({ textContentSide, theme }) => css`
    margin-top: ${textContentSide === 'right' ? 0 : theme.spacing[4]};
    margin-bottom: ${textContentSide === 'right' ? theme.spacing[4] : 0};
  `}
`

const SingleImage = styled.div`
  display: none;
  position: relative;

  ${media.md(css`
    display: block;
    height: 110%;
    min-height: 600px;
    width: 100%;
  `)}
`

const TwoImagesWrapper = styled.div<TwoImagesWrapperProps>`
  position: relative;
  display: grid;
  column-gap: ${({ theme }) => theme.spacing[1.5]};
  grid-template-columns: ${({ blowUpImage }) =>
    blowUpImage ? '1fr 1.4fr' : '1fr 1fr'};
  height: 100%;
  min-height: 250px;
  ${media.md(css`
    column-gap: ${({ theme }) => theme.spacing[2]};
    grid-template-columns: ${({ blowUpImage }: TwoImagesWrapperProps) =>
      blowUpImage ? '1fr 1.2fr' : '1fr 1fr'};
  `)};
`

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const ImageWrap = styled.div<ImageWrapProps>`
  height: 110%;
  position: relative;

  :nth-child(2) {
    ${ImageWrapper} {
      height: ${({ blowUpImage }) => (blowUpImage ? '120%' : '110%')};
    }
  }

  ${media.md(css`
    :nth-child(2) {
      ${ImageWrapper} {
        top: ${({ theme, blowUpImage }: ImageWrapProps & { theme: any }) =>
          blowUpImage ? theme.spacing[3] : theme.spacing[6]};
      }
    }
  `)}
`

const documentOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type
        preset="headlineLarge"
        as="h2"
        bottom={{ xs: 1, md: 2 }}
        top={{ xs: 1, md: 1 }}
        maxWidth={500}
      >
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type preset="labelLarge" as="p" weight="light">
        {children}
      </Type>
    ),
  },
}

const renderImages = (images = [], blowUpImage) => {
  if (images.length === 0) {
    return
  }

  return images.length > 1 ? (
    <TwoImagesWrapper blowUpImage={blowUpImage}>
      {images.map(image => (
        <ImageWrap
          blowUpImage={blowUpImage}
          key={image.sys ? image.sys.id : image}
        >
          <ImageWrapper>
            <ContentfulImage image={image} sizes="50vw" />
          </ImageWrapper>
        </ImageWrap>
      ))}
    </TwoImagesWrapper>
  ) : (
    <SingleImage>
      <ContentfulImage image={images[0]} />
    </SingleImage>
  )
}

export const CtaHero = ({
  textContent,
  images,
  textContentSide,
  videoIds,
  blowUpImage,
}) => {
  return (
    <Appear observer>
      <Container>
        <TextContent
          style={{ order: textContentSide === 'right' ? 1 : 0 }}
          textContentSide={textContentSide}
        >
          <Appear>
            {!!textContent && (
              <RichTextRenderer
                document={textContent}
                customOptions={documentOptions}
              />
            )}
          </Appear>
        </TextContent>
        <MediaContent textContentSide={textContentSide}>
          {videoIds ? null : (
            <Appear observer>{renderImages(images, blowUpImage)}</Appear>
          )}
        </MediaContent>
      </Container>
    </Appear>
  )
}
