import React from 'react'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import styled, { css } from 'styled-components'
import { map } from 'styled-components-breakpoint'

import { GradientOffsetCard } from 'bl-common/src/cards/GradientOffsetCard/GradientOffsetCard'
import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import {
  ICardGridFields,
  IGradientFields,
} from 'bl-common/src/generated/contentful'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'
import { media } from 'bl-common/src/utils/media'

import { formatButton, formatGradient } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'

const CardsWrapper = styled.div<{
  gradient?: IGradientFields
  columnsCount?: ICardGridFields['columnsCount']
}>`
  display: grid;
  row-gap: ${({ theme }) => theme.spacing[4]};
  grid-template-columns: 1fr;
  height: min-content;
  position: relative;

  padding: ${({ theme }) => `0 ${theme.spacing[2.5]}`};

  ${media.md(css`
    padding: ${({ theme }) => `0 ${theme.spacing[5]}`};
    row-gap: ${({ theme }) => theme.spacing[6]};
  `)}
  ::after {
    position: absolute;
    left: 0;
    right: 0;
    top: ${({ theme }) => theme.spacing[4]};
    height: 100%;
  }

  ${({ gradient }) =>
    gradient &&
    css`
      ::after {
        content: '';
        background: linear-gradient(
          ${gradient.degrees || 'to right'},
          ${gradient.color1} 0%,
          ${gradient.color2} 100%
        );
      }
    `}

  ${({ columnsCount, theme }) =>
    columnsCount &&
    columnsCount.xs &&
    css`
      grid-template-columns: repeat(${columnsCount.xs}, 1fr);
      column-gap: ${columnsCount.xs > 2 ? theme.spacing[2] : theme.spacing[8]};
    `}
  

  ${({ columnsCount, theme }) =>
    columnsCount &&
    media.md(
      map(
        columnsCount,
        val => `grid-template-columns: repeat(${val}, 1fr);
      column-gap: ${val > 2 ? theme.spacing[2] : theme.spacing[8]};
      `
      )
    )}
`

const CardsWrapperWrapper = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 18vw;
  grid-template-columns: 1fr 1fr;
`

const GradientOffsetCardsSection = props => {
  const {
    heading,
    multilineSubheading,
    cardsLayout,
    backgroundGradient,
    config,
  } = getSectionProps(props)
  const type = cardsLayout.sys.contentType.sys.id
  const cards = cardsLayout.fields
  const formattedGradient = formatGradient(backgroundGradient)

  // TODO: Switch to useBreakpoints
  const isNotMobile = useObserver(
    observe(viewportWidth(), vw => vw >= breakpoints.md)
  )

  return (
    <Section
      bottom={{ xs: 4, md: 6 }}
      top={{ xs: 4, md: 6 }}
      config={config}
      noHorizontalPadding
    >
      <Appear>
        {heading && (
          <Type
            as="h3"
            preset="headlineLarge"
            bottom={{ xs: 1, md: 2 }}
            left={{ xs: 2, md: 5 }}
            right={{ xs: 2, md: 5 }}
            maxWidth={600}
          >
            {heading}
          </Type>
        )}
        {multilineSubheading && (
          <Type
            preset="subtitle"
            multiline
            bottom={{ xs: 2, md: 3 }}
            left={{ xs: 2, md: 5 }}
            right={{ xs: 2, md: 5 }}
            maxWidth={1200}
          >
            {multilineSubheading}
          </Type>
        )}
      </Appear>
      {type === 'cardGrid' && (
        <CardsWrapper
          gradient={formattedGradient}
          columnsCount={cardsLayout.fields.columnsCount}
        >
          {cards.cards.map(card => (
            <GradientOffsetCard
              {...card.fields}
              button={formatButton(card.fields.button)}
              key={card.sys.id}
            />
          ))}
        </CardsWrapper>
      )}
      {type === 'cardColumns' &&
        (isNotMobile ? (
          <CardsWrapperWrapper>
            <CardsWrapper>
              {cards.leftColumn &&
                cards.leftColumn.map(card => (
                  <GradientOffsetCard {...card.fields} key={card.sys.id} />
                ))}
            </CardsWrapper>
            <CardsWrapper>
              {cards.rightColumn &&
                cards.rightColumn.map(card => (
                  <GradientOffsetCard {...card.fields} key={card.sys.id} />
                ))}
            </CardsWrapper>
          </CardsWrapperWrapper>
        ) : (
          <CardsWrapper>
            {cards.leftColumn &&
              cards.leftColumn.map(card => (
                <GradientOffsetCard {...card.fields} key={card.sys.id} />
              ))}
            {cards.rightColumn &&
              cards.rightColumn.map(card => (
                <GradientOffsetCard {...card.fields} key={card.sys.id} />
              ))}
          </CardsWrapper>
        ))}
    </Section>
  )
}

export default GradientOffsetCardsSection
