import React from 'react'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Link } from 'bl-common/src/elements/Link'
import { Markdown } from 'bl-common/src/elements/Markdown/Markdown'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Section } from 'bl-common/src/units/Section/Section'
import { media } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'

type ContainerProps = {
  hasRelatedItems?: boolean
}

const BREAK = 1024

const RelatedLink = styled(Link)`
  color: ${colors.deepBlue};
  display: block;
  font-weight: 600;
`

const Content = styled.div`
  @media (min-width: ${BREAK}px) {
    flex: 1 0 65.48%;
    margin-right: ${mixins.span({ columns: 1, gutters: 2 })};
    max-width: ${mixins.span({ columns: 8, gutters: 7 })};
  }

  ${media.xl(css`
    max-width: initial;
  `)};
`

const RelatedItems = styled.div`
  background: linear-gradient(137deg, #f4f4f4 6%, #fbfbfb 96%);
  flex: 1 1 auto;
  margin-top: ${({ theme }) => theme.spacing[5]};
  padding: ${({ theme }) => theme.spacing[2]};
  padding-bottom: ${({ theme }) => theme.spacing[4]};

  @media (min-width: ${BREAK}px) {
    max-width: ${mixins.span({ columns: 3, gutters: 2 })};
    margin-top: 0;
  }
`

const Container = styled.div<ContainerProps>`
  ${props =>
    props.hasRelatedItems &&
    css`
      @media (min-width: ${BREAK}px) {
        align-items: flex-start;
        display: flex;
      }
    `};
`

const MarkdownSection = ({ section }) => (
  <Section top={{ xs: 3, md: 3 }} bottom={{ xs: 3, md: 3 }}>
    {section && (
      <Container hasRelatedItems={!!section.fields.relatedLinks}>
        <Content>
          <Markdown>{section.fields.text}</Markdown>
        </Content>
        {section.fields.relatedLinks && (
          <RelatedItems>
            <Type
              size={{ xs: 14, md: 14 }}
              weight="bold"
              bottom={{ xs: 2, md: 1.5 }}
              case="uppercase"
            >
              Related items
            </Type>
            {section.fields.relatedLinks.map(item => (
              <RelatedLink key={item.fields.label} to={item.fields.url}>
                {item.fields.name}
              </RelatedLink>
            ))}
          </RelatedItems>
        )}
      </Container>
    )}
  </Section>
)

export default MarkdownSection
