import React from 'react'

import { Section } from 'bl-common/src/units/Section/Section'

import { getSectionProps } from '../../../utils/getSectionProps'
import { VisitorInfoPanel } from '../../VisitorInfoPanel'

const RoomOverviewSection = props => {
  const { config, ...rest } = getSectionProps(props)

  return (
    <Section
      noHorizontalPadding
      bottom={{ xs: 6, md: 10 }}
      top={{ xs: 6, md: 10 }}
      config={config}
      hideOverflowX
    >
      <VisitorInfoPanel {...rest} />
    </Section>
  )
}

export default RoomOverviewSection
