import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Section } from 'bl-common/src/units/Section/Section'

import { getSectionProps } from '../../../utils/getSectionProps'
import { RoomOverview } from '../../RoomOverview'

const RoomOverviewSection = props => {
  const {
    roomTitle,
    roomQuickView,
    roomDescription,
    roomInclusions,
    roomInclusionsFinePrint,
    config,
  } = getSectionProps(props)

  return (
    <Section bottom={{ xs: 2, md: 2 }} top={{ xs: 4, md: 6 }} config={config}>
      <Type as="h2" preset="headlineMedium" bottom={{ xs: 1, md: 2.5 }}>
        {roomTitle}
      </Type>
      <RoomOverview
        quickView={roomQuickView}
        description={roomDescription}
        inclusions={roomInclusions}
        finePrint={roomInclusionsFinePrint}
      />
    </Section>
  )
}
export default RoomOverviewSection
