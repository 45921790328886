import React from 'react'
import get from 'lodash/get'
import styled, { css } from 'styled-components'
import { mt } from 'styled-components-spacing'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { modularScale } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { between } from 'bl-common/src/utils/between'
import { media, mediaMax } from 'bl-common/src/utils/media'

type ImageProps = {
  imageUrl?: string
}

type OfferButtonContainerProps = {
  hasMargin?: boolean
}

type CardProps = {
  styleChild?: any
}

const Container = styled.div`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: ${zIndex.above};

  ${media.md(css`
    flex-direction: row;
  `)};
`

const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  flex: 0 1 auto;
  position: relative;
  background: ${colors.white};
  color: ${colors.dark};
  width: 100%;
  min-height: 300px;
  padding: 32px;

  ${media.md(css`
    padding: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[4]}`};
    margin-top: ${between(-128 / modularScale, -128)};
    margin-bottom: ${between(128 / modularScale, 128)};
    margin-right: ${between(64 / modularScale, 64)};
    margin-left: ${between(-128 / modularScale, -128)};
    width: ${between(536 / modularScale, 536)};
  `)};
`

const Image = styled.div<ImageProps>`
  background-image: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding-bottom: 60%;

  ${media.md(css`
    flex: none;
    padding-bottom: 43.61%;
    width: 63.65%;

    &::after {
      background: linear-gradient(156.16deg, #6c8897 7.94%, #a3a9aa 91.6%);
      bottom: ${between(64 / modularScale, 64)};
      content: '';
      display: block;
      left: 104px;
      margin: auto;
      position: absolute;
      right: 0;
      top: -64px;
      z-index: -1;
    }
  `)};
`

const OfferButton = styled(Button)`
  ${mediaMax.md(css`
    width: 100%;
  `)};
`

const OfferButtonContainer = styled.div<OfferButtonContainerProps>`
  ${({ hasMargin, theme }) =>
    hasMargin ? mt({ xs: 1, md: 2 }, theme) : 'margin-top: auto'};

  width: 100%;
  a:not(:last-child) {
    margin-bottom: ${between(12, 24, {
      fromWidth: breakpoints.sm,
      toWidth: breakpoints.md,
    })};
  }

  ${media.md(css`
    a {
      display: inline-block;
      width: auto;
    }

    a:not(:last-child) {
      margin-right: ${between(12, 24, { fromWidth: breakpoints.md })};
      margin-bottom: auto;
    }
  `)}
`

const List = styled.ul`
  margin: 0;
  margin-left: 1rem;
`

const ListItem = styled.li`
  padding-left: 0.5rem;
  position: relative;
  &::before {
    color: ${colors.fountainBlue};
    display: block;
    position: absolute;
    left: -1rem;
    content: '•';
  }
  & + & {
    padding-top: ${({ theme }) => theme.spacing[1]};
  }
`

const formatText = string =>
  string &&
  String(string)
    .replace(/\*([^*]+)\*/g, '<b>$1</b>')
    .replace(/_([^_]+)_/g, '<i>$1</i>')

const formatList = string => {
  const separatedByDash = string && String(string).match(/^-\s+(.+)/gm)
  return separatedByDash
    ? separatedByDash.map(sentence => sentence.replace(/(-+\s+)|(^-)/, ''))
    : []
}

export const OfferPanel = ({
  fields: {
    image,
    heading,
    subtitle,
    textContent,
    buttonUrl,
    buttonText,
    secondaryButtonText,
    secondaryButtonUrl,
    listContent,
    backgroundPosition,
  },
}) => (
  <Appear styleChild key={heading}>
    <Container>
      <Image
        imageUrl={get(image, 'fields.file.url') + '?q=90'}
        style={{ backgroundPosition: backgroundPosition || 'center' }}
      />
      <Card styleChild>
        <Type preset="headline" as="h2" bottom={{ xs: subtitle ? 0 : 2 }}>
          {heading}
        </Type>
        {subtitle && (
          <Type preset="subtitle" bottom={{ xs: 2 }}>
            {subtitle}
          </Type>
        )}
        {listContent && (
          <List>
            {formatList(listContent).map(item => (
              <ListItem key={item}>
                <Type preset="textLarge">{item}</Type>
              </ListItem>
            ))}
          </List>
        )}
        {textContent && (
          <Type
            preset="textLarge"
            multiline
            top={listContent && { xs: 2, md: 2 }}
            bottom={{ xs: 2, md: 3 }}
            dangerouslySetInnerHTML={{ __html: formatText(textContent) }}
          />
        )}
        <OfferButtonContainer hasMargin={!textContent}>
          <OfferButton to={buttonUrl}>{buttonText || 'Book offer'}</OfferButton>
          {secondaryButtonUrl && secondaryButtonText && (
            <OfferButton to={secondaryButtonUrl}>
              {secondaryButtonText}
            </OfferButton>
          )}
        </OfferButtonContainer>
      </Card>
    </Container>
  </Appear>
)
