import React, { useContext, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { BLOCKS } from '@contentful/rich-text-types'

import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ISectionBookingBarHeroFields } from 'bl-common/src/generated/contentful'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'

import { formatGradient } from 'utils/formatters'

import { BannerHeightContext } from '../../../BannerHeight'
import * as styles from './styles'

// TODO - Remove dynamic importing
const SpaBookingBar = dynamic(() => import('../../../SpaBookingBar'), {
  ssr: false,
})

const HotelBookingBar = dynamic(() => import('../../../HotelBookingBar'), {
  ssr: false,
})

type BookingBarSectionProps = {
  section: {
    fields: ISectionBookingBarHeroFields
  }
}

const documentOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type
        as="h1"
        preset="headlineExtraLarge"
        bottom={{ xs: 0.5, mlg: 2 }}
        weight="bold"
      >
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type preset="subtitle">{children}</Type>
    ),
  },
}

export const BookingBarSection = ({
  section: {
    fields: {
      textContent,
      image,
      bookingBar,
      imageOverlay = 'light',
      video,
      config,
      backgroundPosition,
    },
  },
}: BookingBarSectionProps) => {
  const [videoError, setVideoError] = useState(false)
  const [offsetBottom, setOffsetBottom] = useState(0)
  const { bannerHeight } = useContext(BannerHeightContext)
  const formattedGradient = formatGradient(bookingBar?.fields.gradient)

  useEffect(() => {
    if (bannerHeight !== offsetBottom) {
      setOffsetBottom(bannerHeight ?? 0)
    }
  }, [bannerHeight])

  const availabilityMaxDate = bookingBar?.fields?.settings?.fields
    ?.availabilityMaxDate
    ? new Date(bookingBar.fields.settings.fields.availabilityMaxDate)
    : undefined

  return (
    <styles.bookingBarHero bottom={config?.fields.bottom} offset={offsetBottom}>
      <styles.left>
        <styles.text>
          <RichTextRenderer
            document={textContent}
            customOptions={documentOptions}
          />
        </styles.text>
        {bookingBar ? (
          <styles.bookingBarWrap>
            <styles.bookingBar
              gradient={formattedGradient}
              background={bookingBar.fields.backgroundColor}
            >
              {bookingBar.fields.type === 'Hotel' ? (
                <HotelBookingBar
                  ctaLabel={bookingBar.fields.ctaLabel}
                  property={bookingBar.fields.property}
                  switchBetweenPanels
                  mobileBarAsButton
                  maxDate={availabilityMaxDate}
                />
              ) : (
                <SpaBookingBar
                  ctaLabel={bookingBar.fields.ctaLabel}
                  property={bookingBar.fields.property}
                  mobileBarAsButton
                />
              )}
            </styles.bookingBar>
          </styles.bookingBarWrap>
        ) : null}
      </styles.left>
      <styles.right aria-hidden imageOverlay={imageOverlay}>
        {video && !videoError ? (
          <video
            src={video.fields.file.url}
            autoPlay
            muted
            loop
            playsInline
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: backgroundPosition || 'center',
            }}
            onError={() => setVideoError(true)}
          />
        ) : (
          <ContentfulImage
            image={image}
            style={{ objectPosition: backgroundPosition }}
          />
        )}
      </styles.right>
    </styles.bookingBarHero>
  )
}

export default BookingBarSection
