import * as React from 'react'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { zIndex } from '../../constants/zIndex'
import { Type } from '../../elements/Typography/Typography'
import { Container } from '../../units/Container'
import { media } from '../../utils/media'
import { FormatGradientType } from './IncludedList'

export const StyledContainer = styled(Container)<{
  gradient?: FormatGradientType
}>`
  position: relative;
  padding-top: 57px;
  padding-bottom: 41px;
  ${media.md(css`
    display: grid;
    grid-template-columns: 40%;
    margin-top: 0;
    padding-top: ${({ theme }) => theme.spacing[6]};
    padding-bottom: ${({ theme }) => theme.spacing[5]};
  `)}
`

export const Title = styled(props => (
  <Type as="h2" preset="headlineLarge" bottom={{ xs: 1, md: 2 }} {...props} />
))`
  ${media.md(css`
    font-size: 40px;
  `)}
`

export const Description = ({ children }: { children: ReactNode }) => (
  <Type multiline preset="textLarge" bottom={{ xs: 2, md: 0 }}>
    {children}
  </Type>
)

export const Card = styled.div`
  z-index: ${zIndex.above};

  padding-left: ${({ theme }) => theme.spacing[2]};
  padding-top: ${({ theme }) => theme.spacing[3]};
  padding-right: ${({ theme }) => theme.spacing[3]};
  padding-bottom: ${({ theme }) => theme.spacing[3]};

  background: ${colors.white};
  color: ${colors.midGrey};

  ${media.md(css`
    position: absolute;
    width: 40%;
    align-self: center;
    right: calc(4.172px + 100vw * 0.052734);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

    padding-left: ${({ theme }) => theme.spacing[2.5]};
    padding-right: ${({ theme }) => theme.spacing[2.5]};
  `)}

  ${media.lg(css`
    width: auto;
    min-width: 500px;
    max-width: 50%;
  `)}
`

export const ListTitle = ({ children }) => (
  <Type as="h3" preset="headlineMedium" bottom={{ xs: 1 }}>
    {children}
  </Type>
)

export const UnorderedList = styled.ul`
  margin-left: 0;

  font-size: 16px;

  ${media.md(css`
    font-size: 18px;
  `)}
`

const ListItemContainer = styled.li`
  display: flex;
  align-items: baseline;
  position: relative;
  flex-wrap: wrap;

  p {
    flex: 1;
    margin-left: ${({ theme }) => theme.spacing[1]};
  }
`

const Bullet = styled.span`
  width: 6px;
  height: 6px;
  display: flex;
  align-items: center;
  position: relative;
  top: -1px;

  ${media.md(css`
    width: ${({ theme }) => theme.spacing[0.5]};
    height: ${({ theme }) => theme.spacing[0.5]};
  `)};
`

export const ListItem = ({ children, ...props }) => (
  <ListItemContainer {...props}>
    <Bullet>
      <svg
        viewBox="0 0 10 10"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: '100%',
          fill: 'var(--global-color-listMarker)',
          overflow: 'visible',
        }}
      >
        <circle cx="5" cy="5" r="5" />
      </svg>
    </Bullet>
    {children}
  </ListItemContainer>
)
