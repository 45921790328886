import React from 'react'
import marked from 'marked'
import styled, { css } from 'styled-components'

import { colors } from '../constants/colors'
import { modularScale } from '../constants/sizes'
import { Type } from '../elements/Typography/Typography'
import { between } from '../utils/between'
import { media } from '../utils/media'

const Copy = styled(Type)`
  padding-bottom: 40px;

  ${media.md(css`
    padding-bottom: ${between(64 / modularScale, 64)};
  `)};

  p {
    font-size: 16px;

    ${media.md(css`
      font-size: ${between(24 / modularScale, 24)};
    `)};
  }

  li {
    font-size: 16px;

    ${media.md(css`
      font-size: ${between(18 / modularScale, 18)};
    `)};
  }

  h2,
  h3 {
    font-weight: 600;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 25px;
  }

  p + p,
  ul + p,
  p + ul {
    padding-top: 36px;
    ${media.md(css`
      padding-top: ${between(64 / modularScale, 64)};
    `)};
  }

  p > strong:first-child {
    display: block;
  }

  a {
    color: ${colors.deepBlue};
    font-weight: 500;
  }

  em {
    font-style: italic;
  }

  ul {
    padding-top: 10px;
    ${media.md(css`
      padding-top: ${between(10 / modularScale, 10)};
    `)};
  }

  ${({ theme }) => css`
    * + h1,
    * + h2,
    * + h3 {
      padding-top: ${theme.spacing[2]};
    }

    h1 + *,
    h2 + * {
      padding-top: ${theme.spacing[2]};
    }

    blockquote {
      padding-left: ${theme.spacing[2]};
    }
  `};
`

// Render links with target="_blank"
const renderer = new marked.Renderer()

renderer.link = function (href, title, text) {
  const link = marked.Renderer.prototype.link.call(this, href, title, text)
  const isInternal = () => {
    // Which is done so that the href is independent of the protocol (e.g. `https:`, `http:`).
    if (href.substr(0, 2) === '//') return false
    // If it's a relative url such as '/path', 'path' and does not contain a protocol we can assume it is internal.
    if (href.indexOf('://') === -1) return true

    return false
  }

  return isInternal() ? link : link.replace('<a', "<a target='_blank' ")
}

marked.setOptions({
  renderer,
})

export const TopicMarkdown = ({ children, ...props }) =>
  children ? (
    <Copy
      {...props}
      as="div"
      dangerouslySetInnerHTML={{
        __html: marked(children, {
          gfm: true,
          breaks: true,
        }),
      }}
    />
  ) : null
