import React from 'react'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { BackgroundImage } from 'bl-common/src/elements/BackgroundImage'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { LightBoxItem } from 'bl-common/src/units/LightBox/LightBoxItem'
import { LightBoxProvider } from 'bl-common/src/units/LightBox/LightBoxProvider'
import { Section } from 'bl-common/src/units/Section/Section'
import { media } from 'bl-common/src/utils/media'

import { getSectionProps } from '../../../utils/getSectionProps'

const OverviewImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`
const List = styled.ol`
  column-count: 2;
  padding: ${({ theme }) => `${theme.spacing[2]} 0`};

  ${media.md(css`
    column-count: 3;
  `)};
`

const ListItem = styled.li`
  margin-bottom: ${({ theme }) => `${theme.spacing[1]}`};
`

export const OverviewSection = props => {
  const { heading, multilineSubheading, image, list, config } =
    getSectionProps(props)

  const listItems = get(list, 'fields.listItems', [])
  return (
    <Section bottom={{ xs: 2, md: 4 }} top={{ xs: 2, md: 4 }} config={config}>
      <Type
        as="h1"
        preset="headlineLarge"
        bottom={{
          xs: multilineSubheading ? 1 : 2,
          md: multilineSubheading ? 2 : 3,
        }}
      >
        {heading}
      </Type>
      {multilineSubheading && (
        <Type
          as="h2"
          preset="subtitle"
          bottom={{ xs: 4, md: 5 }}
          maxWidth={1000}
          multiline
        >
          {multilineSubheading}
        </Type>
      )}
      <LightBoxProvider>
        <LightBoxItem image={image}>
          <OverviewImage image={image} useAspectRatio />
        </LightBoxItem>
      </LightBoxProvider>
      <List>
        {listItems.map((item, index) => (
          <ListItem key={item.fields.item}>
            <Type preset="subtitle">{`${index + 1} ${item.fields.item}`}</Type>
          </ListItem>
        ))}
      </List>
    </Section>
  )
}

export default OverviewSection
