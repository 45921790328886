import React from 'react'

import { CtaHero } from 'bl-common/src/units/CtaHero'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'

export const CtaHeroSection = ({
  section: {
    fields: {
      textContent,
      images,
      videoIds,
      blowUpImage,
      textContentSide,
      offsetDirection,
      config,
    },
  },
}) => {
  return (
    <OffsetSection
      offsetDirection={offsetDirection}
      config={config}
      spacingFromEdge={{ xs: 1, md: 1 }}
    >
      <CtaHero
        textContent={textContent}
        images={images}
        videoIds={videoIds}
        blowUpImage={blowUpImage}
        textContentSide={textContentSide}
      ></CtaHero>
    </OffsetSection>
  )
}

export default CtaHeroSection
