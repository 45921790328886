import React from 'react'
import { Translation } from 'react-i18next'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { media } from 'bl-common/src/utils/media'

const QuickView = styled.ul`
  border-top: 1px solid ${rgba(colors.midGrey, 0.1)};
  border-bottom: 1px solid ${rgba(colors.midGrey, 0.1)};
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing[1]};
`

const Point = styled(Type)`
  position: relative;
  padding-right: 1rem;
  flex: 0 1 100%;
  left: 1rem;

  ${media.md(css`
    flex: 0 1 33.333%;
    padding-top: 0.33rem;
    padding-bottom: 0.33rem;
  `)};

  &::before {
    display: block;
    position: absolute;
    left: -1rem;
    content: '•';
    color: ${colors.fountainBlue};
  }
`

const Inclusions = styled.div`
  background: ${colors.lightGrey};
  padding: 32px 24px;
  overflow: hidden;

  ${media.md(css`
    padding: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[6.5]}`};
  `)};
`

const InclusionList = styled.ul`
  ${media.md(css`
    display: flex;
    flex-wrap: wrap;

    ${Point} {
      flex: 0 1 50%;
    }
  `)};
`

const QuickViewWrap = styled.div`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing[5]};
`

const AtAGlance = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing[6]};

  ${media.md(css`
    flex-wrap: nowrap;
  `)};
`

export const RoomOverview = ({
  description,
  quickView = [],
  inclusions = [],
  finePrint,
}) => {
  return (
    <>
      <AtAGlance>
        <QuickViewWrap>
          <QuickView>
            {quickView.length &&
              quickView.map(item => (
                <Point
                  as="li"
                  key={item}
                  size={{
                    xs: 16,
                    md: 16,
                  }}
                >
                  {item}
                </Point>
              ))}
          </QuickView>
          <Type
            preset="text"
            lineHeight="2"
            top={{ xs: 2, md: 2.5 }}
            bottom={{ xs: 2, md: 2.5 }}
            multiline
            maxWidth={1000}
          >
            {description}
          </Type>
        </QuickViewWrap>
      </AtAGlance>

      <Inclusions>
        <Type preset="label" case="uppercase" bottom={{ xs: 1, md: 2 }}>
          <Translation>{t => t('inclusions')}</Translation>
        </Type>
        <InclusionList>
          {inclusions.length &&
            inclusions.map(inclusion => (
              <Point
                as="li"
                key={inclusion}
                lineHeight={2.2}
                size={{
                  xs: 14,
                  md: 16,
                }}
              >
                {inclusion}
              </Point>
            ))}
        </InclusionList>
        <Type preset="text" top={{ xs: 1, md: 2 }} style={{ opacity: 0.5 }}>
          {finePrint}
        </Type>
      </Inclusions>
    </>
  )
}
