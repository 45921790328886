import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'
import { media } from 'bl-common/src/utils/media'

const Container = styled.div`
  position: relative;
  max-height: 75vh;
  overflow: hidden;
`

const PlayArea = styled.button`
  position: absolute;
  z-index: ${zIndex.above};
  display: flex;
  align-items: center;
  bottom: 32px;
  right: 32px;

  ${media.md(css`
    cursor: pointer;
    bottom: ${({ theme }) => theme.spacing[4]};
    right: ${({ theme }) => theme.spacing[5]};
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  `)}
`

const CtaText = styled(({ ...rest }) => (
  <Type preset="textSmall" case="uppercase" {...rest} />
))`
  color: ${colors.white};
  transition: none;
  margin-left: 8px;

  ${media.md(css`
    margin-left: ${({ theme }) => theme.spacing[0.5]};
  `)}
`

const FallbackImage = styled.div`
  background-position: center;
  background-size: cover;

  &::before {
    content: '';
    display: block;
    padding-bottom: 64.95%;
  }
`

const Cinema = dynamic(() => import('bl-common/src/units/Cinema/Cinema'), {
  ssr: false,
})

export const VideoPlayerHero = ({
  videoLoop,
  ctaText,
  fallbackImage,
  videoId,
}) => {
  const [showCinema, setShowCinema] = useState(false)
  return (
    <>
      <Container>
        <PlayArea onClick={() => setShowCinema(true)}>
          <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#fff" />
            <path
              d="M16.74 11.26L9.68 8.077a.513.513 0 0 0-.457.019.413.413 0 0 0-.223.36v6.366c0 .148.083.283.223.36a.509.509 0 0 0 .457.018l7.06-3.183a.42.42 0 0 0 .259-.378.42.42 0 0 0-.26-.379z"
              fill="#50555B"
            />
          </svg>
          <CtaText>{ctaText}</CtaText>
        </PlayArea>
        {videoLoop ? (
          <video
            autoPlay
            playsInline
            muted
            loop
            src={get(videoLoop, 'fields.file.url')}
            style={{ width: '100%', objectFit: 'cover' }}
          />
        ) : (
          <SmartImage image={fallbackImage}>
            {imageUrl => (
              <FallbackImage style={{ backgroundImage: `url(${imageUrl})` }} />
            )}
          </SmartImage>
        )}
      </Container>
      <Cinema
        show={showCinema}
        onHide={() => setShowCinema(false)}
        videoId={videoId}
      />
    </>
  )
}
