import React from 'react'

import { Section } from 'bl-common/src/units/Section/Section'

import { TopicImagePillars } from '../../TopicImagePillars'

const PillarsSection = ({
  section: {
    fields: {
      title,
      text,
      leftSideImage,
      rightSideImage,
      leftSideImagePosition,
      rightSideImagePosition,
      gradient,
    },
  },
}) => (
  <Section
    minheight={{ xs: '456px', md: 'auto' }}
    top={{ xs: 2, md: 4, lg: 10 }}
    bottom={{ xs: 4, md: 4, lg: 8 }}
  >
    <TopicImagePillars
      title={title}
      text={text}
      leftSideImage={leftSideImage}
      rightSideImage={rightSideImage}
      leftSideImagePosition={leftSideImagePosition}
      rightSideImagePosition={rightSideImagePosition}
      gradient={gradient}
    />
  </Section>
)

export default PillarsSection
