import React from 'react'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import styled, { css } from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { ImageCarousel } from 'bl-common/src/units/ImageCarousel/ImageCarousel'
import { Section } from 'bl-common/src/units/Section/Section'
import { media } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { BreadcrumbLink } from '../../BreadcrumbLink'

export const Content = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing[2.5]};
  ${media.md(css`
    display: flex;
  `)}
`

export const Container = styled.div`
  position: relative;
  z-index: 2;
`

export const Item = styled.div`
  flex-basis: 50%;
`
export const CTALink = styled(Link)`
  color: ${colors.deepBlue};

  .t-dark & {
    color: ${colors.blueOnDark};
  }
`

export const LargeImageAlbum = ({
  section: {
    fields: {
      heading,
      multilineSubheading,
      ctaLink,
      carousel,
      showBreadcrumbLink = false,
      config,
    },
  },
}) => {
  // TODO: Switch to useBreakpoints
  const isSmall = useObserver(
    observe(viewportWidth(), width => width < breakpoints.mlg)
  )

  return (
    <Section
      config={config}
      top={{ xs: 2, md: 6 }}
      bottom={{ xs: 4, md: 6 }}
      hideOverflowX
    >
      <Appear observer>
        {showBreadcrumbLink && (
          <BreadcrumbLink color={colors.deepBlue} top={2} arrowScale={0.7} />
        )}
        <Content>
          <Item>
            <Type
              size={{ xs: 32, md: 65 }}
              weight="bold"
              bottom={{ xs: 1.5, md: 0 }}
              top={showBreadcrumbLink ? { xs: 1.5, md: 0 } : null}
            >
              {heading}
            </Type>
          </Item>
          {multilineSubheading && (
            <Item>
              <Type
                preset="subtitle"
                multiline
                maxWidth={600}
                dangerouslySetInnerHTML={{
                  __html: formatHtmlText(multilineSubheading),
                }}
              />
              {ctaLink && (
                <CTALink to={ctaLink.fields.url}>
                  <Type preset="labelLarge" multiline top={{ xs: 1 }}>
                    {ctaLink.fields.label}
                  </Type>
                </CTALink>
              )}
            </Item>
          )}
        </Content>
        {carousel && carousel.length > 0 && (
          <Container>
            <ImageCarousel
              id="large-image-carousel"
              images={carousel}
              width={
                isSmall ? '80vw' : mixins.span({ columns: 10, gutters: 9 })[0]
              }
              renderControls={false}
            />
          </Container>
        )}
      </Appear>
    </Section>
  )
}

export default LargeImageAlbum
