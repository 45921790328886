import React from 'react'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Amount } from 'bl-common/src/elements/Amount'
import { LinkArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { getImageUrl } from 'bl-common/src/units/SmartImage/getImageUrl'
import { media } from 'bl-common/src/utils/media'

type CardContentProps = {
  spaced?: boolean
}

type CtaWrapperProps = {
  spaced?: boolean
}

type ArrowProps = {
  spaced?: boolean
}

type ImageProps = {
  imageRatio?: number
  image?: string
}

const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  flex-basis: calc(50% - 12px);
  margin-bottom: 24px;

  .t-dark & path {
    fill: ${colors.blueOnDark};
  }

  ${media.mlg(css`
    flex-basis: calc(50% - 24px);
    margin-bottom: 48px;
  `)}

  &:nth-child(odd) {
    margin-right: 12px;
    ${media.mlg(css`
      margin-right: 24px;
      flex-basis: calc(50% - 24px);
      margin-bottom: 48px;
      top: -64px;
    `)}

    &:last-child {
      margin-right: auto;
    }
  }

  &:nth-child(even) {
    margin-left: 12px;
    top: 64px;
    ${media.mlg(css`
      margin-left: 24px;
      flex-basis: calc(50% - 24px);
      margin-bottom: 48px;
      top: 0;
    `)}
  }
`

const CardContainer = styled(props =>
  props.to ? <Link {...props} /> : <div {...props} />
)`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
  max-width: 480px;
  z-index: 10;
  background: white;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.08);
  transition: transform ${durations.long}ms ease;

  .t-dark &,
  .gradient-dark & {
    background: ${colors.darkmodeOffset};
  }

  ${({ to }) =>
    to &&
    css`
      &:hover,
      &:focus {
        transform: translate3d(0, -8px, 0);

        ${Image}::before {
          transform: scale(1.1) translateZ(0);
        }
        ${Arrow} {
          transform: translate3d(-20%, 0, 0);
        }
      }
    `}
`

const CardContent = styled.div<CardContentProps>`
  flex: 1;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: ${({ theme, spaced }) =>
    `${theme.spacing[spaced ? 2 : 1.5]} ${theme.spacing[1]} ${
      theme.spacing[1]
    }`};

  ${media.md(css`
    padding: ${({ theme, spaced }: { theme: any; spaced?: boolean }) =>
      `${theme.spacing[spaced ? 3 : 1.5]} ${theme.spacing[1.5]} ${
        theme.spacing[1.5]
      }`};
  `)}
`

const Image = styled.div<ImageProps>`
  position: relative;
  width: 100%;
  z-index: ${zIndex.above};
  overflow: hidden;
  padding-bottom: ${({ imageRatio }) => imageRatio * 100}%;

  &::before {
    transition: transform ${durations.long}ms ease;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    ${({ image }) => css`
      background-image: url(${image});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    `};
  }
`

const Heading = styled(Type)`
  display: flex;
  justify-content: space-between;
`

const CtaWrapper = styled.div<CtaWrapperProps>`
  color: ${colors.deepBlue};
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: auto;
  padding-top: ${({ theme, spaced }) => theme.spacing[spaced ? 2 : 1]};
  width: 100%;

  .t-dark & {
    color: ${colors.blueOnDark};
  }
`

const ArrowWrap = styled.div`
  width: 12px;
  padding-top: 3px;
`

const Arrow = styled.div<ArrowProps>`
  color: ${colors.deepBlue};
  padding-top: ${({ theme, spaced }) => theme.spacing[spaced ? 2 : 1]};
  margin-top: auto;
  margin-left: auto;
  transition: transform ${durations.long}ms ease;
  width: 10px;

  ${media.md(css`
    width: 12px;
  `)}

  .t-dark & {
    color: ${colors.blueOnDark};
  }
`

export const AmountWrapper = styled(Type)`
  opacity: 0.75;
`

export const CardCollectionCard = ({
  title,
  price,
  description,
  link,
  linkLabel,
  image,
  imageRatio = 1.5,
}) => {
  const showPriceFallback =
    price && price.fields && !price.fields.isk && price.fields.title

  return (
    <CardWrapper>
      <CardContainer to={link}>
        {image && (
          <Image
            image={getImageUrl(image, { width: 1280, ssr: true })}
            imageRatio={imageRatio}
          />
        )}
        <CardContent spaced={!image}>
          <Heading
            as="h2"
            size={{ xs: 16, md: 20, mlg: 18 }}
            weight="bold"
            bottom={{ xs: 0.5 }}
          >
            {title}
          </Heading>
          {price && (
            <AmountWrapper
              size={{ xs: 12, md: 16, mlg: 14 }}
              bottom={{ xs: 0.5 }}
            >
              <Amount
                value={showPriceFallback ? null : price}
                fallback={showPriceFallback}
              />
            </AmountWrapper>
          )}
          {description && (
            <Type size={{ xs: 12, md: 18, mlg: 16 }}>{description}</Type>
          )}
          {link ? (
            linkLabel ? (
              <CtaWrapper>
                <Type weight="bold" right={{ xs: 0.5 }} preset="text">
                  {linkLabel}
                </Type>
                <ArrowWrap>
                  <LinkArrow width="100%" thick />
                </ArrowWrap>
              </CtaWrapper>
            ) : (
              <Arrow spaced={!image}>
                <LinkArrow width="100%" height="100%" thick />
              </Arrow>
            )
          ) : null}
        </CardContent>
      </CardContainer>
    </CardWrapper>
  )
}
