import React from 'react'

import { Section } from 'bl-common/src/units/Section/Section'

import { formatButton } from '../../../utils/formatters/elements'
import { getSectionProps } from '../../../utils/getSectionProps'
import { StoryBlock } from '../../StoryBlock'

const StoryBlockSection = props => {
  const { title, description, button, stories, config } = getSectionProps(props)
  const formattedButton = formatButton(button)
  return (
    <Section
      bottom={{ xs: 2, md: 2 }}
      top={{ xs: 4, md: 6 }}
      config={config}
      hideOverflowX
    >
      <StoryBlock
        title={title}
        description={description}
        button={formattedButton}
        stories={stories}
      />
    </Section>
  )
}
export default StoryBlockSection
