import React from 'react'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { CoverCard } from 'bl-common/src/cards/CoverCard/CoverCard'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { CardCarousel } from 'bl-common/src/units/CardCarousel/CardCarousel'
import { Section } from 'bl-common/src/units/Section/Section'
import { media } from 'bl-common/src/utils/media'

import { formatCoverCard } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'

const Grid = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: ${({ theme }) => theme.spacing[2]};
  grid-template-columns: 1fr;

  ${media.sm(css`
    grid-template-columns: 1fr 1fr;
  `)}

  ${media.md(css`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `)}
`

const CoverCardsSection = props => {
  const {
    heading,
    multilineSubheading,
    coverCards,
    useGrid = false,
    config,
  } = getSectionProps(props)
  const cards = coverCards.map(card => formatCoverCard(card))
  return (
    <div>
      <Section
        bottom={{ xs: 2, md: 4 }}
        top={{ xs: 4, md: 6 }}
        config={config}
        hideOverflowX
      >
        <Type
          as="h2"
          preset="headlineMedium"
          bottom={{
            xs: multilineSubheading ? 1 : 2,
            md: 1,
          }}
        >
          {heading}
        </Type>
        {multilineSubheading && (
          <Type preset="subtitle" bottom={{ xs: 1 }} multiline>
            {multilineSubheading}
          </Type>
        )}
        {useGrid ? (
          <Grid>
            {coverCards.map(card => (
              <Appear delay={100} key={get(card, 'fields.title')}>
                <CoverCard
                  title={get(card, 'fields.title')}
                  minHeight={390}
                  media={get(card, 'fields.media')}
                  fallbackMedia={get(card, 'fields.fallbackMedia')}
                  gradientOverlay={get(card, 'fields.gradientOverlay')}
                  grayscale={get(card, 'fields.grayscale')}
                  link={get(card, 'fields.link')}
                />
              </Appear>
            ))}
          </Grid>
        ) : (
          <CardCarousel
            cards={cards}
            minItemWidthFrom={220}
            minItemWidthTo={273}
            id={'coverCardsCarousel'}
          />
        )}
      </Section>
    </div>
  )
}

export default CoverCardsSection
