import React, { useEffect, useRef, useState } from 'react'
import get from 'lodash.get'
import styled, { css } from 'styled-components'
import root from 'window-or-global'

import { zIndex } from 'bl-common/src/constants/zIndex'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { media } from 'bl-common/src/utils/media'

import { MediaProgress, useInterval } from '../../../Highlights'
import { HighlightsThumbnail } from './HighlightsThumbnail'

const StyledVideo = styled.video<{ $hide?: boolean; $minimize?: boolean }>`
  bottom: 0;
  display: block;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.above};

  ${({ $hide }) =>
    $hide &&
    css`
      display: none;
    `}

  ${({ $minimize }) =>
    $minimize &&
    css`
      top: 0;
      left: 0;
      opacity: 0.1;
      right: 1px;
      bottom: 1px;
    `}
`

const StyledImg = styled.img`
  bottom: 0;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${zIndex.above};
`

const MediaOuter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  ${media.md(css`
    max-height: 960px;
    max-width: 540px;
  `)}
`

const MediaWrap = styled.div<{ isLoaded?: boolean }>`
  background: ${({ isLoaded }) => (isLoaded ? 'transparent' : 'black')};
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  left: 0;
  margin: auto;
  padding: 32px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  width: 100%;

  &::after {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
    bottom: 0;
    content: '';
    height: 134px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }
`

const ContentWrap = styled.div`
  z-index: ${zIndex.above2};
  position: relative;
  padding: 16px 8px;
  margin-top: auto;

  ${media.md(css`
    padding: 16px;
  `)}
`

const TextContent = styled.div`
  margin-bottom: 18px;
`

const ClickableOverlay = styled.button`
  bottom: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: ${zIndex.above2};
`

const ClickableOverlayRight = styled(ClickableOverlay)`
  right: 0;
`

const ClickableOverlayLeft = styled(ClickableOverlay)`
  left: 0;
`

const IMAGE_DURATION = 4000
const IMAGE_TICK = 200

const Video = ({ media, onEnded, onCanPlay, setProgress, index }) => {
  const ref = useRef<HTMLVideoElement>()

  const shouldPlay = media.index === index
  const shouldPreload = media.index === index + 1

  // video is ready when we know its duration
  const [isReady, setReady] = useState(false)

  // next video should load
  useEffect(() => {
    if (shouldPreload) {
      ref.current.load()
    }
  }, [index])

  // current video should play
  useEffect(() => {
    if (!isReady) {
      return
    }

    if (shouldPlay) {
      // onCanPlay also sets media duration
      onCanPlay(ref.current.duration * 1000)
      // Restart video in case it had been started before
      ref.current.currentTime = 0
      // Then play it
      ref.current.play()
    }
  }, [index, isReady])

  const handleEnded = () => {
    ref.current.pause()
    ref.current.currentTime = 0
    if (shouldPlay) {
      onEnded()
    }
  }

  const handleProgressUpdate = () => {
    if (shouldPlay) {
      setProgress(ref.current.currentTime * 1000)
    }
  }

  return (
    <StyledVideo
      ref={ref}
      playsInline
      autoPlay
      preload="auto"
      onLoadedMetadata={() => setReady(true)}
      poster={media.poster}
      muted
      onTimeUpdate={handleProgressUpdate}
      onEnded={handleEnded}
      style={
        !shouldPlay && !shouldPreload
          ? { visibility: 'hidden' }
          : shouldPreload
          ? { zIndex: -10 }
          : {}
      }
    >
      <source
        src={media.index <= index + 1 ? media.src : ''}
        type="video/mp4"
      />
    </StyledVideo>
  )
}

const Image = ({ media, onEnded, onCanPlay, index, setProgress }) => {
  const shouldPlay = media.index === index
  const shouldPreload = media.index === index + 1
  const [progress, updateProgress] = useState(0)
  const [isReady, setReady] = useState(false)

  // fake progress
  useInterval(
    () => updateProgress(progress + IMAGE_TICK),
    shouldPlay ? IMAGE_TICK : null
  )

  useEffect(() => {
    if (!isReady) {
      return
    }

    if (shouldPlay) {
      onCanPlay(IMAGE_DURATION)
    }
  }, [index, isReady])

  useEffect(() => {
    if (progress >= IMAGE_DURATION) {
      onEnded()
    } else {
      setProgress(progress)
    }
  }, [progress])

  return (
    <StyledImg
      src={media.index <= index + 1 ? media.src : ''}
      onLoad={() => setReady(true)}
      style={
        !shouldPlay && !shouldPreload
          ? { display: 'none' }
          : shouldPreload
          ? { zIndex: -10 }
          : {}
      }
    />
  )
}

export const HighlightsPlayer = ({ highlights, name }) => {
  const total = highlights.length

  const [index, setIndex] = useState(0)
  const [, setNextIndex] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [mediaProgress, setMediaProgress] = useState(0)
  const [mediaDuration, setMediaDuration] = useState(0)
  const [media, setMedia] = useState([])
  const [initialPause, setInitialPause] = useState(false)
  const [showThumbnail, setShowThumbnail] = useState(false)

  useEffect(() => {
    // Keep track of the next index
    const nextIndex = index + 1
    if (nextIndex >= total) {
      setNextIndex(0)
    } else {
      setNextIndex(nextIndex)
    }
  }, [index])

  useEffect(() => {
    root.addEventListener('keydown', handleKeyDown)
    return () => {
      root.removeEventListener('keydown', handleKeyDown)
    }
  }, [index])

  useEffect(() => {
    setMedia(
      highlights.map((highlight, index) => {
        const mediaType = highlight.fields.media
          ? highlight.fields.media.fields.file.contentType
          : highlight.fields.file.url

        return {
          mediaType,
          src: highlight.fields.media.fields.file.url,
          poster: `${highlight.fields.poster.fields.file.url}?w=100`,
          index,
          id: highlight.sys.id,
          title: highlight.fields.title,
        }
      })
    )
  }, [])

  const handleGoForward = (confirm?: boolean) => {
    setPlaying(false)
    if (!confirm && index === 0 && !initialPause) {
      setInitialPause(true)
      setShowThumbnail(true)
      return
    }
    if (confirm && initialPause) {
      setShowThumbnail(false)
    }
    if (index === media.length - 1) {
      // Current index is the maximum
      setIndex(0)
      setMediaProgress(0)
    } else {
      // We can safely forward the index
      setIndex(index + 1)
      setMediaProgress(0)
    }
  }

  const handleGoBack = () => {
    const nextIndex = index - 1
    if (nextIndex !== -1) {
      setPlaying(false)
      setIndex(nextIndex)
    }
  }

  const onCanPlay = duration => {
    setMediaDuration(duration)
    setPlaying(true)
  }

  const handleKeyDown = e => {
    if (e.key === 'ArrowRight') {
      setInitialPause(true)
      handleGoForward()
    }
    if (e.key === 'ArrowLeft') {
      setInitialPause(true)
      handleGoBack()
    }
  }

  const title = get(highlights[index], 'fields.title', 'Blue Lagoon')

  if (!media[index]) {
    return null
  }

  return (
    <MediaOuter>
      {showThumbnail ? (
        <HighlightsThumbnail
          onClick={() => {
            handleGoForward(true)
          }}
        />
      ) : null}
      <MediaWrap>
        <ClickableOverlayRight
          onClick={() => {
            setInitialPause(true)
            handleGoForward()
          }}
        />
        <ClickableOverlayLeft
          onClick={() => {
            setInitialPause(true)
            handleGoBack()
          }}
        />
        <ContentWrap>
          <TextContent>
            <Type color="#FFF" size={{ xs: 16 }} weight="bold">
              {name}
            </Type>
            <Type color="#FFF" size={{ xs: 14 }}>
              {title}
            </Type>
          </TextContent>
          <MediaProgress
            current={index}
            total={total}
            isPlaying={playing}
            mediaDuration={mediaProgress}
          />
        </ContentWrap>
        {media.map(m =>
          m.mediaType.includes('image') ? (
            <Image
              key={m.id}
              media={m}
              onEnded={handleGoForward}
              onCanPlay={onCanPlay}
              index={index}
              setProgress={time => {
                if (mediaDuration > 0) {
                  setMediaProgress(time / mediaDuration)
                }
              }}
            />
          ) : (
            <Video
              key={m.id}
              media={m}
              onEnded={handleGoForward}
              onCanPlay={onCanPlay}
              index={index}
              setProgress={time => {
                if (mediaDuration > 0) {
                  setMediaProgress(time / mediaDuration)
                }
              }}
            />
          )
        )}
      </MediaWrap>
    </MediaOuter>
  )
}
