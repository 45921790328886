import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { gradients } from 'bl-common/src/constants/gradients'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import {
  IComparisonCard20Fields,
  ISectionComparisonCards20Fields,
} from 'bl-common/src/generated/contentful'
import { useInView } from 'bl-common/src/hooks/useInView'
import { CssThemeVariables } from 'bl-common/src/styles/CssThemeVariables'
import { Gradient } from 'bl-common/src/types/custom'
import { Appear } from 'bl-common/src/units/Appear'
import { ResponsiveParallax } from 'bl-common/src/units/ResponsiveParallax'
import { media, mediaMax } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'
import {
  triggerProductClick,
  triggerProductImpressions,
} from 'bl-utils/src/analytics'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { formatGradient } from '../../utils/formatters'
import Card from './Card'

type ComparisonCardProps = {
  isRight?: boolean
  bgColor?: IComparisonCard20Fields['backgroundColor']
}

const Container = styled.div<{ gradient?: Gradient }>`
  margin-top: 32px;
  padding-top: 32px;

  ${media.md(css`
    display: grid;
    grid-template-columns: 55% 45%;
    margin-top: 0;
  `)}

  &::after {
    content: '';
    position: absolute;
    top: 192px;
    width: 100%;
    left: 20%;
    height: 52%;

    ${props => css`
      background: linear-gradient(
        ${props.gradient.color1 || gradients.supportSilver[0]},
        ${props.gradient.color2 || gradients.supportSilver[1]}
      );
    `};

    .t-dark & {
      opacity: 0.1;
    }

    ${media.md(css`
      top: ${({ theme }) => theme.spacing[26]};
      left: 41%;
      height: 58%;
    `)}
  }
`

const Column = styled.div`
  position: relative;
  z-index: ${zIndex.above};

  &:nth-child(odd) {
    ${mixins.siteGutter({ right: false })}
    padding-right: ${({ theme }) => theme.spacing[2]};

    ${mediaMax.md(css`
      margin-bottom: ${({ theme }) => theme.spacing[4]};
    `)}
  }

  &:nth-child(even) {
    padding-left: ${({ theme }) => theme.spacing[2]};
    ${mixins.siteGutter({ left: false })}
  }
`

const ContentWrapper = styled.div`
  position: relative;
  z-index: ${zIndex.above2};
  margin-bottom: 48px;

  p {
    max-width: 100%;
  }

  ${media.md(css`
    margin-bottom: ${({ theme }) => theme.spacing[0.5]};
    padding-right: ${({ theme }) => theme.spacing[10]};
  `)}
`

const ComparisonCard = styled.div<ComparisonCardProps>`
  z-index: ${zIndex.above};

  ${({ isRight, bgColor }) =>
    isRight &&
    css`
      padding: 24px;
      background: ${colors.midGrey};
      color: ${colors.white};
      background: ${bgColor === 'light' ? colors.cream : colors.midGrey};
      color: ${bgColor === 'light' ? colors.midGrey : colors.white};
    `}

  ${media.md(css`
    padding: ${({ isRight, theme }: { isRight?: boolean; theme: any }) =>
      isRight ? theme.spacing[2.5] : 0};

    &:nth-child(odd) {
      margin-top: ${({ theme }) => theme.spacing[9]};
    }

    &:nth-child(even) {
      margin-top: ${({ theme }) => theme.spacing[1]};
    }
  `)}
`

const Content = ({ title, description }) => (
  <ContentWrapper>
    <Appear observer>
      <Appear>
        <Type as="h2" preset="headlineExtraLarge" bottom={{ xs: 0.5, md: 1.5 }}>
          {title}
        </Type>
      </Appear>
      <Appear>
        <Type
          multiline
          preset="subtitle"
          maxWidth={435}
          bottom={{ xs: 1, md: 2 }}
          // format markdown text to be able to render links and other html tags
          dangerouslySetInnerHTML={{ __html: formatHtmlText(description) }}
        />
      </Appear>
    </Appear>
  </ContentWrapper>
)

export const ComparisonCards2 = ({
  title,
  description,
  cards,
  gradient,
}: ISectionComparisonCards20Fields) => {
  const { exchangeRates } = useContext(CurrencyContext)
  const formattedGradient: Gradient = formatGradient(gradient)

  const reportImpressionIfRelevant = () => {
    const productReferences = cards
      .filter(card => card.fields.productReference)
      .map(card => card.fields.productReference)

    if (exchangeRates) {
      triggerProductImpressions(productReferences, exchangeRates?.EUR)
    }
  }

  const { ref } = useInView({
    unobserveOnEnter: true,
    onChange: inView => {
      if (inView) {
        reportImpressionIfRelevant()
      }
    },
  })

  const onClick = card => {
    if (card.fields.productReference && exchangeRates) {
      triggerProductClick(card.fields.productReference, exchangeRates?.EUR)
    }
  }

  return (
    <Container gradient={formattedGradient} ref={ref}>
      <Column>
        {title && description && (
          <Content title={title} description={description} />
        )}
        <ComparisonCard>
          {!!cards[0]?.fields && (
            <Card
              key={cards[0].fields.title}
              title={cards[0].fields.title}
              price={cards[0].fields.price}
              description={cards[0].fields.description}
              image={cards[0].fields.image}
              imageRatio={220 / 316}
              buttons={cards[0].fields.buttons}
              onClick={() => onClick(cards[0])}
            />
          )}
        </ComparisonCard>
      </Column>
      <Column>
        <CssThemeVariables
          listMarkerColor={
            cards[1].fields.backgroundColor === 'light'
              ? colors.deepBlue
              : colors.blueOnDark
          }
        >
          <ResponsiveParallax disabledMobile from={8} to={-8}>
            <ComparisonCard isRight bgColor={cards[1].fields.backgroundColor}>
              {!!cards[1]?.fields && (
                <Card
                  key={cards[1].fields.title}
                  title={cards[1].fields.title}
                  price={cards[1].fields.price}
                  description={cards[1].fields.description}
                  image={cards[1].fields.image}
                  imageRatio={236 / 200}
                  buttons={cards[1].fields.buttons}
                  isRight
                  onClick={() => onClick(cards[1])}
                />
              )}
            </ComparisonCard>
          </ResponsiveParallax>
        </CssThemeVariables>
      </Column>
    </Container>
  )
}
