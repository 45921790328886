import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { BLOCKS } from '@contentful/rich-text-types'
import { useMutation } from 'urql'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import {
  IPagePackageFields,
  ISectionUseGiftCardFields,
} from 'bl-common/src/generated/contentful'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Appear } from 'bl-common/src/units/Appear'
import { DiscoverPanel } from 'bl-common/src/units/DiscoverPanel'
import { SimpleForm } from 'bl-common/src/units/Form/SimpleForm'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'

import { getEntryWrapper } from 'services/contentfulClient'

import { graphql } from '../../../gql'
import { formatGradient } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'

interface Errors {
  giftCardNo?: string
}

interface FormValues {
  giftCardNo: string
}

const isAmountGiftcard = (externalDocumentNo: string) =>
  externalDocumentNo === '2lRuuzoGK70uJwXZb8kWJH'

const oldGiftcardCC = new RegExp(/^8301[0-9]{10}$/)
const oldGiftcardPaper = new RegExp(/^[0-9]{1,5}$/)
const oldGiftCardPaths = {
  is: '/is/gjafabref/bokun',
  en: '/is/gjafabref/bokun',
}

const documentOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Type preset="headlineLarge" bottom={{ xs: 1, md: 2 }}>
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Type preset="text" bottom={{ xs: 1, md: 2 }}>
        {children}
      </Type>
    ),
  },
}

const FindGiftCardExternalDocument = graphql(`
  mutation FindGiftCardExternalDocument(
    $input: BookingFlowFindGiftCardRequestModelInput!
  ) {
    Bluelagoon {
      Booking {
        postBookingflowServerGiftcardFind(input: $input) {
          externalDocumentNo
        }
      }
    }
  }
`)

export const UseGiftCardSection = (props: any) => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const continueUrl = useRef('')
  const hash = useRef('')

  const [, findGiftCard] = useMutation(FindGiftCardExternalDocument)

  const {
    backgroundGradient,
    config,
    offsetDirection,
    images,
    content,
    ...contentfulProps
  } = getSectionProps(props) as ISectionUseGiftCardFields

  const formattedGradient = formatGradient(backgroundGradient)

  const [errors, setErrors] = useState<object | undefined>(undefined)

  const submit = async (values: FormValues) => {
    const cleanGC = values.giftCardNo.replace(/\s/g, '')
    const isOldGiftCard =
      oldGiftcardCC.test(cleanGC) || oldGiftcardPaper.test(cleanGC)

    if (isOldGiftCard) {
      continueUrl.current = oldGiftCardPaths[i18n.language as 'is' | 'en']
    } else {
      const { data, error } = await findGiftCard({
        input: { giftCardNo: cleanGC },
      })

      if (error && error !== null) {
        setErrors({
          giftCardNo: t('giftCardNotFound'),
        })
        return false
      }

      const giftCard = data.Bluelagoon.Booking.postBookingflowServerGiftcardFind

      const {
        fields: { path },
      } = await getEntryWrapper<IPagePackageFields>(
        giftCard.externalDocumentNo,
        {
          locale: i18n.language,
        }
      )

      if (path) {
        continueUrl.current = `${path}/use`

        if (isAmountGiftcard(giftCard.externalDocumentNo)) {
          hash.current = 'nota'
        }
      }
    }

    return true
  }

  const validate = (values: FormValues) => {
    const errors: Errors = {}

    if (!values.giftCardNo) {
      errors.giftCardNo = t('giftCardNumberReqired')
    }

    return errors
  }

  const initialValues: FormValues = {
    giftCardNo: '',
  }

  return (
    <Appear>
      <OffsetSection
        offsetDirection={offsetDirection}
        background={formattedGradient}
        config={config}
      >
        <DiscoverPanel {...contentfulProps} images={images}>
          {!!content && (
            <RichTextRenderer
              document={content}
              customOptions={documentOptions}
            />
          )}

          <SimpleForm
            form={{
              fields: [
                {
                  type: 'text',
                  key: 'giftCardNo',
                  name: t('giftCardNumber'),
                },
              ],
            }}
            initialValues={initialValues}
            validate={validate}
            onSubmit={submit}
            onComplete={() => {
              router.push({
                pathname: continueUrl?.current,
                hash: hash.current,
              })
            }}
            buttonText={t('moreDetail')}
            buttonSize="small"
            parentErrors={errors}
            validateOnBlur={false}
          />
        </DiscoverPanel>
      </OffsetSection>
    </Appear>
  )
}

export default UseGiftCardSection
