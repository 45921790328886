import get from 'lodash/get'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { zIndex } from '../../constants/zIndex'
import { Button as _Button } from '../../elements/Button/Button'
import { between } from '../../utils/between'
import { media } from '../../utils/media'

type ContainerProps = {
  isRetreat?: boolean
  backgroundImage?: string
  backgroundGradient?: any
}

type ListItemProps = {
  itemStyle?: string
}

export const Content = styled.div`
  min-height: 340px;
  padding: 30px 24px 62px 24px;

  ${media.md(css`
    min-height: auto;
    padding-top: ${between(16, 32)};
    padding-bottom: ${between(24, 62)};
    padding-left: ${between(8, 24)};
    padding-right: ${between(8, 24)};
  `)};
`

export const Top = styled.div`
  padding-bottom: 8px;
  position: relative;

  ${media.md(css`
    padding-bottom: ${({ theme }) => theme.spacing[0.5]};
  `)};
`

export const List = styled.ul`
  margin: 0;
  margin-right: ${({ theme }) => theme.spacing[1]};
`

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  margin-left: ${({ theme }) => theme.spacing[1]};
  margin-bottom: ${({ theme }) => theme.spacing[0.5]};
  position: relative;

  &::before {
    color: ${({ color }) =>
      color === 'light' ? colors.white : colors.deepBlue};
    content: '•';
    display: block;
    margin-left: -1rem;
    padding-right: 1rem;
  }

  ${props =>
    props.itemStyle === 'Additional' &&
    css`
      &::before {
        content: '+';
        font-weight: 600;
      }
    `};

  ${props =>
    props.itemStyle === 'Title' &&
    css`
      color: ${colors.deepBlue};
      margin-left: 0;
      &::before {
        display: none;
      }
    `};
`

export const Button = styled(_Button)`
  z-index: ${zIndex.above};
`

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  padding: 1rem;
  background-color: ${colors.white};
  height: 100%;

  ${({ backgroundGradient }) =>
    backgroundGradient &&
    css`
      background: linear-gradient(
        ${get(
          backgroundGradient,
          'fields.degrees',
          backgroundGradient.degrees
        )},
        ${get(backgroundGradient, 'fields.color1', backgroundGradient.color1)},
        ${get(backgroundGradient, 'fields.color2', backgroundGradient.color2)}
      );
    `}

  ${props =>
    props.isRetreat &&
    css`
      z-index: ${zIndex.above};
      background-image: linear-gradient(35deg, #626971 0%, #9c747f 100%);
      color: ${colors.cream};
      &::before {
        background-image: linear-gradient(
          135deg,
          rgba(0, 0, 0, 0) 0%,
          #000000 90%
        );
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0.47;
        position: absolute;
        right: 0;
        top: 0;
      }
      &::after {
        content: '';
        display: block;
        background-image: url(${props.backgroundImage});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.5;
        mix-blend-mode: multiply;
        pointer-events: none;
      }
    `};

  ${Top}::after {
    ${props =>
      props.isRetreat &&
      css`
        background: ${rgba(colors.lightGrey, 0.45)};
      `};
  }

  ${ListItem} {
    &::before {
      ${props =>
        props.isRetreat &&
        css`
          color: ${colors.cream};
        `};
    }
  }
`
export const Image = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ theme }) => theme.spacing[20]};
  margin-left: -1rem;
  margin-top: -1rem;
  width: calc(100% + 2rem);

  ${media.md(css`
    height: ${({ theme }) => theme.spacing[16]};
  `)};
`
