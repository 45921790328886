import React from 'react'
import get from 'lodash/get'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'

import { getSectionProps } from '../../../../utils/getSectionProps'
import { VideoPlayerHero } from './VideoPlayerHero'

const VideoPlayerHeroSection = props => {
  const { config, videoPlayerHero, heading, multilineSubheading, centerText } =
    getSectionProps(props)
  const { videoLoop, videoId, ctaText, fallbackImage } = get(
    videoPlayerHero,
    'fields',
    {}
  )
  return (
    <Section bottom={{ xs: 2, md: 4 }} top={{ xs: 2, md: 4 }} config={config}>
      <Appear observer>
        {heading && (
          <Appear>
            <Type
              as="h1"
              preset="headlineLarge"
              bottom={{ xs: 0.5, md: 1 }}
              textAlign={centerText ? 'center' : 'left'}
            >
              {heading}
            </Type>
          </Appear>
        )}
        {multilineSubheading && (
          <Appear>
            <Type
              as="h2"
              preset="subtitle"
              bottom={{ xs: 2, md: 5 }}
              maxWidth={1000}
              multiline
              textAlign={centerText ? 'center' : 'left'}
              style={centerText ? { margin: '0 auto' } : {}}
            >
              {multilineSubheading}
            </Type>
          </Appear>
        )}
        {(videoLoop || fallbackImage) && (
          <VideoPlayerHero
            videoLoop={videoLoop}
            videoId={videoId}
            fallbackImage={fallbackImage}
            ctaText={ctaText}
          />
        )}
      </Appear>
    </Section>
  )
}

export default VideoPlayerHeroSection
