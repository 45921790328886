import styled, { css } from 'styled-components'

import { media } from '../../utils/media'

export const ImageWrap = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;

  ${media.md(css`
    padding-bottom: 72%;
  `)}
`

export const BackgroundImage = styled.div`
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`
