import React from 'react'
import styled, { css } from 'styled-components'

import { LightBoxItem } from '../../units/LightBox/LightBoxItem'
import { SmartImage } from '../../units/SmartImage/SmartImage'
import { between } from '../../utils/between'

type ImageWrapProps = {
  height?: string
  scale?: number
}

const ImageWrap = styled.div<ImageWrapProps>`
  ${({ height = 'small', scale = 1.24 }) => {
    const imageHeight = height === 'large' ? 590 : 390
    return css`
      min-height: ${between(imageHeight / scale, imageHeight)};
    `
  }};

  height: 100%;
  position: relative;
  overflow: hidden;
`

const BackgroundImage = styled.div`
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export const ImageCard = ({
  image,
  lightBoxImage,
  imageSize,
  backgroundPosition,
}) => {
  return (
    <LightBoxItem image={lightBoxImage || image}>
      <ImageWrap height={imageSize}>
        <SmartImage image={image}>
          {image => (
            <BackgroundImage
              style={{
                backgroundImage: `url(${image})`,
                backgroundPosition,
              }}
            />
          )}
        </SmartImage>
      </ImageWrap>
    </LightBoxItem>
  )
}
