import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import styled, { css } from 'styled-components'

import { durations } from 'bl-common/src/constants/durations'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Amount } from 'bl-common/src/elements/Amount'
import { ArrowLink } from 'bl-common/src/elements/ArrowLink'
import { Button } from 'bl-common/src/elements/Button/Button'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { IComparisonCard20Fields } from 'bl-common/src/generated/contentful'
import { List, RichTextListItem } from 'bl-common/src/richText/RichTextBlocks'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { media, mediaMax } from 'bl-common/src/utils/media'

type DescriptionProps = {
  isRight?: boolean
}

type ImageWrapperProps = {
  ratio?: number
}

const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  flex-basis: calc(50% - 12px);

  ${media.md(css`
    flex-basis: calc(50% - 24px);
  `)}
`

const CardContainer = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: ${zIndex.above};
  transition: transform ${durations.long}ms ease;
`

const CardContentWrapper = styled.div`
  padding-top: 24px;

  ${media.md(css`
    padding-top: ${({ theme }) => theme.spacing[2]};
  `)}
`

const ArrowWrap = styled.span`
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
  transition: transform ${durations.short}ms;
`

const ButtonWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-top: auto;

  ${media.md(css`
    &:hover {
      ${ArrowWrap} {
        transform: translateX(10px);
      }
    }
  `)}

  a {
    padding: 14px 32px;

    ${media.md(css`
      padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[1.5]}`};
    `)}

    ${media.mlg(css`
      padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[3]}`};
    `)}
  }
`

const ButtonGroup = styled.div`
  display: flex;
`

const AmountWrapper = styled(Type)`
  opacity: 0.75;
  margin-bottom: 8px;
`

const Heading = styled(Type)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Description = styled.div<DescriptionProps>`
  ${media.md(css`
    ${() => css`
      padding-right: ${({
        isRight,
        theme,
      }: DescriptionProps & { theme: any }) =>
        isRight ? 0 : theme.spacing[8]};
    `}
  `)}

  p {
    margin-bottom: ${({ theme }) => theme.spacing[1.5]};

    &:empty {
      display: none;
    }

    ${mediaMax.md(css`
      font-size: 14px;
      font-weight: 400;
    `)}
  }

  li p {
    margin-bottom: 0;
  }
`

const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  width: 100%;
  z-index: ${zIndex.above};
  overflow: hidden;
  padding-bottom: ${({ ratio }) => ratio * 100}%;
`

const listItemOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <Type preset="text">{children}</Type>,
  },
}

const documentOptions = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: node => (
      <RichTextListItem>
        <RichTextRenderer
          document={node.content[0]}
          customOptions={listItemOptions}
        />
      </RichTextListItem>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type
        size={{ xs: 12, md: 24 }}
        top={{ xs: 1 }}
        bottom={{ xs: 0.5 }}
        weight="light"
      >
        {children}
      </Type>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'list':
          return <List {...fields} />
        default:
          return null
      }
    },
  },
}

const CardContent = ({ title, price, priceFormat, isRight, children }) => (
  <>
    <Heading size={{ xs: 20, md: 40 }} weight="bold" bottom={{ xs: 0.5 }}>
      {title}
    </Heading>
    {price && (
      <AmountWrapper size={{ xs: 12, md: 16, mlg: 18 }}>
        <Amount value={price} format={priceFormat} />
      </AmountWrapper>
    )}
    {!!children && <Description isRight={isRight}>{children}</Description>}
  </>
)

type CardProps = IComparisonCard20Fields & {
  imageRatio?: number
  isRight?: boolean
  onClick: () => void
}

const Card = ({
  title,
  price,
  description,
  image,
  buttons,
  imageRatio = 1.5,
  isRight = false,
  onClick,
}: CardProps) => {
  const priceFormat = price ? price.fields.priceFormat : null

  return (
    <CardWrapper>
      <CardContainer>
        {!!image && (
          <ImageWrapper ratio={imageRatio}>
            <ContentfulImage image={image} />
          </ImageWrapper>
        )}
        <CardContentWrapper>
          <CardContent
            title={title}
            price={price}
            priceFormat={priceFormat}
            isRight={isRight}
          >
            {!!description && (
              <RichTextRenderer
                document={description}
                customOptions={documentOptions}
              />
            )}
          </CardContent>
          {buttons && (
            <ButtonGroup>
              {buttons.map((button, index) => {
                const { text, link, color, textColor } = button.fields
                const primaryButton = index === 0
                const secondaryButton = index === 1
                const renderArrowButton =
                  secondaryButton || color === 'transparent'
                const ButtonType = renderArrowButton ? ArrowLink : Button

                return (
                  <ButtonWrapper key={index}>
                    <ButtonType
                      to={link}
                      paddingSize={'large'}
                      preset={color}
                      top={{ md: 1 }}
                      textColor={
                        secondaryButton && isRight ? 'blueOnDark' : textColor
                      }
                      noHover={secondaryButton}
                      onClick={() => {
                        if (primaryButton) {
                          onClick()
                        }
                      }}
                      style={
                        !secondaryButton && color === 'transparent'
                          ? { paddingLeft: 0 }
                          : {}
                      }
                    >
                      {text}
                    </ButtonType>
                  </ButtonWrapper>
                )
              })}
            </ButtonGroup>
          )}
        </CardContentWrapper>
      </CardContainer>
    </CardWrapper>
  )
}

export default Card
