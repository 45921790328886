import React from 'react'

import { HeroHeader } from 'bl-common/src/units/HeroHeader/HeroHeader'
import { Section } from 'bl-common/src/units/Section/Section'

import { formatGradient } from '../../../utils/formatters/elements'
import { getSectionProps } from '../../../utils/getSectionProps'

export const HeroHeaderSection = props => {
  const { title, description, showArrow, image, frameGradient, config } =
    getSectionProps(props)

  return image ? (
    <Section noHorizontalPadding config={config} top={{ xs: 0 }} hideOverflowX>
      <HeroHeader
        image={image}
        frameGradient={formatGradient(frameGradient)}
        title={title}
        description={description}
        showArrow={showArrow}
      />
    </Section>
  ) : null
}

export default HeroHeaderSection
