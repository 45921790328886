const preStartData = {
  small: [
    {
      top: 100.02000000000001,
      left: 536.8186549664268,
      life: 3001,
      speedUp: 20,
      speedRight: 8.731314924416242,
      startOpacity: 0.37512500000000004,
      size: 249.99,
      newTop: 100.02000000000001,
      newLeft: 536.8186549664268,
    },
    {
      top: 102.02,
      left: 718.2715563090329,
      life: 3101,
      speedUp: 20,
      speedRight: 9.207378290698692,
      startOpacity: 0.387625,
      size: 248.99,
      newTop: 102.02,
      newLeft: 718.2715563090329,
    },
    {
      top: 104.02,
      left: -87.60536947399487,
      life: 3201,
      speedUp: 20,
      speedRight: 7.216938039218963,
      startOpacity: 0.40012499999999995,
      size: 247.99,
      newTop: 104.02,
      newLeft: -87.60536947399487,
    },
    {
      top: 106.02000000000001,
      left: 13.011470473595729,
      life: 3301,
      speedUp: 20,
      speedRight: 7.689749742540051,
      startOpacity: 0.412625,
      size: 246.99,
      newTop: 106.02000000000001,
      newLeft: 13.011470473595729,
    },
    {
      top: 108.04,
      left: 675.6475639011136,
      life: 3402,
      speedUp: 20,
      speedRight: 6.765830799164325,
      startOpacity: 0.42525,
      size: 245.98,
      newTop: 108.04,
      newLeft: 675.6475639011136,
    },
    {
      top: 110.02000000000001,
      left: -28.255185086698,
      life: 3501,
      speedUp: 20,
      speedRight: 0.9956014595317608,
      startOpacity: 0.43762500000000004,
      size: 244.99,
      newTop: 110.02000000000001,
      newLeft: -28.255185086698,
    },
    {
      top: 112,
      left: 615.4958835328998,
      life: 3600,
      speedUp: 20,
      speedRight: 18.389872858294094,
      startOpacity: 0.44999999999999996,
      size: 244,
      newTop: 112,
      newLeft: 615.4958835328998,
    },
    {
      top: 114.02,
      left: -39.42935839007333,
      life: 3701,
      speedUp: 20,
      speedRight: 13.961734835496253,
      startOpacity: 0.46262499999999995,
      size: 242.99,
      newTop: 114.02,
      newLeft: -39.42935839007333,
    },
    {
      top: 116,
      left: 376.98525386163146,
      life: 3800,
      speedUp: 20,
      speedRight: 14.894230923286607,
      startOpacity: 0.475,
      size: 242,
      newTop: 116,
      newLeft: 376.98525386163146,
    },
    {
      top: 118.04,
      left: 695.1532398963625,
      life: 3902,
      speedUp: 20,
      speedRight: 7.401691021339234,
      startOpacity: 0.48775,
      size: 240.98,
      newTop: 118.04,
      newLeft: 695.1532398963625,
    },
    {
      top: 120.02,
      left: 709.7598234679581,
      life: 4001,
      speedUp: 20,
      speedRight: 15.500593682350612,
      startOpacity: 0.5001249999999999,
      size: 239.99,
      newTop: 120.02,
      newLeft: 709.7598234679581,
    },
    {
      top: 122.03999999999999,
      left: -123.97652287812282,
      life: 4102,
      speedUp: 20,
      speedRight: 7.603831680599971,
      startOpacity: 0.51275,
      size: 238.98000000000002,
      newTop: 122.03999999999999,
      newLeft: -123.97652287812282,
    },
    {
      top: 124.02,
      left: 780.6530601568545,
      life: 4201,
      speedUp: 20,
      speedRight: 0.8434309301588927,
      startOpacity: 0.5251250000000001,
      size: 237.99,
      newTop: 124.02,
      newLeft: 780.6530601568545,
    },
    {
      top: 126.02000000000001,
      left: -5.156146188086481,
      life: 4301,
      speedUp: 20,
      speedRight: 17.549610910936483,
      startOpacity: 0.537625,
      size: 236.99,
      newTop: 126.02000000000001,
      newLeft: -5.156146188086481,
    },
    {
      top: 128.01999999999998,
      left: 719.6519375164729,
      life: 4401,
      speedUp: 20,
      speedRight: 19.387436435354232,
      startOpacity: 0.550125,
      size: 235.99,
      newTop: 128.01999999999998,
      newLeft: 719.6519375164729,
    },
    {
      top: 130.01999999999998,
      left: 38.67875883873653,
      life: 4501,
      speedUp: 20,
      speedRight: 11.060396413965297,
      startOpacity: 0.5626249999999999,
      size: 234.99,
      newTop: 130.01999999999998,
      newLeft: 38.67875883873653,
    },
    {
      top: 132,
      left: 0.7390338276799895,
      life: 4600,
      speedUp: 20,
      speedRight: 5.379570466928598,
      startOpacity: 0.575,
      size: 234,
      newTop: 132,
      newLeft: 0.7390338276799895,
    },
    {
      top: 134,
      left: 658.820011778187,
      life: 4700,
      speedUp: 20,
      speedRight: 5.375464488698944,
      startOpacity: 0.5875,
      size: 233,
      newTop: 134,
      newLeft: 658.820011778187,
    },
    {
      top: 136.02,
      left: 480.30844412579694,
      life: 4801,
      speedUp: 20,
      speedRight: 11.568210108700146,
      startOpacity: 0.600125,
      size: 231.99,
      newTop: 136.02,
      newLeft: 480.30844412579694,
    },
    {
      top: 138.04000000000002,
      left: 601.6469039473862,
      life: 4902,
      speedUp: 20,
      speedRight: 18.182675227685184,
      startOpacity: 0.61275,
      size: 230.98,
      newTop: 138.04000000000002,
      newLeft: 601.6469039473862,
    },
    {
      top: 140.01999999999998,
      left: 687.2727962008736,
      life: 5001,
      speedUp: 20,
      speedRight: 11.515808627686388,
      startOpacity: 0.6251249999999999,
      size: 229.99,
      newTop: 140.01999999999998,
      newLeft: 687.2727962008736,
    },
    {
      top: 142.04,
      left: 713.3233962838668,
      life: 5102,
      speedUp: 20,
      speedRight: 9.503232250839577,
      startOpacity: 0.63775,
      size: 228.98,
      newTop: 142.04,
      newLeft: 713.3233962838668,
    },
    {
      top: 144.04,
      left: 295.88217881162586,
      life: 5202,
      speedUp: 20,
      speedRight: 8.453841775275045,
      startOpacity: 0.65025,
      size: 227.98,
      newTop: 144.04,
      newLeft: 295.88217881162586,
    },
    {
      top: 146.04,
      left: -66.39269758904088,
      life: 5302,
      speedUp: 20,
      speedRight: 1.2213572127393313,
      startOpacity: 0.66275,
      size: 226.98,
      newTop: 146.04,
      newLeft: -66.39269758904088,
    },
    {
      top: 148.04000000000002,
      left: -126.13731388184235,
      life: 5402,
      speedUp: 20,
      speedRight: 9.336001926271994,
      startOpacity: 0.67525,
      size: 225.98,
      newTop: 148.04000000000002,
      newLeft: -126.13731388184235,
    },
    {
      top: 150.04,
      left: -13.95535493129652,
      life: 5502,
      speedUp: 20,
      speedRight: 0.45131143722122236,
      startOpacity: 0.68775,
      size: 224.98000000000002,
      newTop: 150.04,
      newLeft: -13.95535493129652,
    },
    {
      top: 152,
      left: 378.62292448811405,
      life: 5600,
      speedUp: 20,
      speedRight: 11.028201157600602,
      startOpacity: 0.7,
      size: 224,
      newTop: 152,
      newLeft: 378.62292448811405,
    },
    {
      top: 154.04,
      left: 29.132492048505824,
      life: 5702,
      speedUp: 20,
      speedRight: 16.268502685879838,
      startOpacity: 0.71275,
      size: 222.98,
      newTop: 154.04,
      newLeft: 29.132492048505824,
    },
    {
      top: 156.04,
      left: 290.46216804247524,
      life: 5802,
      speedUp: 20,
      speedRight: 10.930840554202547,
      startOpacity: 0.72525,
      size: 221.98,
      newTop: 156.04,
      newLeft: 290.46216804247524,
    },
    {
      top: 158.01999999999998,
      left: 536.1840838866906,
      life: 5901,
      speedUp: 20,
      speedRight: 2.1331951193054666,
      startOpacity: 0.737625,
      size: 220.99,
      newTop: 158.01999999999998,
      newLeft: 536.1840838866906,
    },
    {
      top: 160.01999999999998,
      left: 244.85444868567328,
      life: 6001,
      speedUp: 20,
      speedRight: 8.853942838061265,
      startOpacity: 0.7501249999999999,
      size: 219.99,
      newTop: 160.01999999999998,
      newLeft: 244.85444868567328,
    },
    {
      top: 162.01999999999998,
      left: -3.092980785770692,
      life: 6101,
      speedUp: 20,
      speedRight: 1.211126341586275,
      startOpacity: 0.762625,
      size: 218.99,
      newTop: 162.01999999999998,
      newLeft: -3.092980785770692,
    },
    {
      top: 164.04,
      left: -191.3505641635877,
      life: 6202,
      speedUp: 20,
      speedRight: 7.5219046519697175,
      startOpacity: 0.77525,
      size: 217.98,
      newTop: 164.04,
      newLeft: -191.3505641635877,
    },
    {
      top: 166.01999999999998,
      left: -194.5592436117191,
      life: 6301,
      speedUp: 20,
      speedRight: 15.562608748081463,
      startOpacity: 0.787625,
      size: 216.99,
      newTop: 166.01999999999998,
      newLeft: -194.5592436117191,
    },
    {
      top: 168.02,
      left: -204.48280000180947,
      life: 6401,
      speedUp: 20,
      speedRight: 11.682802248634863,
      startOpacity: 0.800125,
      size: 215.99,
      newTop: 168.02,
      newLeft: -204.48280000180947,
    },
    {
      top: 170.04,
      left: 705.2217964231997,
      life: 6502,
      speedUp: 20,
      speedRight: 4.920316167663996,
      startOpacity: 0.81275,
      size: 214.98,
      newTop: 170.04,
      newLeft: 705.2217964231997,
    },
    {
      top: 172.02,
      left: 124.19030629931389,
      life: 6601,
      speedUp: 20,
      speedRight: 6.033639585306685,
      startOpacity: 0.825125,
      size: 213.99,
      newTop: 172.02,
      newLeft: 124.19030629931389,
    },
    {
      top: 174.04,
      left: 210.30433778545566,
      life: 6702,
      speedUp: 20,
      speedRight: 7.494677687290965,
      startOpacity: 0.83775,
      size: 212.98,
      newTop: 174.04,
      newLeft: 210.30433778545566,
    },
    {
      top: 176.02,
      left: 650.7599056856102,
      life: 6801,
      speedUp: 20,
      speedRight: 7.637138389262201,
      startOpacity: 0.850125,
      size: 211.99,
      newTop: 176.02,
      newLeft: 650.7599056856102,
    },
    {
      top: 178.02,
      left: 273.9199927978384,
      life: 6901,
      speedUp: 20,
      speedRight: 13.313438507184955,
      startOpacity: 0.862625,
      size: 210.99,
      newTop: 178.02,
      newLeft: 273.9199927978384,
    },
    {
      top: 180.04,
      left: -151.68433756735647,
      life: 7002,
      speedUp: 20,
      speedRight: 4.31381423607081,
      startOpacity: 0.87525,
      size: 209.98,
      newTop: 180.04,
      newLeft: -151.68433756735647,
    },
    {
      top: 182.02,
      left: 12.067491117059557,
      life: 7101,
      speedUp: 20,
      speedRight: 16.30500429767494,
      startOpacity: 0.887625,
      size: 208.99,
      newTop: 182.02,
      newLeft: 12.067491117059557,
    },
    {
      top: 184,
      left: 522.4993650362563,
      life: 7200,
      speedUp: 20,
      speedRight: 0.8474394323593826,
      startOpacity: 0.9,
      size: 208,
      newTop: 184,
      newLeft: 522.4993650362563,
    },
    {
      top: 186.04,
      left: 22.0897132235893,
      life: 7302,
      speedUp: 20,
      speedRight: 6.077631820624032,
      startOpacity: 0.91275,
      size: 206.98,
      newTop: 186.04,
      newLeft: 22.0897132235893,
    },
    {
      top: 188.04,
      left: 219.28429815676537,
      life: 7402,
      speedUp: 20,
      speedRight: 6.510010301379516,
      startOpacity: 0.92525,
      size: 205.98,
      newTop: 188.04,
      newLeft: 219.28429815676537,
    },
    {
      top: 190.04,
      left: 533.4223004923323,
      life: 7502,
      speedUp: 20,
      speedRight: 11.36478397646433,
      startOpacity: 0.93775,
      size: 204.98,
      newTop: 190.04,
      newLeft: 533.4223004923323,
    },
    {
      top: 192.02,
      left: 610.3754284582591,
      life: 7601,
      speedUp: 20,
      speedRight: 17.378400142264702,
      startOpacity: 0.950125,
      size: 203.99,
      newTop: 192.02,
      newLeft: 610.3754284582591,
    },
    {
      top: 194.02,
      left: 240.49944366078847,
      life: 7701,
      speedUp: 20,
      speedRight: 12.116386091920322,
      startOpacity: 0.962625,
      size: 202.99,
      newTop: 194.02,
      newLeft: 240.49944366078847,
    },
    {
      top: 196,
      left: 464.4189518197603,
      life: 7800,
      speedUp: 20,
      speedRight: 18.354508747696592,
      startOpacity: 0.975,
      size: 202,
      newTop: 196,
      newLeft: 464.4189518197603,
    },
    {
      top: 198,
      left: -142.23551480728173,
      life: 7900,
      speedUp: 20,
      speedRight: 11.653737289846124,
      startOpacity: 0.9875,
      size: 201,
      newTop: 198,
      newLeft: -142.23551480728173,
    },
    {
      top: 194.32,
      left: 479.8806781039675,
      life: 7716,
      speedUp: 20,
      speedRight: 16.243973258051646,
      startOpacity: 0.9645,
      size: 202.84,
      newTop: 194.32,
      newLeft: 479.8806781039675,
    },
    {
      top: 196.34,
      left: 600.773227635212,
      life: 7817,
      speedUp: 20,
      speedRight: 7.341582792262518,
      startOpacity: 0.977125,
      size: 201.83,
      newTop: 196.34,
      newLeft: 600.773227635212,
    },
    {
      top: 198.34,
      left: 141.6755916403969,
      life: 7917,
      speedUp: 20,
      speedRight: 5.06962009728559,
      startOpacity: 0.989625,
      size: 200.83,
      newTop: 198.34,
      newLeft: 141.6755916403969,
    },
    {
      top: 200,
      left: 500.08444947931923,
      life: 8000,
      speedUp: 20,
      speedRight: 1.3062975151587741,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -175.89056810550346,
      life: 8000,
      speedUp: 20,
      speedRight: 3.6949599155541746,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 578.2354756557465,
      life: 8000,
      speedUp: 20,
      speedRight: 4.368236122898708,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -63.75596585782577,
      life: 8000,
      speedUp: 20,
      speedRight: 18.825719816013383,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 194.58471058462965,
      life: 8000,
      speedUp: 20,
      speedRight: 4.344540385913764,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 108.24067609895866,
      life: 8000,
      speedUp: 20,
      speedRight: 6.7966791321646625,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 211.68382397301787,
      life: 8000,
      speedUp: 20,
      speedRight: 9.210078475614733,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 337.5693448627594,
      life: 8000,
      speedUp: 20,
      speedRight: 11.405167728808436,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 144.89537599453456,
      life: 8000,
      speedUp: 20,
      speedRight: 1.7025861783293905,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -24.58902436755244,
      life: 8000,
      speedUp: 20,
      speedRight: 7.501713061743116,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 626.4501529903514,
      life: 8000,
      speedUp: 20,
      speedRight: 12.589232917837867,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 535.1721960379421,
      life: 8000,
      speedUp: 20,
      speedRight: 11.238168751989468,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 708.3981759482509,
      life: 8000,
      speedUp: 20,
      speedRight: 16.815037125519954,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 465.641928597698,
      life: 8000,
      speedUp: 20,
      speedRight: 17.704485524878585,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 593.5463647478703,
      life: 8000,
      speedUp: 20,
      speedRight: 8.84939435496577,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 97.47518678200066,
      life: 8000,
      speedUp: 20,
      speedRight: 17.98088182036021,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 547.7601768437487,
      life: 8000,
      speedUp: 20,
      speedRight: 12.021911504286527,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 441.5837840869898,
      life: 8000,
      speedUp: 20,
      speedRight: 15.948103163258644,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 311.54837153513876,
      life: 8000,
      speedUp: 20,
      speedRight: 3.2265243482757633,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -133.76096665330908,
      life: 8000,
      speedUp: 20,
      speedRight: 6.954456775494147,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 605.3563379876856,
      life: 8000,
      speedUp: 20,
      speedRight: 11.973658110764397,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -197.97598924850251,
      life: 8000,
      speedUp: 20,
      speedRight: 14.196980959760118,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -183.26821391937736,
      life: 8000,
      speedUp: 20,
      speedRight: 15.2607473557355,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 648.9580212708332,
      life: 8000,
      speedUp: 20,
      speedRight: 7.633978881136878,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -15.508844578356474,
      life: 8000,
      speedUp: 20,
      speedRight: 8.08803140516467,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -91.97053473283772,
      life: 8000,
      speedUp: 20,
      speedRight: 10.335988454905234,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 452.7567642414207,
      life: 8000,
      speedUp: 20,
      speedRight: 14.228199209258246,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -120.34983725548858,
      life: 8000,
      speedUp: 20,
      speedRight: 19.329922111147418,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 712.1003278584301,
      life: 8000,
      speedUp: 20,
      speedRight: 0.690007578413927,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 585.4505668785125,
      life: 8000,
      speedUp: 20,
      speedRight: 19.54506656343501,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 142.42094293610006,
      life: 8000,
      speedUp: 20,
      speedRight: 9.901380582378788,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -148.10859045641237,
      life: 8000,
      speedUp: 20,
      speedRight: 6.029595538773647,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 435.4904162020682,
      life: 8000,
      speedUp: 20,
      speedRight: 1.6752272497409226,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 431.932445093409,
      life: 8000,
      speedUp: 20,
      speedRight: 16.029331499057676,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 79.38352000050173,
      life: 8000,
      speedUp: 20,
      speedRight: 3.5625225559269413,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 696.8402999994545,
      life: 8000,
      speedUp: 20,
      speedRight: 1.4060035414209837,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 184.8863754178273,
      life: 8000,
      speedUp: 20,
      speedRight: 2.3518629633007304,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 294.3580223920325,
      life: 8000,
      speedUp: 20,
      speedRight: 13.477316247330906,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 356.3991457291553,
      life: 8000,
      speedUp: 20,
      speedRight: 6.206290998940349,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -7.9609347303074,
      life: 8000,
      speedUp: 20,
      speedRight: 19.409048211657876,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 336.64595234283263,
      life: 8000,
      speedUp: 20,
      speedRight: 18.50085477196616,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -21.40134916926445,
      life: 8000,
      speedUp: 20,
      speedRight: 18.816412229069513,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 576.3755721981296,
      life: 8000,
      speedUp: 20,
      speedRight: 2.2278672437175118,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 744.3598533116817,
      life: 8000,
      speedUp: 20,
      speedRight: 7.566499897677614,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 397.18250972947476,
      life: 8000,
      speedUp: 20,
      speedRight: 4.852457037066009,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 408.3067560628159,
      life: 8000,
      speedUp: 20,
      speedRight: 15.660935611227668,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: 395.3691681305262,
      life: 8000,
      speedUp: 20,
      speedRight: 17.914604160325823,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 200,
      left: -146.73716746563352,
      life: 8000,
      speedUp: 20,
      speedRight: 0.48707037926208674,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 40.84,
      left: -246.65831307617788,
      life: 42,
      speedUp: 20,
      speedRight: 7.1564955250391105,
      startOpacity: 0.005249999999999977,
      size: 279.58,
      newTop: 40.84,
      newLeft: -246.65831307617788,
    },
    {
      top: 41.84,
      left: 533.2314138313023,
      life: 92,
      speedUp: 20,
      speedRight: 14.554727608152058,
      startOpacity: 0.011499999999999955,
      size: 279.08,
      newTop: 41.84,
      newLeft: 533.2314138313023,
    },
    {
      top: 42.84,
      left: 55.98401610781916,
      life: 142,
      speedUp: 20,
      speedRight: 2.7434608187589316,
      startOpacity: 0.017750000000000044,
      size: 278.58,
      newTop: 42.84,
      newLeft: 55.98401610781916,
    },
    {
      top: 43.84,
      left: 341.58049437585584,
      life: 192,
      speedUp: 20,
      speedRight: 6.658246236045469,
      startOpacity: 0.02400000000000002,
      size: 278.08,
      newTop: 43.84,
      newLeft: 341.58049437585584,
    },
    {
      top: 44.84,
      left: 335.56105374155476,
      life: 242,
      speedUp: 20,
      speedRight: 11.72572943655286,
      startOpacity: 0.03025,
      size: 277.58,
      newTop: 44.84,
      newLeft: 335.56105374155476,
    },
    {
      top: 45.84,
      left: 508.6154584413018,
      life: 292,
      speedUp: 20,
      speedRight: 17.718240437240993,
      startOpacity: 0.03649999999999998,
      size: 277.08,
      newTop: 45.84,
      newLeft: 508.6154584413018,
    },
    {
      top: 46.84,
      left: 648.1296787671872,
      life: 342,
      speedUp: 20,
      speedRight: 12.150822968608136,
      startOpacity: 0.042749999999999955,
      size: 276.58,
      newTop: 46.84,
      newLeft: 648.1296787671872,
    },
    {
      top: 47.84,
      left: 672.7331013261465,
      life: 392,
      speedUp: 20,
      speedRight: 15.8222238648883,
      startOpacity: 0.049000000000000044,
      size: 276.08,
      newTop: 47.84,
      newLeft: 672.7331013261465,
    },
    {
      top: 48.84,
      left: 501.76134031220363,
      life: 442,
      speedUp: 20,
      speedRight: 6.32133757280863,
      startOpacity: 0.05525000000000002,
      size: 275.58,
      newTop: 48.84,
      newLeft: 501.76134031220363,
    },
    {
      top: 49.84,
      left: 523.3520393502586,
      life: 492,
      speedUp: 20,
      speedRight: 15.268181012997912,
      startOpacity: 0.0615,
      size: 275.08,
      newTop: 49.84,
      newLeft: 523.3520393502586,
    },
    {
      top: 50.84,
      left: 29.522477742420733,
      life: 542,
      speedUp: 20,
      speedRight: 12.718058104444232,
      startOpacity: 0.06774999999999998,
      size: 274.58,
      newTop: 50.84,
      newLeft: 29.522477742420733,
    },
    {
      top: 51.84,
      left: -209.7310435046701,
      life: 592,
      speedUp: 20,
      speedRight: 12.593827458252393,
      startOpacity: 0.07399999999999995,
      size: 274.08,
      newTop: 51.84,
      newLeft: -209.7310435046701,
    },
    {
      top: 52.84,
      left: -55.33663918253849,
      life: 642,
      speedUp: 20,
      speedRight: 2.7742874439290333,
      startOpacity: 0.08025000000000004,
      size: 273.58,
      newTop: 52.84,
      newLeft: -55.33663918253849,
    },
    {
      top: 53.84,
      left: 204.9355713808324,
      life: 692,
      speedUp: 20,
      speedRight: 2.693830554408665,
      startOpacity: 0.08650000000000002,
      size: 273.08,
      newTop: 53.84,
      newLeft: 204.9355713808324,
    },
    {
      top: 54.84,
      left: -12.005669403153775,
      life: 742,
      speedUp: 20,
      speedRight: 7.998169613111159,
      startOpacity: 0.09275,
      size: 272.58,
      newTop: 54.84,
      newLeft: -12.005669403153775,
    },
    {
      top: 55.84,
      left: 318.6294170845045,
      life: 792,
      speedUp: 20,
      speedRight: 3.729440592948956,
      startOpacity: 0.09899999999999998,
      size: 272.08,
      newTop: 55.84,
      newLeft: 318.6294170845045,
    },
    {
      top: 56.84,
      left: 639.827930932056,
      life: 842,
      speedUp: 20,
      speedRight: 2.799589189568956,
      startOpacity: 0.10524999999999995,
      size: 271.58,
      newTop: 56.84,
      newLeft: 639.827930932056,
    },
    {
      top: 57.84,
      left: -154.8954229809382,
      life: 892,
      speedUp: 20,
      speedRight: 4.056944873507984,
      startOpacity: 0.11150000000000004,
      size: 271.08,
      newTop: 57.84,
      newLeft: -154.8954229809382,
    },
    {
      top: 58.84,
      left: 616.2047489634194,
      life: 942,
      speedUp: 20,
      speedRight: 14.757801039048012,
      startOpacity: 0.11775000000000002,
      size: 270.58,
      newTop: 58.84,
      newLeft: 616.2047489634194,
    },
    {
      top: 59.81999999999999,
      left: 544.4182040047097,
      life: 991,
      speedUp: 20,
      speedRight: 18.793741875700583,
      startOpacity: 0.12387499999999996,
      size: 270.09000000000003,
      newTop: 59.81999999999999,
      newLeft: 544.4182040047097,
    },
    {
      top: 60.81999999999999,
      left: 621.9380789023475,
      life: 1041,
      speedUp: 20,
      speedRight: 18.761536958540276,
      startOpacity: 0.13012500000000005,
      size: 269.59000000000003,
      newTop: 60.81999999999999,
      newLeft: 621.9380789023475,
    },
    {
      top: 61.81999999999999,
      left: -62.08114608892346,
      life: 1091,
      speedUp: 20,
      speedRight: 12.833987310386066,
      startOpacity: 0.13637500000000002,
      size: 269.09000000000003,
      newTop: 61.81999999999999,
      newLeft: -62.08114608892346,
    },
    {
      top: 62.81999999999999,
      left: 344.908017600217,
      life: 1141,
      speedUp: 20,
      speedRight: 11.313659360543245,
      startOpacity: 0.142625,
      size: 268.59000000000003,
      newTop: 62.81999999999999,
      newLeft: 344.908017600217,
    },
    {
      top: 63.81999999999999,
      left: 620.1213667070064,
      life: 1191,
      speedUp: 20,
      speedRight: 19.197708573247265,
      startOpacity: 0.14887499999999998,
      size: 268.09000000000003,
      newTop: 63.81999999999999,
      newLeft: 620.1213667070064,
    },
    {
      top: 64.82,
      left: 271.62024831893734,
      life: 1241,
      speedUp: 20,
      speedRight: 6.755812537699435,
      startOpacity: 0.15512499999999996,
      size: 267.59000000000003,
      newTop: 64.82,
      newLeft: 271.62024831893734,
    },
    {
      top: 65.82,
      left: 160.7941190853846,
      life: 1291,
      speedUp: 20,
      speedRight: 4.725394384001351,
      startOpacity: 0.16137500000000005,
      size: 267.09000000000003,
      newTop: 65.82,
      newLeft: 160.7941190853846,
    },
    {
      top: 66.82,
      left: -156.8532369888262,
      life: 1341,
      speedUp: 20,
      speedRight: 15.540337256360836,
      startOpacity: 0.16762500000000002,
      size: 266.59000000000003,
      newTop: 66.82,
      newLeft: -156.8532369888262,
    },
    {
      top: 67.82,
      left: -233.25523778200986,
      life: 1391,
      speedUp: 20,
      speedRight: 10.59095926689469,
      startOpacity: 0.173875,
      size: 266.09000000000003,
      newTop: 67.82,
      newLeft: -233.25523778200986,
    },
    {
      top: 68.82,
      left: -113.15727915324722,
      life: 1441,
      speedUp: 20,
      speedRight: 3.32308462671548,
      startOpacity: 0.18012499999999998,
      size: 265.59000000000003,
      newTop: 68.82,
      newLeft: -113.15727915324722,
    },
    {
      top: 69.82,
      left: -101.88453255486809,
      life: 1491,
      speedUp: 20,
      speedRight: 17.91952126825615,
      startOpacity: 0.18637499999999996,
      size: 265.09000000000003,
      newTop: 69.82,
      newLeft: -101.88453255486809,
    },
    {
      top: 70.82,
      left: 549.1814182645155,
      life: 1541,
      speedUp: 20,
      speedRight: 3.8651539575082605,
      startOpacity: 0.19262500000000005,
      size: 264.59000000000003,
      newTop: 70.82,
      newLeft: 549.1814182645155,
    },
    {
      top: 71.82,
      left: 176.18010626902054,
      life: 1591,
      speedUp: 20,
      speedRight: 16.310135374790654,
      startOpacity: 0.19887500000000002,
      size: 264.09000000000003,
      newTop: 71.82,
      newLeft: 176.18010626902054,
    },
    {
      top: 72.82,
      left: 621.0027198233761,
      life: 1641,
      speedUp: 20,
      speedRight: 8.170672423836574,
      startOpacity: 0.205125,
      size: 263.59000000000003,
      newTop: 72.82,
      newLeft: 621.0027198233761,
    },
    {
      top: 73.82,
      left: 119.21787920051385,
      life: 1691,
      speedUp: 20,
      speedRight: 18.982584272140592,
      startOpacity: 0.21137499999999998,
      size: 263.09000000000003,
      newTop: 73.82,
      newLeft: 119.21787920051385,
    },
    {
      top: 74.82,
      left: -196.58352378746028,
      life: 1741,
      speedUp: 20,
      speedRight: 8.63765614818416,
      startOpacity: 0.21762499999999996,
      size: 262.59000000000003,
      newTop: 74.82,
      newLeft: -196.58352378746028,
    },
    {
      top: 75.82000000000001,
      left: -113.01809641134864,
      life: 1791,
      speedUp: 20,
      speedRight: 15.352121634833601,
      startOpacity: 0.22387500000000005,
      size: 262.09,
      newTop: 75.82000000000001,
      newLeft: -113.01809641134864,
    },
    {
      top: 76.82000000000001,
      left: 591.967363887584,
      life: 1841,
      speedUp: 20,
      speedRight: 13.670150136739823,
      startOpacity: 0.23012500000000002,
      size: 261.59,
      newTop: 76.82000000000001,
      newLeft: 591.967363887584,
    },
    {
      top: 77.82,
      left: 356.3015627726346,
      life: 1891,
      speedUp: 20,
      speedRight: 6.287086966807918,
      startOpacity: 0.236375,
      size: 261.09000000000003,
      newTop: 77.82,
      newLeft: 356.3015627726346,
    },
    {
      top: 78.82,
      left: 263.45498227047483,
      life: 1941,
      speedUp: 20,
      speedRight: 9.03600371885763,
      startOpacity: 0.24262499999999998,
      size: 260.59000000000003,
      newTop: 78.82,
      newLeft: 263.45498227047483,
    },
    {
      top: 79.82,
      left: -23.905637510382082,
      life: 1991,
      speedUp: 20,
      speedRight: 5.285186816266432,
      startOpacity: 0.24887499999999996,
      size: 260.09000000000003,
      newTop: 79.82,
      newLeft: -23.905637510382082,
    },
    {
      top: 80.82000000000001,
      left: 559.0008972185177,
      life: 2041,
      speedUp: 20,
      speedRight: 11.270542150405603,
      startOpacity: 0.25512500000000005,
      size: 259.59,
      newTop: 80.82000000000001,
      newLeft: 559.0008972185177,
    },
    {
      top: 81.82000000000001,
      left: 174.65515366031738,
      life: 2091,
      speedUp: 20,
      speedRight: 2.9305620640553753,
      startOpacity: 0.261375,
      size: 259.09,
      newTop: 81.82000000000001,
      newLeft: 174.65515366031738,
    },
    {
      top: 82.82,
      left: 265.13975323421346,
      life: 2141,
      speedUp: 20,
      speedRight: 9.191581802180199,
      startOpacity: 0.267625,
      size: 258.59000000000003,
      newTop: 82.82,
      newLeft: 265.13975323421346,
    },
    {
      top: 83.82,
      left: 408.53632685269685,
      life: 2191,
      speedUp: 20,
      speedRight: 1.4723491159231417,
      startOpacity: 0.273875,
      size: 258.09000000000003,
      newTop: 83.82,
      newLeft: 408.53632685269685,
    },
    {
      top: 84.82,
      left: 538.613369544892,
      life: 2241,
      speedUp: 20,
      speedRight: 5.776698591602547,
      startOpacity: 0.28012499999999996,
      size: 257.59000000000003,
      newTop: 84.82,
      newLeft: 538.613369544892,
    },
    {
      top: 85.82000000000001,
      left: 21.6288544909751,
      life: 2291,
      speedUp: 20,
      speedRight: 2.04478122660694,
      startOpacity: 0.28637500000000005,
      size: 257.09,
      newTop: 85.82000000000001,
      newLeft: 21.6288544909751,
    },
    {
      top: 86.82000000000001,
      left: -33.11823512208282,
      life: 2341,
      speedUp: 20,
      speedRight: 13.97285839545896,
      startOpacity: 0.292625,
      size: 256.59,
      newTop: 86.82000000000001,
      newLeft: -33.11823512208282,
    },
    {
      top: 87.82,
      left: 346.5647247006276,
      life: 2391,
      speedUp: 20,
      speedRight: 18.258721803397123,
      startOpacity: 0.298875,
      size: 256.09000000000003,
      newTop: 87.82,
      newLeft: 346.5647247006276,
    },
    {
      top: 88.82,
      left: 373.67088805245476,
      life: 2441,
      speedUp: 20,
      speedRight: 10.124334780481927,
      startOpacity: 0.305125,
      size: 255.59,
      newTop: 88.82,
      newLeft: 373.67088805245476,
    },
    {
      top: 89.82,
      left: 167.91732407685518,
      life: 2491,
      speedUp: 20,
      speedRight: 18.00028100969456,
      startOpacity: 0.31137499999999996,
      size: 255.09,
      newTop: 89.82,
      newLeft: 167.91732407685518,
    },
    {
      top: 90.82000000000001,
      left: 159.67387143614152,
      life: 2541,
      speedUp: 20,
      speedRight: 5.069655828788333,
      startOpacity: 0.31762500000000005,
      size: 254.59,
      newTop: 90.82000000000001,
      newLeft: 159.67387143614152,
    },
    {
      top: 91.82000000000001,
      left: 453.9953278904199,
      life: 2591,
      speedUp: 20,
      speedRight: 11.556714516105941,
      startOpacity: 0.323875,
      size: 254.09,
      newTop: 91.82000000000001,
      newLeft: 453.9953278904199,
    },
    {
      top: 92.82,
      left: 83.47507020666067,
      life: 2641,
      speedUp: 20,
      speedRight: 18.92413619323419,
      startOpacity: 0.330125,
      size: 253.59,
      newTop: 92.82,
      newLeft: 83.47507020666067,
    },
    {
      top: 93.82,
      left: 38.92477677765615,
      life: 2691,
      speedUp: 20,
      speedRight: 1.6801210566035607,
      startOpacity: 0.336375,
      size: 253.09,
      newTop: 93.82,
      newLeft: 38.92477677765615,
    },
    {
      top: 94.82,
      left: 709.1653695849926,
      life: 2741,
      speedUp: 20,
      speedRight: 7.654804060841007,
      startOpacity: 0.34262499999999996,
      size: 252.59,
      newTop: 94.82,
      newLeft: 709.1653695849926,
    },
    {
      top: 95.82000000000001,
      left: 453.8719275503853,
      life: 2791,
      speedUp: 20,
      speedRight: 5.509921403165934,
      startOpacity: 0.34887500000000005,
      size: 252.09,
      newTop: 95.82000000000001,
      newLeft: 453.8719275503853,
    },
    {
      top: 96.82000000000001,
      left: -211.73104407949336,
      life: 2841,
      speedUp: 20,
      speedRight: 3.558510343723289,
      startOpacity: 0.355125,
      size: 251.59,
      newTop: 96.82000000000001,
      newLeft: -211.73104407949336,
    },
    {
      top: 97.82,
      left: -175.67147671686848,
      life: 2891,
      speedUp: 20,
      speedRight: 14.868909217847165,
      startOpacity: 0.361375,
      size: 251.09,
      newTop: 97.82,
      newLeft: -175.67147671686848,
    },
    {
      top: 98.82,
      left: 149.12071541093124,
      life: 2941,
      speedUp: 20,
      speedRight: 10.450192368357193,
      startOpacity: 0.367625,
      size: 250.59,
      newTop: 98.82,
      newLeft: 149.12071541093124,
    },
    {
      top: 99.82,
      left: 240.36519186418275,
      life: 2991,
      speedUp: 20,
      speedRight: 16.982562189292345,
      startOpacity: 0.37387499999999996,
      size: 250.09,
      newTop: 99.82,
      newLeft: 240.36519186418275,
    },
    {
      top: 100.82000000000001,
      left: 75.87285701464648,
      life: 3041,
      speedUp: 20,
      speedRight: 19.885276483475142,
      startOpacity: 0.38012500000000005,
      size: 249.59,
      newTop: 100.82000000000001,
      newLeft: 75.87285701464648,
    },
    {
      top: 101.82000000000001,
      left: 194.86290346119705,
      life: 3091,
      speedUp: 20,
      speedRight: 13.712194332525112,
      startOpacity: 0.386375,
      size: 249.09,
      newTop: 101.82000000000001,
      newLeft: 194.86290346119705,
    },
    {
      top: 102.82,
      left: 543.3654329476475,
      life: 3141,
      speedUp: 20,
      speedRight: 7.216489805064481,
      startOpacity: 0.392625,
      size: 248.59,
      newTop: 102.82,
      newLeft: 543.3654329476475,
    },
    {
      top: 103.82,
      left: 568.8781391547866,
      life: 3191,
      speedUp: 20,
      speedRight: 2.7404754560003575,
      startOpacity: 0.398875,
      size: 248.09,
      newTop: 103.82,
      newLeft: 568.8781391547866,
    },
    {
      top: 104.82,
      left: -217.7229397512101,
      life: 3241,
      speedUp: 20,
      speedRight: 11.008152134440934,
      startOpacity: 0.40512499999999996,
      size: 247.59,
      newTop: 104.82,
      newLeft: -217.7229397512101,
    },
    {
      top: 105.82000000000001,
      left: 733.8146900258831,
      life: 3291,
      speedUp: 20,
      speedRight: 3.614886568718556,
      startOpacity: 0.41137500000000005,
      size: 247.09,
      newTop: 105.82000000000001,
      newLeft: 733.8146900258831,
    },
    {
      top: 106.82000000000001,
      left: 620.6248114865491,
      life: 3341,
      speedUp: 20,
      speedRight: 18.774998694930147,
      startOpacity: 0.417625,
      size: 246.59,
      newTop: 106.82000000000001,
      newLeft: 620.6248114865491,
    },
    {
      top: 107.82,
      left: 695.6238197204797,
      life: 3391,
      speedUp: 20,
      speedRight: 15.450310130255769,
      startOpacity: 0.423875,
      size: 246.09,
      newTop: 107.82,
      newLeft: 695.6238197204797,
    },
    {
      top: 108.82,
      left: 770.2869363696714,
      life: 3441,
      speedUp: 20,
      speedRight: 0.690625199625603,
      startOpacity: 0.430125,
      size: 245.59,
      newTop: 108.82,
      newLeft: 770.2869363696714,
    },
    {
      top: 109.82,
      left: 248.83143096031378,
      life: 3491,
      speedUp: 20,
      speedRight: 2.396362757496746,
      startOpacity: 0.43637499999999996,
      size: 245.09,
      newTop: 109.82,
      newLeft: 248.83143096031378,
    },
    {
      top: 110.82000000000001,
      left: 625.2148686023629,
      life: 3541,
      speedUp: 20,
      speedRight: 10.781868204867857,
      startOpacity: 0.44262500000000005,
      size: 244.59,
      newTop: 110.82000000000001,
      newLeft: 625.2148686023629,
    },
    {
      top: 111.82000000000001,
      left: 249.4919363337474,
      life: 3591,
      speedUp: 20,
      speedRight: 4.041800034450134,
      startOpacity: 0.448875,
      size: 244.09,
      newTop: 111.82000000000001,
      newLeft: 249.4919363337474,
    },
    {
      top: 112.84,
      left: -4.5787056853925945,
      life: 3642,
      speedUp: 20,
      speedRight: 1.4753677225541129,
      startOpacity: 0.45525000000000004,
      size: 243.57999999999998,
      newTop: 112.84,
      newLeft: -4.5787056853925945,
    },
    {
      top: 113.84,
      left: 549.8112461063864,
      life: 3692,
      speedUp: 20,
      speedRight: 11.934662528681589,
      startOpacity: 0.4615,
      size: 243.07999999999998,
      newTop: 113.84,
      newLeft: 549.8112461063864,
    },
    {
      top: 114.84,
      left: 675.5988992066649,
      life: 3742,
      speedUp: 20,
      speedRight: 0.38752195094432285,
      startOpacity: 0.46775,
      size: 242.57999999999998,
      newTop: 114.84,
      newLeft: 675.5988992066649,
    },
    {
      top: 115.84,
      left: 499.02202087142064,
      life: 3792,
      speedUp: 20,
      speedRight: 3.357254495948103,
      startOpacity: 0.474,
      size: 242.07999999999998,
      newTop: 115.84,
      newLeft: 499.02202087142064,
    },
    {
      top: 116.83999999999999,
      left: 202.9451171100095,
      life: 3842,
      speedUp: 20,
      speedRight: 15.309485563009613,
      startOpacity: 0.48024999999999995,
      size: 241.58,
      newTop: 116.83999999999999,
      newLeft: 202.9451171100095,
    },
    {
      top: 117.84,
      left: 625.8661205755883,
      life: 3892,
      speedUp: 20,
      speedRight: 11.999043770271097,
      startOpacity: 0.48650000000000004,
      size: 241.07999999999998,
      newTop: 117.84,
      newLeft: 625.8661205755883,
    },
    {
      top: 118.84,
      left: 345.8420441032325,
      life: 3942,
      speedUp: 20,
      speedRight: 7.162574092691254,
      startOpacity: 0.49275,
      size: 240.57999999999998,
      newTop: 118.84,
      newLeft: 345.8420441032325,
    },
    {
      top: 119.84,
      left: 658.0752345904789,
      life: 3992,
      speedUp: 20,
      speedRight: 17.64328323067009,
      startOpacity: 0.499,
      size: 240.07999999999998,
      newTop: 119.84,
      newLeft: 658.0752345904789,
    },
    {
      top: 120.84,
      left: 638.0932478718676,
      life: 4042,
      speedUp: 20,
      speedRight: 16.632051042437826,
      startOpacity: 0.50525,
      size: 239.57999999999998,
      newTop: 120.84,
      newLeft: 638.0932478718676,
    },
    {
      top: 121.84,
      left: 215.27221909789566,
      life: 4092,
      speedUp: 20,
      speedRight: 8.266114739000386,
      startOpacity: 0.5115000000000001,
      size: 239.07999999999998,
      newTop: 121.84,
      newLeft: 215.27221909789566,
    },
    {
      top: 122.84,
      left: 10.48639525916569,
      life: 4142,
      speedUp: 20,
      speedRight: 6.475842068230273,
      startOpacity: 0.5177499999999999,
      size: 238.57999999999998,
      newTop: 122.84,
      newLeft: 10.48639525916569,
    },
    {
      top: 123.84,
      left: -211.54240392129915,
      life: 4192,
      speedUp: 20,
      speedRight: 3.235653599175703,
      startOpacity: 0.524,
      size: 238.07999999999998,
      newTop: 123.84,
      newLeft: -211.54240392129915,
    },
    {
      top: 124.82000000000001,
      left: 594.1744435511256,
      life: 4241,
      speedUp: 20,
      speedRight: 8.438929524407556,
      startOpacity: 0.530125,
      size: 237.59,
      newTop: 124.82000000000001,
      newLeft: 594.1744435511256,
    },
    {
      top: 125.82,
      left: -5.802609852313267,
      life: 4291,
      speedUp: 20,
      speedRight: 10.734805749199321,
      startOpacity: 0.536375,
      size: 237.09,
      newTop: 125.82,
      newLeft: -5.802609852313267,
    },
    {
      top: 126.82000000000001,
      left: 391.1744637508487,
      life: 4341,
      speedUp: 20,
      speedRight: 3.1719094113808177,
      startOpacity: 0.542625,
      size: 236.59,
      newTop: 126.82000000000001,
      newLeft: 391.1744637508487,
    },
    {
      top: 127.82,
      left: 100.40093641162063,
      life: 4391,
      speedUp: 20,
      speedRight: 13.740620304604722,
      startOpacity: 0.548875,
      size: 236.09,
      newTop: 127.82,
      newLeft: 100.40093641162063,
    },
    {
      top: 128.82,
      left: 736.9243593921504,
      life: 4441,
      speedUp: 20,
      speedRight: 7.381691998206632,
      startOpacity: 0.555125,
      size: 235.59,
      newTop: 128.82,
      newLeft: 736.9243593921504,
    },
    {
      top: 129.82,
      left: 183.3003742109991,
      life: 4491,
      speedUp: 20,
      speedRight: 16.256568420928264,
      startOpacity: 0.561375,
      size: 235.09,
      newTop: 129.82,
      newLeft: 183.3003742109991,
    },
    {
      top: 130.82,
      left: 9.613775934096935,
      life: 4541,
      speedUp: 20,
      speedRight: 15.252278596367868,
      startOpacity: 0.567625,
      size: 234.59,
      newTop: 130.82,
      newLeft: 9.613775934096935,
    },
    {
      top: 131.82,
      left: -238.09517835597174,
      life: 4591,
      speedUp: 20,
      speedRight: 12.28359310857186,
      startOpacity: 0.573875,
      size: 234.09,
      newTop: 131.82,
      newLeft: -238.09517835597174,
    },
    {
      top: 132.82,
      left: 532.7816368013944,
      life: 4641,
      speedUp: 20,
      speedRight: 12.960326656546215,
      startOpacity: 0.580125,
      size: 233.59,
      newTop: 132.82,
      newLeft: 532.7816368013944,
    },
    {
      top: 133.82,
      left: 133.9801589485702,
      life: 4691,
      speedUp: 20,
      speedRight: 6.555163087857023,
      startOpacity: 0.586375,
      size: 233.09,
      newTop: 133.82,
      newLeft: 133.9801589485702,
    },
    {
      top: 134.82,
      left: -97.96158177142769,
      life: 4741,
      speedUp: 20,
      speedRight: 6.618211501841724,
      startOpacity: 0.592625,
      size: 232.59,
      newTop: 134.82,
      newLeft: -97.96158177142769,
    },
    {
      top: 135.82,
      left: -255.83469745284378,
      life: 4791,
      speedUp: 20,
      speedRight: 18.06768107686421,
      startOpacity: 0.598875,
      size: 232.09,
      newTop: 135.82,
      newLeft: -255.83469745284378,
    },
    {
      top: 136.82,
      left: 547.0852846270079,
      life: 4841,
      speedUp: 20,
      speedRight: 16.25874991759977,
      startOpacity: 0.605125,
      size: 231.59,
      newTop: 136.82,
      newLeft: 547.0852846270079,
    },
    {
      top: 137.82,
      left: 691.5615176935337,
      life: 4891,
      speedUp: 20,
      speedRight: 3.4220197531765617,
      startOpacity: 0.611375,
      size: 231.09,
      newTop: 137.82,
      newLeft: 691.5615176935337,
    },
    {
      top: 138.82,
      left: 355.28934724767413,
      life: 4941,
      speedUp: 20,
      speedRight: 14.804229876492565,
      startOpacity: 0.617625,
      size: 230.59,
      newTop: 138.82,
      newLeft: 355.28934724767413,
    },
  ],
  large: [
    {
      top: 182.38,
      left: 292.26866516231496,
      life: 1119,
      speedUp: 20,
      speedRight: 17.60253336202194,
      startOpacity: 0.09325000000000006,
      size: 308.81,
      newTop: 182.38,
      newLeft: 292.26866516231496,
    },
    {
      top: 184.38,
      left: -161.53818738717882,
      life: 1219,
      speedUp: 20,
      speedRight: 7.6446116779059015,
      startOpacity: 0.10158333333333336,
      size: 307.81,
      newTop: 184.38,
      newLeft: -161.53818738717882,
    },
    {
      top: 186.35999999999999,
      left: 196.11764373241738,
      life: 1318,
      speedUp: 20,
      speedRight: 5.314673852418195,
      startOpacity: 0.10983333333333334,
      size: 306.82,
      newTop: 186.35999999999999,
      newLeft: 196.11764373241738,
    },
    {
      top: 188.35999999999999,
      left: 352.3229490733893,
      life: 1418,
      speedUp: 20,
      speedRight: 3.657489023783005,
      startOpacity: 0.11816666666666664,
      size: 305.82,
      newTop: 188.35999999999999,
      newLeft: 352.3229490733893,
    },
    {
      top: 190.33999999999997,
      left: 563.9927235614012,
      life: 1517,
      speedUp: 20,
      speedRight: 7.165764793553362,
      startOpacity: 0.12641666666666662,
      size: 304.83000000000004,
      newTop: 190.33999999999997,
      newLeft: 563.9927235614012,
    },
    {
      top: 192.34000000000003,
      left: 362.4444245282307,
      life: 1617,
      speedUp: 20,
      speedRight: 13.778694463620585,
      startOpacity: 0.13475000000000004,
      size: 303.83,
      newTop: 192.34000000000003,
      newLeft: 362.4444245282307,
    },
    {
      top: 194.38,
      left: -98.419387770917,
      life: 1719,
      speedUp: 20,
      speedRight: 9.774106019703286,
      startOpacity: 0.14325,
      size: 302.81,
      newTop: 194.38,
      newLeft: -98.419387770917,
    },
    {
      top: 196.34,
      left: -26.906976387454073,
      life: 1817,
      speedUp: 20,
      speedRight: 3.1459502272741924,
      startOpacity: 0.15141666666666664,
      size: 301.83,
      newTop: 196.34,
      newLeft: -26.906976387454073,
    },
    {
      top: 198.34,
      left: -0.2047695548198476,
      life: 1917,
      speedUp: 20,
      speedRight: 9.342181862047966,
      startOpacity: 0.15974999999999995,
      size: 300.83,
      newTop: 198.34,
      newLeft: -0.2047695548198476,
    },
    {
      top: 200.33999999999997,
      left: 611.771063312989,
      life: 2017,
      speedUp: 20,
      speedRight: 1.6540321246175171,
      startOpacity: 0.16808333333333336,
      size: 299.83000000000004,
      newTop: 200.33999999999997,
      newLeft: 611.771063312989,
    },
    {
      top: 202.34000000000003,
      left: 381.2939251546462,
      life: 2117,
      speedUp: 20,
      speedRight: 14.632703911635193,
      startOpacity: 0.17641666666666667,
      size: 298.83,
      newTop: 202.34000000000003,
      newLeft: 381.2939251546462,
    },
    {
      top: 204.36,
      left: 492.19416903602195,
      life: 2218,
      speedUp: 20,
      speedRight: 5.425145906229951,
      startOpacity: 0.1848333333333333,
      size: 297.82,
      newTop: 204.36,
      newLeft: 492.19416903602195,
    },
    {
      top: 206.34,
      left: 124.70911864029172,
      life: 2317,
      speedUp: 20,
      speedRight: 9.591627259375786,
      startOpacity: 0.19308333333333338,
      size: 296.83,
      newTop: 206.34,
      newLeft: 124.70911864029172,
    },
    {
      top: 208.35999999999999,
      left: 223.94790133410567,
      life: 2418,
      speedUp: 20,
      speedRight: 14.83726070658233,
      startOpacity: 0.2015,
      size: 295.82,
      newTop: 208.35999999999999,
      newLeft: 223.94790133410567,
    },
    {
      top: 210.33999999999997,
      left: 550.0660021122364,
      life: 2517,
      speedUp: 20,
      speedRight: 4.894469890650157,
      startOpacity: 0.20975,
      size: 294.83000000000004,
      newTop: 210.33999999999997,
      newLeft: 550.0660021122364,
    },
    {
      top: 212.34000000000003,
      left: -258.1559543216517,
      life: 2617,
      speedUp: 20,
      speedRight: 9.978617825061328,
      startOpacity: 0.2180833333333333,
      size: 293.83,
      newTop: 212.34000000000003,
      newLeft: -258.1559543216517,
    },
    {
      top: 214.36,
      left: -60.714380241755904,
      life: 2718,
      speedUp: 20,
      speedRight: 0.8988678586170495,
      startOpacity: 0.22650000000000003,
      size: 292.82,
      newTop: 214.36,
      newLeft: -60.714380241755904,
    },
    {
      top: 216.35999999999999,
      left: -155.7208609864211,
      life: 2818,
      speedUp: 20,
      speedRight: 7.6976456562576345,
      startOpacity: 0.23483333333333334,
      size: 291.82,
      newTop: 216.35999999999999,
      newLeft: -155.7208609864211,
    },
    {
      top: 218.35999999999999,
      left: 332.2792856520674,
      life: 2918,
      speedUp: 20,
      speedRight: 1.2647493868863613,
      startOpacity: 0.24316666666666664,
      size: 290.82,
      newTop: 218.35999999999999,
      newLeft: 332.2792856520674,
    },
    {
      top: 220.33999999999997,
      left: 60.75018523132157,
      life: 3017,
      speedUp: 20,
      speedRight: 12.28727229190882,
      startOpacity: 0.2514166666666666,
      size: 289.83000000000004,
      newTop: 220.33999999999997,
      newLeft: 60.75018523132157,
    },
    {
      top: 222.36,
      left: 583.1132321328736,
      life: 3118,
      speedUp: 20,
      speedRight: 4.519680061360067,
      startOpacity: 0.25983333333333336,
      size: 288.82,
      newTop: 222.36,
      newLeft: 583.1132321328736,
    },
    {
      top: 224.34,
      left: 479.110034738515,
      life: 3217,
      speedUp: 20,
      speedRight: 15.174795640888084,
      startOpacity: 0.26808333333333334,
      size: 287.83,
      newTop: 224.34,
      newLeft: 479.110034738515,
    },
    {
      top: 226.35999999999999,
      left: 154.97142829128035,
      life: 3318,
      speedUp: 20,
      speedRight: 7.996267567711066,
      startOpacity: 0.27649999999999997,
      size: 286.82,
      newTop: 226.35999999999999,
      newLeft: 154.97142829128035,
    },
    {
      top: 228.35999999999999,
      left: 436.8709022823193,
      life: 3418,
      speedUp: 20,
      speedRight: 14.330564786439393,
      startOpacity: 0.2848333333333334,
      size: 285.82,
      newTop: 228.35999999999999,
      newLeft: 436.8709022823193,
    },
    {
      top: 230.33999999999997,
      left: 687.0279582275798,
      life: 3517,
      speedUp: 20,
      speedRight: 1.1565481405080469,
      startOpacity: 0.29308333333333336,
      size: 284.83000000000004,
      newTop: 230.33999999999997,
      newLeft: 687.0279582275798,
    },
    {
      top: 232.36,
      left: 596.9165804947523,
      life: 3618,
      speedUp: 20,
      speedRight: 8.251245267754875,
      startOpacity: 0.3015,
      size: 283.82,
      newTop: 232.36,
      newLeft: 596.9165804947523,
    },
    {
      top: 234.36,
      left: -354.0811439657966,
      life: 3718,
      speedUp: 20,
      speedRight: 19.810047663658267,
      startOpacity: 0.3098333333333333,
      size: 282.82,
      newTop: 234.36,
      newLeft: -354.0811439657966,
    },
    {
      top: 236.34,
      left: 330.97478166052775,
      life: 3817,
      speedUp: 20,
      speedRight: 2.6696574846630527,
      startOpacity: 0.3180833333333334,
      size: 281.83,
      newTop: 236.34,
      newLeft: 330.97478166052775,
    },
    {
      top: 238.35999999999999,
      left: -80.10732415335114,
      life: 3918,
      speedUp: 20,
      speedRight: 7.15217061682472,
      startOpacity: 0.3265,
      size: 280.82,
      newTop: 238.35999999999999,
      newLeft: -80.10732415335114,
    },
    {
      top: 240.35999999999999,
      left: 491.26532943922825,
      life: 4018,
      speedUp: 20,
      speedRight: 12.047353476914719,
      startOpacity: 0.3348333333333333,
      size: 279.82,
      newTop: 240.35999999999999,
      newLeft: 491.26532943922825,
    },
    {
      top: 242.36,
      left: 219.1419701957061,
      life: 4118,
      speedUp: 20,
      speedRight: 0.497629873764609,
      startOpacity: 0.3431666666666666,
      size: 278.82,
      newTop: 242.36,
      newLeft: 219.1419701957061,
    },
    {
      top: 244.36,
      left: 380.86937110078077,
      life: 4218,
      speedUp: 20,
      speedRight: 12.157656119558066,
      startOpacity: 0.35150000000000003,
      size: 277.82,
      newTop: 244.36,
      newLeft: 380.86937110078077,
    },
    {
      top: 246.34,
      left: -56.86052543232296,
      life: 4317,
      speedUp: 20,
      speedRight: 15.882763340984019,
      startOpacity: 0.35975,
      size: 276.83,
      newTop: 246.34,
      newLeft: -56.86052543232296,
    },
    {
      top: 248.36,
      left: 438.6317852515478,
      life: 4418,
      speedUp: 20,
      speedRight: 9.61150562232219,
      startOpacity: 0.36816666666666664,
      size: 275.82,
      newTop: 248.36,
      newLeft: 438.6317852515478,
    },
    {
      top: 250.34,
      left: 298.5632665877895,
      life: 4517,
      speedUp: 20,
      speedRight: 6.116546252117212,
      startOpacity: 0.3764166666666666,
      size: 274.83,
      newTop: 250.34,
      newLeft: 298.5632665877895,
    },
    {
      top: 252.36,
      left: -122.47150497248063,
      life: 4618,
      speedUp: 20,
      speedRight: 9.875381549103714,
      startOpacity: 0.38483333333333336,
      size: 273.82,
      newTop: 252.36,
      newLeft: -122.47150497248063,
    },
    {
      top: 254.36,
      left: 640.1905615365803,
      life: 4718,
      speedUp: 20,
      speedRight: 8.902502089901589,
      startOpacity: 0.39316666666666666,
      size: 272.82,
      newTop: 254.36,
      newLeft: 640.1905615365803,
    },
    {
      top: 256.34000000000003,
      left: 437.5419362911351,
      life: 4817,
      speedUp: 20,
      speedRight: 9.965164812568098,
      startOpacity: 0.40141666666666664,
      size: 271.83,
      newTop: 256.34000000000003,
      newLeft: 437.5419362911351,
    },
    {
      top: 258.36,
      left: 581.149373448693,
      life: 4918,
      speedUp: 20,
      speedRight: 2.154039033050661,
      startOpacity: 0.4098333333333334,
      size: 270.82,
      newTop: 258.36,
      newLeft: 581.149373448693,
    },
    {
      top: 260.36,
      left: 436.5954265232303,
      life: 5018,
      speedUp: 20,
      speedRight: 3.1766694403051465,
      startOpacity: 0.4181666666666667,
      size: 269.82,
      newTop: 260.36,
      newLeft: 436.5954265232303,
    },
    {
      top: 262.36,
      left: 234.29988588415742,
      life: 5118,
      speedUp: 20,
      speedRight: 5.871113943020956,
      startOpacity: 0.4265,
      size: 268.82,
      newTop: 262.36,
      newLeft: 234.29988588415742,
    },
    {
      top: 264.36,
      left: 159.3457536019645,
      life: 5218,
      speedUp: 20,
      speedRight: 2.7091230749530304,
      startOpacity: 0.4348333333333333,
      size: 267.82,
      newTop: 264.36,
      newLeft: 159.3457536019645,
    },
    {
      top: 266.36,
      left: 345.2927337746902,
      life: 5318,
      speedUp: 20,
      speedRight: 3.653358342681332,
      startOpacity: 0.4431666666666667,
      size: 266.82,
      newTop: 266.36,
      newLeft: 345.2927337746902,
    },
    {
      top: 268.36,
      left: -103.57219501239621,
      life: 5418,
      speedUp: 20,
      speedRight: 0.14752357263579707,
      startOpacity: 0.4515,
      size: 265.82,
      newTop: 268.36,
      newLeft: -103.57219501239621,
    },
    {
      top: 270.36,
      left: 206.0134084295001,
      life: 5518,
      speedUp: 20,
      speedRight: 2.6985250657353044,
      startOpacity: 0.4598333333333333,
      size: 264.82,
      newTop: 270.36,
      newLeft: 206.0134084295001,
    },
    {
      top: 272.36,
      left: -213.87561987467757,
      life: 5618,
      speedUp: 20,
      speedRight: 10.396034844735325,
      startOpacity: 0.4681666666666666,
      size: 263.82,
      newTop: 272.36,
      newLeft: -213.87561987467757,
    },
    {
      top: 274.34,
      left: 46.40259616935805,
      life: 5717,
      speedUp: 20,
      speedRight: 1.2805725617542896,
      startOpacity: 0.4764166666666667,
      size: 262.83,
      newTop: 274.34,
      newLeft: 46.40259616935805,
    },
    {
      top: 276.36,
      left: 125.95914787989403,
      life: 5818,
      speedUp: 20,
      speedRight: 12.811535759224256,
      startOpacity: 0.48483333333333334,
      size: 261.82,
      newTop: 276.36,
      newLeft: 125.95914787989403,
    },
    {
      top: 278.36,
      left: -268.5536322651461,
      life: 5918,
      speedUp: 20,
      speedRight: 11.278739708418847,
      startOpacity: 0.49316666666666664,
      size: 260.82,
      newTop: 278.36,
      newLeft: -268.5536322651461,
    },
    {
      top: 280.54,
      left: 465.867192370594,
      life: 6027,
      speedUp: 20,
      speedRight: 14.154037644747559,
      startOpacity: 0.50225,
      size: 259.73,
      newTop: 280.54,
      newLeft: 465.867192370594,
    },
    {
      top: 282.32,
      left: -256.0298031240956,
      life: 6116,
      speedUp: 20,
      speedRight: 16.99871378684375,
      startOpacity: 0.5096666666666667,
      size: 258.84000000000003,
      newTop: 282.32,
      newLeft: -256.0298031240956,
    },
    {
      top: 284.34,
      left: -154.8801757475644,
      life: 6217,
      speedUp: 20,
      speedRight: 6.489195542962589,
      startOpacity: 0.5180833333333333,
      size: 257.83,
      newTop: 284.34,
      newLeft: -154.8801757475644,
    },
    {
      top: 286.34000000000003,
      left: -205.03679509604518,
      life: 6317,
      speedUp: 20,
      speedRight: 19.359055721960146,
      startOpacity: 0.5264166666666666,
      size: 256.83,
      newTop: 286.34000000000003,
      newLeft: -205.03679509604518,
    },
    {
      top: 288.36,
      left: 290.8189033378968,
      life: 6418,
      speedUp: 20,
      speedRight: 6.6805183674631685,
      startOpacity: 0.5348333333333333,
      size: 255.82,
      newTop: 288.36,
      newLeft: 290.8189033378968,
    },
    {
      top: 290.36,
      left: 405.7270103180002,
      life: 6518,
      speedUp: 20,
      speedRight: 13.481547885363522,
      startOpacity: 0.5431666666666667,
      size: 254.82,
      newTop: 290.36,
      newLeft: 405.7270103180002,
    },
    {
      top: 292.36,
      left: -97.21118390472077,
      life: 6618,
      speedUp: 20,
      speedRight: 3.24247138711919,
      startOpacity: 0.5515,
      size: 253.82,
      newTop: 292.36,
      newLeft: -97.21118390472077,
    },
    {
      top: 294.36,
      left: 210.86074565789914,
      life: 6718,
      speedUp: 20,
      speedRight: 1.7757854131062922,
      startOpacity: 0.5598333333333334,
      size: 252.82,
      newTop: 294.36,
      newLeft: 210.86074565789914,
    },
    {
      top: 296.5,
      left: 17.78301390015765,
      life: 6825,
      speedUp: 20,
      speedRight: 11.405654068942166,
      startOpacity: 0.56875,
      size: 251.75,
      newTop: 296.5,
      newLeft: 17.78301390015765,
    },
    {
      top: 298.36,
      left: 48.429463026734815,
      life: 6918,
      speedUp: 20,
      speedRight: 11.042890681902438,
      startOpacity: 0.5765,
      size: 250.82,
      newTop: 298.36,
      newLeft: 48.429463026734815,
    },
    {
      top: 300.36,
      left: -13.30709270117666,
      life: 7018,
      speedUp: 20,
      speedRight: 0.3776870291378298,
      startOpacity: 0.5848333333333333,
      size: 249.82,
      newTop: 300.36,
      newLeft: -13.30709270117666,
    },
    {
      top: 302.34000000000003,
      left: -99.4981288652987,
      life: 7117,
      speedUp: 20,
      speedRight: 7.242095639108923,
      startOpacity: 0.5930833333333334,
      size: 248.82999999999998,
      newTop: 302.34000000000003,
      newLeft: -99.4981288652987,
    },
    {
      top: 304.34,
      left: 47.01782366475234,
      life: 7217,
      speedUp: 20,
      speedRight: 3.0696285560237335,
      startOpacity: 0.6014166666666667,
      size: 247.83,
      newTop: 304.34,
      newLeft: 47.01782366475234,
    },
    {
      top: 306.36,
      left: 217.56622849792447,
      life: 7318,
      speedUp: 20,
      speedRight: 8.371195097546273,
      startOpacity: 0.6098333333333333,
      size: 246.82,
      newTop: 306.36,
      newLeft: 217.56622849792447,
    },
    {
      top: 308.32,
      left: -242.8450133676091,
      life: 7416,
      speedUp: 20,
      speedRight: 14.8217952148206,
      startOpacity: 0.618,
      size: 245.84,
      newTop: 308.32,
      newLeft: -242.8450133676091,
    },
    {
      top: 310.3,
      left: 488.6201714012175,
      life: 7515,
      speedUp: 20,
      speedRight: 5.478728351260425,
      startOpacity: 0.62625,
      size: 244.85,
      newTop: 310.3,
      newLeft: 488.6201714012175,
    },
    {
      top: 312.34000000000003,
      left: 190.42699193156795,
      life: 7617,
      speedUp: 20,
      speedRight: 9.821504444407099,
      startOpacity: 0.6347499999999999,
      size: 243.82999999999998,
      newTop: 312.34000000000003,
      newLeft: 190.42699193156795,
    },
    {
      top: 314.32,
      left: 698.4364837091347,
      life: 7716,
      speedUp: 20,
      speedRight: 9.636310029679835,
      startOpacity: 0.643,
      size: 242.84,
      newTop: 314.32,
      newLeft: 698.4364837091347,
    },
    {
      top: 316.34000000000003,
      left: 258.5066887245764,
      life: 7817,
      speedUp: 20,
      speedRight: 14.240344463418872,
      startOpacity: 0.6514166666666666,
      size: 241.82999999999998,
      newTop: 316.34000000000003,
      newLeft: 258.5066887245764,
    },
    {
      top: 318.34000000000003,
      left: 365.07167356894155,
      life: 7917,
      speedUp: 20,
      speedRight: 15.7134510840501,
      startOpacity: 0.6597500000000001,
      size: 240.82999999999998,
      newTop: 318.34000000000003,
      newLeft: 365.07167356894155,
    },
    {
      top: 320.34000000000003,
      left: 463.49856738119036,
      life: 8017,
      speedUp: 20,
      speedRight: 17.991521257747337,
      startOpacity: 0.6680833333333334,
      size: 239.82999999999998,
      newTop: 320.34000000000003,
      newLeft: 463.49856738119036,
    },
    {
      top: 322.34000000000003,
      left: 403.50423579160093,
      life: 8117,
      speedUp: 20,
      speedRight: 17.502962309921134,
      startOpacity: 0.6764166666666667,
      size: 238.82999999999998,
      newTop: 322.34000000000003,
      newLeft: 403.50423579160093,
    },
    {
      top: 324.34000000000003,
      left: 354.7388267385838,
      life: 8217,
      speedUp: 20,
      speedRight: 0.24807187811534348,
      startOpacity: 0.68475,
      size: 237.82999999999998,
      newTop: 324.34000000000003,
      newLeft: 354.7388267385838,
    },
    {
      top: 326.34000000000003,
      left: 293.03667355749155,
      life: 8317,
      speedUp: 20,
      speedRight: 15.936071942856675,
      startOpacity: 0.6930833333333333,
      size: 236.82999999999998,
      newTop: 326.34000000000003,
      newLeft: 293.03667355749155,
    },
    {
      top: 328.34000000000003,
      left: -8.420794991867876,
      life: 8417,
      speedUp: 20,
      speedRight: 6.7493011678564185,
      startOpacity: 0.7014166666666667,
      size: 235.82999999999998,
      newTop: 328.34000000000003,
      newLeft: -8.420794991867876,
    },
    {
      top: 330.34000000000003,
      left: 46.32466719485303,
      life: 8517,
      speedUp: 20,
      speedRight: 2.7050046250239967,
      startOpacity: 0.70975,
      size: 234.82999999999998,
      newTop: 330.34000000000003,
      newLeft: 46.32466719485303,
    },
    {
      top: 332.3,
      left: 322.8724080844647,
      life: 8615,
      speedUp: 20,
      speedRight: 2.355542200849716,
      startOpacity: 0.7179166666666666,
      size: 233.85,
      newTop: 332.3,
      newLeft: 322.8724080844647,
    },
    {
      top: 334.32,
      left: -92.23367406909779,
      life: 8716,
      speedUp: 20,
      speedRight: 8.025323311588934,
      startOpacity: 0.7263333333333333,
      size: 232.84,
      newTop: 334.32,
      newLeft: -92.23367406909779,
    },
    {
      top: 336.34000000000003,
      left: 600.5628847274559,
      life: 8817,
      speedUp: 20,
      speedRight: 16.3237110319549,
      startOpacity: 0.73475,
      size: 231.82999999999998,
      newTop: 336.34000000000003,
      newLeft: 600.5628847274559,
    },
    {
      top: 338.34,
      left: 251.29478071146525,
      life: 8917,
      speedUp: 20,
      speedRight: 17.883082911213958,
      startOpacity: 0.7430833333333333,
      size: 230.83,
      newTop: 338.34,
      newLeft: 251.29478071146525,
    },
    {
      top: 340.32,
      left: 13.349121364715646,
      life: 9016,
      speedUp: 20,
      speedRight: 8.18025574053987,
      startOpacity: 0.7513333333333333,
      size: 229.84,
      newTop: 340.32,
      newLeft: 13.349121364715646,
    },
    {
      top: 342.36,
      left: 684.93424101203,
      life: 9118,
      speedUp: 20,
      speedRight: 8.308432525109449,
      startOpacity: 0.7598333333333334,
      size: 228.82,
      newTop: 342.36,
      newLeft: 684.93424101203,
    },
    {
      top: 344.36,
      left: 167.39758973128875,
      life: 9218,
      speedUp: 20,
      speedRight: 11.322701818972106,
      startOpacity: 0.7681666666666667,
      size: 227.82,
      newTop: 344.36,
      newLeft: 167.39758973128875,
    },
    {
      top: 346.34000000000003,
      left: 789.8987174059213,
      life: 9317,
      speedUp: 20,
      speedRight: 0.09298507523888055,
      startOpacity: 0.7764166666666666,
      size: 226.82999999999998,
      newTop: 346.34000000000003,
      newLeft: 789.8987174059213,
    },
    {
      top: 348.36,
      left: 617.5733115668382,
      life: 9418,
      speedUp: 20,
      speedRight: 4.214004047578355,
      startOpacity: 0.7848333333333333,
      size: 225.82,
      newTop: 348.36,
      newLeft: 617.5733115668382,
    },
    {
      top: 350.34,
      left: 264.1042908533936,
      life: 9517,
      speedUp: 20,
      speedRight: 11.219502055819696,
      startOpacity: 0.7930833333333334,
      size: 224.83,
      newTop: 350.34,
      newLeft: 264.1042908533936,
    },
    {
      top: 352.34000000000003,
      left: 90.22735956096871,
      life: 9617,
      speedUp: 20,
      speedRight: 7.719444663086792,
      startOpacity: 0.8014166666666667,
      size: 223.82999999999998,
      newTop: 352.34000000000003,
      newLeft: 90.22735956096871,
    },
    {
      top: 354.34000000000003,
      left: 37.25552161979645,
      life: 9717,
      speedUp: 20,
      speedRight: 3.5740228616297376,
      startOpacity: 0.80975,
      size: 222.82999999999998,
      newTop: 354.34000000000003,
      newLeft: 37.25552161979645,
    },
    {
      top: 356.32,
      left: -80.84115078134039,
      life: 9816,
      speedUp: 20,
      speedRight: 10.881375792278426,
      startOpacity: 0.8180000000000001,
      size: 221.84,
      newTop: 356.32,
      newLeft: -80.84115078134039,
    },
    {
      top: 358.34,
      left: 323.2636625992436,
      life: 9917,
      speedUp: 20,
      speedRight: 4.899385138484944,
      startOpacity: 0.8264166666666667,
      size: 220.83,
      newTop: 358.34,
      newLeft: 323.2636625992436,
    },
    {
      top: 360.34,
      left: 336.30658208480475,
      life: 10017,
      speedUp: 20,
      speedRight: 9.985026348928109,
      startOpacity: 0.83475,
      size: 219.83,
      newTop: 360.34,
      newLeft: 336.30658208480475,
    },
    {
      top: 362.32,
      left: -106.38363981171074,
      life: 10116,
      speedUp: 20,
      speedRight: 11.272450336473279,
      startOpacity: 0.843,
      size: 218.84,
      newTop: 362.32,
      newLeft: -106.38363981171074,
    },
    {
      top: 364.34000000000003,
      left: 84.23936424818311,
      life: 10217,
      speedUp: 20,
      speedRight: 10.999575412448795,
      startOpacity: 0.8514166666666667,
      size: 217.82999999999998,
      newTop: 364.34000000000003,
      newLeft: 84.23936424818311,
    },
    {
      top: 366.34,
      left: 735.8882833776963,
      life: 10317,
      speedUp: 20,
      speedRight: 18.092706061070874,
      startOpacity: 0.85975,
      size: 216.83,
      newTop: 366.34,
      newLeft: 735.8882833776963,
    },
    {
      top: 368.34,
      left: 44.38285374926493,
      life: 10417,
      speedUp: 20,
      speedRight: 15.267338696908448,
      startOpacity: 0.8680833333333333,
      size: 215.83,
      newTop: 368.34,
      newLeft: 44.38285374926493,
    },
    {
      top: 370.32,
      left: 29.548411775834364,
      life: 10516,
      speedUp: 20,
      speedRight: 12.733851596097674,
      startOpacity: 0.8763333333333333,
      size: 214.84,
      newTop: 370.32,
      newLeft: 29.548411775834364,
    },
    {
      top: 372.34,
      left: -65.68934133392415,
      life: 10617,
      speedUp: 20,
      speedRight: 16.32472795061566,
      startOpacity: 0.88475,
      size: 213.83,
      newTop: 372.34,
      newLeft: -65.68934133392415,
    },
    {
      top: 374.34000000000003,
      left: 306.25027891270355,
      life: 10717,
      speedUp: 20,
      speedRight: 8.106699910907885,
      startOpacity: 0.8930833333333333,
      size: 212.82999999999998,
      newTop: 374.34000000000003,
      newLeft: 306.25027891270355,
    },
    {
      top: 376.32,
      left: 476.593828120981,
      life: 10816,
      speedUp: 20,
      speedRight: 9.284531498580609,
      startOpacity: 0.9013333333333333,
      size: 211.84,
      newTop: 376.32,
      newLeft: 476.593828120981,
    },
    {
      top: 378.36,
      left: 491.67960687228833,
      life: 10918,
      speedUp: 20,
      speedRight: 7.56370989845327,
      startOpacity: 0.9098333333333333,
      size: 210.82,
      newTop: 378.36,
      newLeft: 491.67960687228833,
    },
    {
      top: 380.36,
      left: -119.71650754384892,
      life: 11018,
      speedUp: 20,
      speedRight: 4.594129505562288,
      startOpacity: 0.9181666666666667,
      size: 209.82,
      newTop: 380.36,
      newLeft: -119.71650754384892,
    },
    {
      top: 382.36,
      left: 398.37637807319004,
      life: 11118,
      speedUp: 20,
      speedRight: 8.6611216279911,
      startOpacity: 0.9265,
      size: 208.82,
      newTop: 382.36,
      newLeft: 398.37637807319004,
    },
    {
      top: 384.32,
      left: -9.860853423663253,
      life: 11216,
      speedUp: 20,
      speedRight: 19.7584482043396,
      startOpacity: 0.9346666666666666,
      size: 207.84,
      newTop: 384.32,
      newLeft: -9.860853423663253,
    },
    {
      top: 386.3,
      left: 120.19713341373027,
      life: 11315,
      speedUp: 20,
      speedRight: 16.694289763915116,
      startOpacity: 0.9429166666666666,
      size: 206.85,
      newTop: 386.3,
      newLeft: 120.19713341373027,
    },
    {
      top: 388.34,
      left: 186.2236561898557,
      life: 11417,
      speedUp: 20,
      speedRight: 18.965534405208473,
      startOpacity: 0.9514166666666667,
      size: 205.83,
      newTop: 388.34,
      newLeft: 186.2236561898557,
    },
    {
      top: 390.34,
      left: -109.26049220214526,
      life: 11517,
      speedUp: 20,
      speedRight: 7.137977665975681,
      startOpacity: 0.95975,
      size: 204.83,
      newTop: 390.34,
      newLeft: -109.26049220214526,
    },
    {
      top: 392.34,
      left: -157.46984280388298,
      life: 11617,
      speedUp: 20,
      speedRight: 5.412826617185282,
      startOpacity: 0.9680833333333333,
      size: 203.83,
      newTop: 392.34,
      newLeft: -157.46984280388298,
    },
    {
      top: 394.34,
      left: 752.8911313354051,
      life: 11717,
      speedUp: 20,
      speedRight: 11.459835301069514,
      startOpacity: 0.9764166666666667,
      size: 202.83,
      newTop: 394.34,
      newLeft: 752.8911313354051,
    },
    {
      top: 396.32,
      left: 417.94312225591096,
      life: 11816,
      speedUp: 20,
      speedRight: 10.612090298099721,
      startOpacity: 0.9846666666666667,
      size: 201.84,
      newTop: 396.32,
      newLeft: 417.94312225591096,
    },
    {
      top: 398.34,
      left: 794.7087442359606,
      life: 11917,
      speedUp: 20,
      speedRight: 18.693387729586934,
      startOpacity: 0.9930833333333333,
      size: 200.83,
      newTop: 398.34,
      newLeft: 794.7087442359606,
    },
    {
      top: 396,
      left: -116.33363872829591,
      life: 11800,
      speedUp: 20,
      speedRight: 5.497355542141293,
      startOpacity: 0.9833333333333333,
      size: 202,
      newTop: 396,
      newLeft: -116.33363872829591,
    },
    {
      top: 398,
      left: 133.66984051250617,
      life: 11900,
      speedUp: 20,
      speedRight: 3.4029656209051673,
      startOpacity: 0.9916666666666667,
      size: 201,
      newTop: 398,
      newLeft: 133.66984051250617,
    },
    {
      top: 399.98,
      left: 469.3528965843369,
      life: 11999,
      speedUp: 20,
      speedRight: 7.359794152440755,
      startOpacity: 0.9999166666666667,
      size: 200.01,
      newTop: 399.98,
      newLeft: 469.3528965843369,
    },
    {
      top: 400,
      left: 568.2103427967093,
      life: 12000,
      speedUp: 20,
      speedRight: 5.850856131214153,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 756.1679989852023,
      life: 12000,
      speedUp: 20,
      speedRight: 1.7435565707415224,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 641.6495664593547,
      life: 12000,
      speedUp: 20,
      speedRight: 15.140809092451608,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 225.63795210567258,
      life: 12000,
      speedUp: 20,
      speedRight: 1.3884143603754673,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 720.5900618839755,
      life: 12000,
      speedUp: 20,
      speedRight: 16.310343237450816,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -70.06816244176522,
      life: 12000,
      speedUp: 20,
      speedRight: 0.8074518052174318,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 338.9763692300627,
      life: 12000,
      speedUp: 20,
      speedRight: 19.5438357055594,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 698.5667513573627,
      life: 12000,
      speedUp: 20,
      speedRight: 6.067312109485963,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 497.061755951641,
      life: 12000,
      speedUp: 20,
      speedRight: 17.174787856633245,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 791.8773873435869,
      life: 12000,
      speedUp: 20,
      speedRight: 9.472166965491745,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 84.1533799024665,
      life: 12000,
      speedUp: 20,
      speedRight: 0.6042476475185099,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 444.902481920435,
      life: 12000,
      speedUp: 20,
      speedRight: 11.477966474851202,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 200.2903388940431,
      life: 12000,
      speedUp: 20,
      speedRight: 14.838693967917592,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 701.1449151693132,
      life: 12000,
      speedUp: 20,
      speedRight: 17.79200064751504,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 338.8456439186833,
      life: 12000,
      speedUp: 20,
      speedRight: 10.334692715225927,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 568.2732445124414,
      life: 12000,
      speedUp: 20,
      speedRight: 19.441344965787096,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 773.7150748754435,
      life: 12000,
      speedUp: 20,
      speedRight: 16.593849637476858,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 605.7071796220023,
      life: 12000,
      speedUp: 20,
      speedRight: 17.520924714180772,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 450.08119332532215,
      life: 12000,
      speedUp: 20,
      speedRight: 7.567428246938124,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 593.2801035237211,
      life: 12000,
      speedUp: 20,
      speedRight: 16.078163524774123,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 586.0843160049209,
      life: 12000,
      speedUp: 20,
      speedRight: 19.555448168128567,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 246.26644955822468,
      life: 12000,
      speedUp: 20,
      speedRight: 1.848522869023319,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 777.3784574309758,
      life: 12000,
      speedUp: 20,
      speedRight: 19.314712612341612,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 283.0635735828595,
      life: 12000,
      speedUp: 20,
      speedRight: 3.337599134409901,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 535.6740063282059,
      life: 12000,
      speedUp: 20,
      speedRight: 14.347571546044207,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 521.3672419013491,
      life: 12000,
      speedUp: 20,
      speedRight: 7.9333670277559465,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 464.87760291013603,
      life: 12000,
      speedUp: 20,
      speedRight: 2.7568959482613042,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 796.3700688417414,
      life: 12000,
      speedUp: 20,
      speedRight: 13.491224589624316,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -64.57909682523072,
      life: 12000,
      speedUp: 20,
      speedRight: 12.296961358507957,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -93.08770920207459,
      life: 12000,
      speedUp: 20,
      speedRight: 11.612236584764979,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 16.7442123619079,
      life: 12000,
      speedUp: 20,
      speedRight: 16.673663894567994,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 520.0962551384733,
      life: 12000,
      speedUp: 20,
      speedRight: 7.662575045902638,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 373.5166985032778,
      life: 12000,
      speedUp: 20,
      speedRight: 2.1507482869513694,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -176.173054290685,
      life: 12000,
      speedUp: 20,
      speedRight: 12.055932458131524,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 737.6295811207868,
      life: 12000,
      speedUp: 20,
      speedRight: 9.111772926799677,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 702.5816098232302,
      life: 12000,
      speedUp: 20,
      speedRight: 5.023349673328434,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 786.5785641678156,
      life: 12000,
      speedUp: 20,
      speedRight: 11.216940599417985,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 785.8911961190108,
      life: 12000,
      speedUp: 20,
      speedRight: 11.39241232148228,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -11.239454989779233,
      life: 12000,
      speedUp: 20,
      speedRight: 0.5074110898255313,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 141.89972228746313,
      life: 12000,
      speedUp: 20,
      speedRight: 2.7327047509146496,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 213.5020347492495,
      life: 12000,
      speedUp: 20,
      speedRight: 15.838573170413742,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 393.9196511512972,
      life: 12000,
      speedUp: 20,
      speedRight: 2.406125180748968,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -29.035456678824715,
      life: 12000,
      speedUp: 20,
      speedRight: 7.467444280553361,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -117.85253647103815,
      life: 12000,
      speedUp: 20,
      speedRight: 19.59054561484381,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 435.86340573656867,
      life: 12000,
      speedUp: 20,
      speedRight: 3.5337918257353973,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 92.55084964110398,
      life: 12000,
      speedUp: 20,
      speedRight: 5.9332038643381635,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 144.17795451010534,
      life: 12000,
      speedUp: 20,
      speedRight: 6.4581888876619775,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 222.19129423028835,
      life: 12000,
      speedUp: 20,
      speedRight: 11.686825166512111,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 608.9957277759403,
      life: 12000,
      speedUp: 20,
      speedRight: 9.043983018029657,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 287.16791849225126,
      life: 12000,
      speedUp: 20,
      speedRight: 1.6999335670145665,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 446.91765422375806,
      life: 12000,
      speedUp: 20,
      speedRight: 9.566329694406335,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -126.218020138029,
      life: 12000,
      speedUp: 20,
      speedRight: 19.51804783795116,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 12.973188156713178,
      life: 12000,
      speedUp: 20,
      speedRight: 10.058515115560112,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -83.09395810254033,
      life: 12000,
      speedUp: 20,
      speedRight: 11.577899356238447,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 42.69197223495809,
      life: 12000,
      speedUp: 20,
      speedRight: 0.7362499494304542,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -21.43897360766306,
      life: 12000,
      speedUp: 20,
      speedRight: 14.123220086821,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 268.28869690808085,
      life: 12000,
      speedUp: 20,
      speedRight: 8.877652162183324,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 740.6261257514727,
      life: 12000,
      speedUp: 20,
      speedRight: 14.323264746227755,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 553.9599318573457,
      life: 12000,
      speedUp: 20,
      speedRight: 7.33316123708216,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -0.4583449705806686,
      life: 12000,
      speedUp: 20,
      speedRight: 17.936112554460216,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -126.75651989506558,
      life: 12000,
      speedUp: 20,
      speedRight: 6.300523542119194,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 680.4503360691134,
      life: 12000,
      speedUp: 20,
      speedRight: 10.620936999869492,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 763.7970443686505,
      life: 12000,
      speedUp: 20,
      speedRight: 3.6295400035140934,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 28.345186036990498,
      life: 12000,
      speedUp: 20,
      speedRight: 14.446389571982902,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -90.57691576454157,
      life: 12000,
      speedUp: 20,
      speedRight: 4.982267965949814,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 189.05158704548893,
      life: 12000,
      speedUp: 20,
      speedRight: 12.420886078325012,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 479.12697864162226,
      life: 12000,
      speedUp: 20,
      speedRight: 6.6566199672818005,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -89.24478961525134,
      life: 12000,
      speedUp: 20,
      speedRight: 19.969762142122907,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 123.76525321438777,
      life: 12000,
      speedUp: 20,
      speedRight: 14.807414010779038,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 735.650609503154,
      life: 12000,
      speedUp: 20,
      speedRight: 10.276091620303415,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 342.7108063100645,
      life: 12000,
      speedUp: 20,
      speedRight: 16.70362007059606,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 371.4994238122673,
      life: 12000,
      speedUp: 20,
      speedRight: 1.715872656614268,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 764.8134729942389,
      life: 12000,
      speedUp: 20,
      speedRight: 0.8872815514676002,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 309.3248819903338,
      life: 12000,
      speedUp: 20,
      speedRight: 10.074724922843249,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -126.61374524198004,
      life: 12000,
      speedUp: 20,
      speedRight: 2.90202826599971,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 137.63547829109285,
      life: 12000,
      speedUp: 20,
      speedRight: 10.236215496245858,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -89.40326590536833,
      life: 12000,
      speedUp: 20,
      speedRight: 18.460834543143218,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 701.1559229727952,
      life: 12000,
      speedUp: 20,
      speedRight: 16.02036528427629,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -194.02134468697545,
      life: 12000,
      speedUp: 20,
      speedRight: 13.009927601773823,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 582.6737766146325,
      life: 12000,
      speedUp: 20,
      speedRight: 14.148504718934682,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -60.260538818472526,
      life: 12000,
      speedUp: 20,
      speedRight: 3.4098216100256584,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 40.46550850474978,
      life: 12000,
      speedUp: 20,
      speedRight: 10.305627577705042,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 374.4525874115625,
      life: 12000,
      speedUp: 20,
      speedRight: 14.00537389461741,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 156.12791841038336,
      life: 12000,
      speedUp: 20,
      speedRight: 1.3202628337578126,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 179.6599111452539,
      life: 12000,
      speedUp: 20,
      speedRight: 9.457523905186068,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: -28.26838465990616,
      life: 12000,
      speedUp: 20,
      speedRight: 9.247007711246805,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 631.0374659867246,
      life: 12000,
      speedUp: 20,
      speedRight: 18.28986235887648,
      startOpacity: 1,
      size: 200,
    },
    {
      top: 400,
      left: 280.8211651213723,
      life: 12000,
      speedUp: 20,
      speedRight: 1.5197432271289557,
      startOpacity: 1,
      size: 200,
    },
  ],
}

export default preStartData
