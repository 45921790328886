export default {
  required: '{{name}} vantar',
  inputType: '{{name}} er rangt, bjóst við texta',
  email: '{{name}} er ekki gilt',
  date: '{{name}} er ekki gild dagsetning, þarf að vera ÁÁÁÁ-MM-DD',
  reservationDateAndTime: 'Veldu dag og tíma',
  reservationDate: 'Veldu dag',
  reservationTime: 'Veldu tíma',
  reservationKids: 'Veldu fjölda barna',
  nameFirstAndLast: 'Fullt nafn vantar',
  nameFirst: 'Fornafn vantar',
  nameLast: 'Eftirnafn vantar',
}
