import Script from 'next/script'
import styled from 'styled-components'

import { ISectionReviewsFields } from 'bl-common/src/generated/contentful'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { theme } from 'bl-common/src/styles/theme'
import { Section } from 'bl-common/src/units/Section/Section'

const trustIndexUrl =
  process.env.NEXT_PUBLIC_TRUSTINDEX_URL ||
  'https://cdn.trustindex.io/loader.js'

type ReviewsSectionProps = {
  section: {
    fields: ISectionReviewsFields
  }
}

const TextContent = styled.div({
  marginBottom: theme.spacing[2],
})

const ReviewsSection = ({
  section: {
    fields: { textContent, reviewId, config },
  },
}: ReviewsSectionProps) => (
  <>
    <Section config={config}>
      {!!textContent && (
        <TextContent>
          <RichTextRenderer document={textContent} />
        </TextContent>
      )}
      <div data-src={`${trustIndexUrl}?${reviewId}`} />
    </Section>
    <Script src={trustIndexUrl} />
  </>
)

export default ReviewsSection
