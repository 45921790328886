import React from 'react'
import marked from 'marked'
import PropTypes from 'prop-types'

import { getLinkTarget } from 'bl-utils/src/getLinkTarget'

import Copy from '../Copy'

export const Markdown = ({ children, ...props }) => {
  const renderer = new marked.Renderer()

  // disallow h1
  renderer.heading = function (text, level) {
    const maxHeading = 6
    const lvl = Math.min(level + 1, maxHeading)

    return `
          <h${lvl}>
            ${text}
          </h${lvl}>`
  }

  renderer.link = (href, title, text) => {
    const target = getLinkTarget(href)
    return `<a href=${href} target=${target} title="${title}" ${
      props.linkColor ? `style=color:${props.linkColor}` : ''
    }>${text}</a>`
  }

  return (
    <Copy
      as="div"
      {...props}
      dangerouslySetInnerHTML={{
        __html: marked(children, {
          gfm: true,
          breaks: true,
          renderer,
        }),
      }}
    />
  )
}

Markdown.propTypes = {
  children: PropTypes.string.isRequired,
}
