import React from 'react'

import { Carousel } from '../../units/Carousel/Carousel'
import { Tag } from './Tag'

const gradients = {
  'blue-lagoon': [
    { color1: '#5B839B', color2: '#5A859D', degrees: '135deg' },
    { color1: '#5A859D', color2: '#578DA4', degrees: '135deg' },
    { color1: '#578DA4', color2: '#5595AB', degrees: '135deg' },
    { color1: '#5595AB', color2: '#539DB1', degrees: '135deg' },
    { color1: '#539DB1', color2: '#51A5B8', degrees: '135deg' },
    { color1: '#51A5B8', color2: '#539EB3', degrees: '135deg' },
  ],
  'retreat-spa': [
    { color1: '#626971', color2: '#666A72', degress: '135deg' },
    { color1: '#666A72', color2: '#736D76', degress: '135deg' },
    { color1: '#736D76', color2: '#7F6F78', degress: '135deg' },
    { color1: '#7F6F78', color2: '#8C717B', degress: '135deg' },
    { color1: '#8C717B', color2: '#98737E', degress: '135deg' },
    { color1: '#98737E', color2: '#9C747F', degress: '135deg' },
  ],
  'silica-hotel': [
    { color1: '#8F9196', color2: '#909195', degrees: '135deg' },
    { color1: '#909195', color2: '#919092', degrees: '135deg' },
    { color1: '#919092', color2: '#949090', degrees: '135deg' },
    { color1: '#949090', color2: '#968F8D', degrees: '135deg' },
    { color1: '#968F8D', color2: '#998F8B', degrees: '135deg' },
    { color1: '#998F8B', color2: '#9A8E88', degrees: '135deg' },
  ],
  'retreat-hotel': [
    { color1: '#233030', color2: '#263333', degrees: '135deg' },
    { color1: '#263333', color2: '#323D3F', degrees: '135deg' },
    { color1: '#323D3F', color2: '#3F484C', degrees: '135deg' },
    { color1: '#3F484C', color2: '#4B5358', degrees: '135deg' },
    { color1: '#4B5358', color2: '#585D65', degrees: '135deg' },
    { color1: '#575D64', color2: '#62666F', degrees: '135deg' },
  ],
}

export const Tags = ({ tags, linkComponent = undefined, categorySlug }) => {
  if (!tags || !Array.isArray(tags)) {
    return null
  }

  if (!gradients[categorySlug]) {
    // fall back to default colors if slug isn't found.
    categorySlug = 'blue-lagoon'
  }

  return (
    <Carousel
      minItemWidthFrom={200}
      minItemWidthTo={277}
      scrollKey={tags[0].id}
    >
      {tags.map(({ title, description, slug, supportArticleLink }, index) => {
        const url = supportArticleLink || `/support/${categorySlug}/${slug}`
        const gradientFromSlug =
          gradients[categorySlug] &&
          (gradients[categorySlug][index] ||
            gradients[categorySlug][gradients[categorySlug].length - 1])

        return (
          <Tag
            key={slug}
            title={title}
            linkComponent={linkComponent}
            description={description}
            slug={url}
            gradient={gradientFromSlug}
          />
        )
      })}
    </Carousel>
  )
}
