import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { BackgroundImage } from 'bl-common/src/elements/BackgroundImage'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

type ColumnItemProps = {
  isGrid?: boolean
}

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;

  ${media.sm(css`
    flex: 0 0 45%;
  `)};

  ${media.md(css`
    flex: 0 0 25%;
  `)};
`

const ColumnItem = styled.div<ColumnItemProps>`
  height: ${between(320, 350)};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`

const Top = styled(({ image, gradient, ...rest }) =>
  image ? <BackgroundImage image={image} {...rest} /> : <div {...rest} />
)`
  height: ${between(150, 200)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: ${({ theme }) => theme.spacing[1.5]};

  ${({ gradient }) =>
    gradient &&
    gradient.fields &&
    css`
      background-image: linear-gradient(
        ${gradient.fields.degrees || 'to right'},
        ${gradient.fields.color1},
        ${gradient.fields.color2}
      );
    `};
`

const splitIntoColumns = (items, columnCount) => {
  return items.reduce(
    (acc, item, index) => {
      const currColumn = acc[index % columnCount]
      acc[index % columnCount] = [...currColumn, item]
      return acc
    },
    [[], [], []]
  )
}

export const InfoColumnsGrid = ({ items }) => {
  const cols = splitIntoColumns(items, 3)
  return (
    <Grid>
      {cols.map((col, index) => (
        <Column key={`GridColumn-${index}`}>
          {col.map(item => (
            <ColumnItem key={item.fields.title} isGrid={true}>
              {(item.fields.image || item.fields.gradient) && (
                <Top
                  image={item.fields.image}
                  gradient={item.fields.gradient}
                />
              )}
              {item.fields.title && (
                <Type
                  preset="label"
                  weight="bold"
                  bottom={{ xs: 0.5, md: 0.5 }}
                  case="uppercase"
                >
                  {item.fields.title}
                </Type>
              )}
              <Type preset="label" weight="normal" multiline>
                {item.fields.text}
              </Type>
            </ColumnItem>
          ))}
        </Column>
      ))}
    </Grid>
  )
}

InfoColumnsGrid.propTypes = {
  items: PropTypes.array.isRequired,
}
