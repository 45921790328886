import { colors } from 'bl-common/src/constants/colors'
import { ScrollArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { ArrowLink } from 'bl-common/src/elements/ArrowLink'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ISectionAboutFields } from 'bl-common/src/generated/contentful'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { formatButton } from '../../../../utils/formatters'
import { BreadcrumbLink } from '../../../BreadcrumbLink'
import { ImageLinksBox } from './ImageLinksBox'
import * as Styles from './styles'

type AboutSectionProps = {
  section: {
    fields: ISectionAboutFields
  }
}

const formatImageLink = link =>
  link && {
    id: link.sys.id,
    type: link.sys.contentType.sys.id,
    icon: link.fields.icon && link.fields.icon.fields.file.url,
    link: link.fields.link,
    name: link.fields.name,
    text: link.fields.text,
    backgroundColor: link.fields.backgroundColor,
  }

const AboutSection = ({
  section: {
    fields: {
      tagline,
      heading,
      button,
      sidebarImage,
      subHeading,
      imageLinks,
      frameGradient,
      showBreadcrumb,
      withScrollArrow = false,
      config,
    },
  },
}: AboutSectionProps) => {
  const gradient = frameGradient?.fields
  const formattedButton = formatButton(button)

  return (
    <Section
      config={config}
      noHorizontalPadding={true}
      top={{ xs: 1, md: 1 }}
      bottom={{ xs: 2, md: 8 }}
      hideOverflowX
    >
      <Styles.Container>
        <Styles.ContentFrame gradient={gradient}>
          {showBreadcrumb && <BreadcrumbLink />}
          <Styles.ContentContainer>
            <Appear>
              {tagline && (
                <Type
                  preset="label"
                  color={colors.deepBlue}
                  bottom={{ xs: 0.5 }}
                >
                  {tagline}
                </Type>
              )}
              <Type
                preset="headlineLarge"
                bottom={{ md: 2, xs: 1 }}
                maxWidth={400}
                weight="bold"
              >
                {heading}
              </Type>
            </Appear>
            <Appear>
              <Type
                size={{ md: 24, xs: 16 }}
                weight="light"
                bottom={{ md: 2, xs: 1 }}
                multiline
                dangerouslySetInnerHTML={{
                  __html: formatHtmlText(subHeading, Styles.linkStyle),
                }}
              />
              {formattedButton ? (
                button.fields?.displayAsLink ? (
                  <ArrowLink
                    weight="bold"
                    size={{ xs: 16 }}
                    {...formattedButton}
                    bottom={{ xs: 1 }}
                  >
                    {formattedButton.text}
                  </ArrowLink>
                ) : (
                  <Button {...formattedButton} bottom={{ xs: 1 }}>
                    {formattedButton.text}
                  </Button>
                )
              ) : null}
              {withScrollArrow && <ScrollArrow />}
            </Appear>
          </Styles.ContentContainer>
          <SmartImage image={sidebarImage}>
            {imageUrl => <Styles.SideImage image={imageUrl} />}
          </SmartImage>
          {imageLinks && (
            <Appear>
              <Styles.ContentContainer>
                <ImageLinksBox
                  imageLinks={imageLinks.map(link => formatImageLink(link))}
                />
              </Styles.ContentContainer>
            </Appear>
          )}
        </Styles.ContentFrame>
      </Styles.Container>
    </Section>
  )
}

export default AboutSection
