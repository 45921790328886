export default {
  required: '{{name}} is required',
  inputType: '{{name}} is an invalid type, expected string',
  email: '{{name}} is an invalid email address',
  date: '{{name}} is invalid, please type YYYY-MM-DD',
  reservationDateAndTime: 'Date and time are required',
  reservationDate: 'Date is required',
  reservationTime: 'Time is required',
  reservationKids: 'Please choose number of children',
  nameFirstAndLast: 'Full name is required',
  nameFirst: 'First name is required',
  nameLast: 'Last name is required',
}
