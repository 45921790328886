import React from 'react'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { zIndex } from '../../constants/zIndex'
import { Link } from '../../elements/Link'
import { Type } from '../../elements/Typography/Typography'
import { media } from '../../utils/media'

const TagWrapper = styled(({ theme, component: Component = Link, ...rest }) => (
  <Component {...rest} />
))`
  color: ${colors.white};
  display: inline-block;
  height: 100%;
  padding: ${({ theme }) => theme.spacing[3]};
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 0;

  ${media.md(css`
    padding: ${({ theme }) => theme.spacing[2]};
    text-align: left;
  `)};

  &:hover::before {
    transform: scale(1.02);
  }

  &::before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform ${durations.short}ms ease;
    will-change: transform;
    z-index: ${zIndex.behind};
    ${({ gradient }) =>
      gradient &&
      css`
        background-image: linear-gradient(
          ${get(gradient, 'fields.degrees', gradient.degrees)},
          ${get(gradient, 'fields.color1', gradient.color1)},
          ${get(gradient, 'fields.color2', gradient.color2)}
        );
        background-color: ${get(gradient, 'fields.color1', gradient.color1)};
      `};
`

export const Tag = ({
  title,
  description,
  slug,
  gradient = { color1: '#5B839B', color2: '#5A859D', degrees: '135deg' },
  linkComponent,
}) => (
  <TagWrapper gradient={gradient} to={slug} component={linkComponent}>
    <Type as="h2" preset="label" case="uppercase" bottom={{ xs: 0.5, md: 1 }}>
      {title}
    </Type>
    <Type preset="text" bottom={{ xs: 1, md: 3 }}>
      {description}
    </Type>
  </TagWrapper>
)
