import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { triggerEvent } from 'bl-utils/src/analytics/events'

import { colors } from '../constants/colors'
import { media } from '../utils/media'
import { Markdown } from './Markdown/Markdown'
import { Icon } from './SupportAccordion/icon'
import { Type } from './Typography/Typography'

const AccordionContainer = styled.div`
  position: relative;
  text-align: left;
  overflow: hidden;
  padding-top: ${({ theme }) => theme.spacing[1.5]};

  :first-child {
    padding-top: 0;
  }
`

const Label = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing[1.5]};
  text-align: left;
  width: 100%;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.black};
  opacity: 0.15;
`

const Content = styled(motion.div)``

const IconWrap = styled.div`
  padding-top: 5px;

  ${media.md(css`
    padding-top: 10px;
  `)}
`

export const Accordion = ({
  label,
  content,
  textColor = colors.dark,
  linkColor = colors.deepBlue,
  ...rest
}) => {
  const router = useRouter()
  const [expanded, setExpanded] = useState(false)

  const toggle = () => {
    if (!expanded) {
      triggerEvent({
        event: 'FAQ click',
        eventAction: `${router.asPath} - ${label}`,
      })
    }
    setExpanded(!expanded)
  }

  return (
    <AccordionContainer>
      <Label
        type="button"
        onClick={toggle}
        aria-controls="accordion-content"
        aria-expanded={expanded}
        aria-label={
          expanded ? 'click to collapse' : `${label}, click to expand`
        }
        style={{ color: textColor }}
      >
        <Type preset="headlineSmall" {...rest}>
          {label}
        </Type>
        <IconWrap>
          <Icon isActive={expanded} color={textColor} />
        </IconWrap>
      </Label>
      <Content
        id="accordion-content"
        aria-hidden={!expanded}
        variants={{
          collapsed: { opacity: 0, height: 0 },
          expanded: { opacity: 1, height: 'auto' },
        }}
        initial="collapsed"
        animate={expanded ? 'expanded' : 'collapsed'}
        exit={expanded ? 'expanded' : 'collapsed'}
        transition={{ duration: 0.3, ease: 'easeOut' }}
      >
        <Markdown
          bottom={{ xs: 2, md: 3 }}
          color={textColor}
          linkColor={linkColor}
        >
          {content}
        </Markdown>
      </Content>
      <Divider style={{ background: textColor }} />
    </AccordionContainer>
  )
}
