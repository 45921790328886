import i18n from 'i18next'

import en from './en'
import is from './is'

const i18nInstance = i18n.createInstance()

i18nInstance.init({
  fallbackLng: 'en',
  saveMissing: true,

  missingKeyHandler: (locale, _ns, key) => {
    // eslint-disable-next-line no-console
    console.log(`Translations: Missing key '${key}' in locale ${locale}.`)
  },

  resources: {
    en: {
      translation: en,
    },
    is: {
      translation: is,
    },
  },
})

export default i18nInstance
